export const ENDPOINTS = {
  create_catalog: "/api/create_catalog/",
  create_catalog_result: "/api/create_catalog_result/",
  create_catalog_in_bulk: "/api/create_catalog/bulk/",
  schedule_catalog_creation: "/api/schedule_catalog_creation/",
  schedule_periodic_catalog_creation:
    "/api/schedule_periodic_catalog_creation/",
  schedule_periodic_task: "/api/schedule_periodic_task/",
  add_new_catalog: "/api/add_new_catalog/",
  delete_catalog: "/api/delete_catalog/",
  get_document_content: "/api/get_document_content/",
  get_catalog: "/api/get_catalog/",
  chat: "/api/chat/",
  fetch_files: "/api/fetch_files/",
  fetch_folders: "/api/fetch_folders/",
  get_catalog_summary: "/api/get_catalog_summary/",
  delete_files_in_catalog: "/api/documents/delete/",
  upload_catalog: "/api/upload_catalog/",
  scan_documents: "/api/scan_documents/",
  clear_label: "/api/clear_label/",
  clear_scan: "/api/clear_scan/",
  rename_catalog: "/api/rename_catalog/",
  get_tasks_status: "/api/get_tasks_status/",
  trigger_long_task: "/api/trigger_long_task/",
  create_usecase: "/api/create_usecase/",
  get_all_usecases: "/api/get_all_usecases/",
  delete_usecase: "/api/delete_usecase/",
  get_usecase_history: "/api/get_usecase_history/",
  create_usecase_history: "/api/create_usecase_history/",
  rename_chat: "/api/rename_chat/",
  delete_usecase_history: "/api/delete_usecase_history/",
  delete_usecase_history_message: "/api/delete_usecase_history_message/",
  clear_usecase_history: "/api/clear_usecase_history/",
  delete_label: "/api/delete_label/",
  create_chunks: "/api/create_chunks/",
  get_tags: "/api/get_tags/",
  run_rule: "/api/run_rule/",
  get_rules: "/api/get_rules/",
  upload_tag: "/api/catalog/tag/",
  upload_tags: "/api/upload_tags/",
  upload_rules: "/api/upload_rules/",
  upload_tag_rules: "/api/upload_tag_rules/",
  delete_tag_rules: "/api/delete_tag_rules/",
  get_tag_rules: "/api/get_tag_rules/",
  load_user_profile: "/api/load_user_profile/",
  save_user_profile: "/api/save_user_profile/",
  get_user_token_tracking: "/api/get_user_token_tracking/",
  get_team_token_tracking: "/api/get_team_token_tracking/",
  check_chunk_sensitivity: "/api/check_chunk_sensitivity/",
  approve_quarantine_files: "/api/approve_quarantine_files/",
  archive_files: "/api/archive_files/",
  text_generation: "/api/text_generation/",
  export: "/api/export/",
  pubsub_token: "/api/pubsub_token/",
  gcp_firestore_auth: "/api/gcp_firestore_auth/",
  get_teams: "/api/get_teams/",
  update_teams: "/api/update_teams/",
  get_task_status: "/api/workers/task-status/",
  revoke_task: "/api/workers/revoke-task/",
  auto_create_tags: "/api/auto_create_tags/",
  auto_create_tags_result: "/api/auto_create_tags_result/",
  upload_files: "/api/upload_files/",
  evidence_reports: "/api/evidence-reports/",
  evaluate_tags: "/api/evaluate_tags/",
  team_preferences: "/api/team-preferences/",
  standardize_tag_in_catalog: "/api/standardize_tag_in_catalog/",
  suggest_tag_description: "/api/suggest_tag_description/",
  rerun_tag: "/api/rerun_tag/",
  get_evidence: "/api/get_evidence/",
  get_user_scheduled_tasks: "/api/get_user_scheduled_tasks/",
  get_schedule_task_details: "/api/get_schedule_task_details/",
  fetch_entries_for_scheduled_tasks: "/api/fetch_entries_for_scheduled_tasks/",
  schedule_periodic_catalog_creation_bulk: "/api/schedule_periodic_catalog_creation_bulk/",
  update_schedule_task_status: "/api/update_schedule_task_status/",
  apply_tag_value_change_to_catalog: "/api/apply_tag_value_change_to_catalog/",
  apply_single_tag_value_change: "/api/apply_single_tag_value_change/",
  document_info: "/api/document-info/",
  fetch_catalog_content: "/api/fetch_catalog_content/",
  get_validation_records: "/api/get_validation_records/",
  update_validation_records: "/api/update_validation_records/",
  create_catalog_by_chunks: "/api/create_catalog_by_chunks/",
  transfer_validation_records: "/api/transfer_validation_records/",
  update_tag_version: "/api/update_tag_version/",
  get_tag_versions: "/api/get_tag_versions/",
  get_team_members: "/api/get_team_members/",
  get_token_limit: "/api/get_token_limit/",
  update_user_token_limit: "/api/update_user_token_limit/",
  get_document_level_evidence: "/api/get_document_level_evidence/",
  save_user_feedback: "/api/save_user_feedback/",
  create_catalog_for_scheduled_folder: "/api/create_catalog_for_scheduled_folder/",
  get_schedule_task_file_details: "/api/get_schedule_task_file_details/",
  rerun_schedule_tags: "/api/rerun_schedule_tags/",
};
