import { useContext, useState, useEffect } from "react";
import { DataContext } from "../../../../../../../context/DataContext";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faArrowLeft,
  faInfoCircle,
  faFolder,
} from "@fortawesome/free-solid-svg-icons";
import ScheduleSettingCalendar from "./SchedulingSubComponents/ScheduleSettingCalendar";
import { ENDPOINTS } from "../../../../../../../api/endpoints";
import { sendRequest } from "../../../../../../utilities/functions/api";
import Auth from "../../../../../../../auth/AuthProvider";
import { toast } from "./../../../../../../utilities/Toast";
import SelectData from "./SchedulingSubComponents/SelectData";
import { useAtom } from "jotai";
import ConfirmSchedule from "./SchedulingSubComponents/ConfirmSchedule";
import { selectedTagKeysAtom } from "../../../../../../../atoms";

const ScheduleSettingComponent = ({
  folders,
  checkedItems,
  setCheckedItems,
  filteredFolderKeys,
  setFolders,
  setTimeSelectionModalOpen,
  calledFrom = "",
}) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const [selectedHour, setSelectedHour] = useState(0);
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(0);
  const [selectedDayOfMonth, setSelectedDayOfMonth] = useState(1);
  const [minTime, setMinTime] = useState(new Date());
  const [maxTime, setMaxTime] = useState(new Date().setHours(23, 59, 59, 999));
  const [integration, setIntegration] = useState("s3");
  const [isFolderListModalOpen, setIsFolderListModalOpen] = useState(false);
  const [selectedTagKeys] = useAtom(selectedTagKeysAtom);
  const { preferences } = useContext(DataContext);
  const [checkedFolders, setCheckedFolders] = useState({});
  const [isConfirmationView, setIsConfirmationView] = useState(false);
  const [selectedFoldersForConfirmation, setSelectedFoldersForConfirmation] =
    useState({});

  const { usedCatalog, currentDataGroup, availableTags, setShowFilePreview } =
    useContext(DataContext);

  useEffect(() => {
    if (calledFrom === "recurring") {
      setSelectedPeriod("HOURLY");
    }
  }, [calledFrom]);

  const fetchFolders = async (integration) => {
    let dataStore = preferences.webapp_profile.DATA_STORES[integration];
    try {
      const folderResponse = await sendRequest(
        {
          data_store: JSON.stringify(dataStore),
          [preferences.system.API_USERNAME_KEYWORD]: (
            await Auth.currentAuthenticatedUser()
          ).username,
        },
        ENDPOINTS["fetch_folders"],
      );
      const retrievedFolders = await folderResponse.json();

      setFolders(retrievedFolders.folder_list);
    } catch (error) {
      console.error("Error during the request", error);
    }
  };

  const handleIntegrationClick = (key) => {
    setIntegration(key);
    fetchFolders(key);
    setIsFolderListModalOpen(true);
  };

  const isToday = (date) => {
    const today = new Date();
    return (
      date &&
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const getMinTime = (date) => {
    const now = new Date();
    if (!date || isToday(date)) {
      return now;
    }
    return new Date().setHours(0, 0, 0, 0);
  };

  const getMaxTime = () => {
    const endOfDay = new Date();
    endOfDay.setHours(23, 59, 59, 999);
    return endOfDay;
  };

  useEffect(() => {
    setMinTime(getMinTime(selectedDate));
    setMaxTime(getMaxTime());
  }, [selectedDate]);

  const handleCreateCatalogWithFilesAndTags = async (
    confirmedFolders,
    selectedOption,
    singleCatalogName,
  ) => {
    const scheduleTime = selectedDate || new Date();
    setTimeSelectionModalOpen(false);

    const username = (await Auth.currentAuthenticatedUser()).username;
    const selectedFolders = Object.keys(confirmedFolders).filter(
      (folder) => confirmedFolders[folder].selectedFiles > 0,
    );

    const getFilesForFolder = (folderPath) => {
      const dataSnapShot = { ...currentDataGroup };
      return Object.entries(checkedItems)
        .filter(([fileName, itemData]) => {
          if (folderPath === "Unfoldered") {
            return (
              itemData.isChecked &&
              (!itemData.folder || itemData.folder === "/")
            );
          }
          return (
            itemData.isChecked &&
            itemData.folder &&
            (itemData.folder === folderPath ||
              itemData.folder.startsWith(`${folderPath}/`))
          );
        })
        .map(([fileName, itemData]) => {
          let fileDetails = dataSnapShot[fileName] || {};
          let dataStore, path;

          // Determine the data store name
          let dataStoreName = "";
          if (
            itemData.source &&
            preferences.webapp_profile.DATA_STORES[itemData.source]
          ) {
            dataStoreName = itemData.source;
            dataStore = preferences.webapp_profile.DATA_STORES[itemData.source];
          } else if (fileDetails.data_store_name) {
            dataStoreName = Array.isArray(fileDetails.data_store_name)
              ? fileDetails.data_store_name[0]
              : fileDetails.data_store_name;
            dataStore = preferences.webapp_profile.DATA_STORES[dataStoreName];
          } else {
            console.warn(
              `Data store not found for file: ${fileName}. Using default.`,
            );
            dataStoreName = "default";
            dataStore = preferences.webapp_profile.DATA_STORES["default"];
          }

          if (fileDetails.file_directory) {
            path = `${fileDetails.file_directory}/${fileName}`;
          } else if (!itemData.folder || itemData.folder === "/") {
            path = `${dataStore.base_path}/${fileName}`;
          } else {
            path = `${dataStore.base_path}${itemData.folder}/${fileName}`;
          }

          path = path.replace(/^\/\//, "/");

          return {
            data_store_name: dataStoreName,
            path: path,
            file_name: fileName,
            storage: dataStore.storage || {},
          };
        })
        .filter((item) => item !== null); // Remove any null items (skipped files)
    };

    const availableTagsCopy = JSON.parse(JSON.stringify(availableTags));
    for (const tagKey in availableTagsCopy?.llm?.tagger_params?.tag_dict ||
      {}) {
      if (selectedTagKeys.length === 0 || selectedTagKeys.includes(tagKey))
        continue;
      delete availableTagsCopy.llm.tagger_params.tag_dict[tagKey];
    }
    const ensureScheduledPrefix = (name) => {
      if (!name || typeof name !== "string") return "Catalog";
      return name;
    };

    const createCatalog = async (catalogName, files) => {
      const finalCatalogName = ensureScheduledPrefix(catalogName);
      const requestData = {
        username: username,
        catalog_name: finalCatalogName,
        files: files,
        tagger_list: availableTagsCopy,
        schedule_at: scheduleTime.toISOString(),
        check_sensitivity: false,
        is_soft_run: false,
        quarantine_name: preferences.system.QUARANTINECATALOG,
      };

      try {
        const response = await sendRequest(
          requestData,
          ENDPOINTS["create_catalog_for_scheduled_folder"],
        );
        const result = await response.json();

        toast.success({
          title: `New catalog "${finalCatalogName}" created and files are being processed`,
          description: `Task ID: ${result.task_id}`,
        });
      } catch (error) {
        console.error(`Error creating catalog "${finalCatalogName}":`, error);
        toast.error({
          title: `Failed to create catalog "${finalCatalogName}"`,
          description: error.message,
        });
      }
    };

    const handleCreateWorkflow = async (catalogName, folders) => {
      const finalCatalogName = ensureScheduledPrefix(catalogName);
      const requestData = {
        username: username,
        catalog_name: finalCatalogName,
        folders: folders,
        data_store: preferences.webapp_profile.DATA_STORES[integration],
        tagger_list: availableTagsCopy,
        scheduling_period: selectedPeriod,
        day_of_period: selectedDayOfWeek,
        time_of_period: selectedHour,
      };

      try {
        const response = await sendRequest(
          requestData,
          ENDPOINTS["schedule_periodic_task"],
        );
        const result = await response.json();

        toast.success({
          title: `New workflow "${finalCatalogName}" created and files are being processed`,
          description: `Workflow ID: ${result.workflow_id}`,
        });
      } catch (error) {
        console.error(`Error creating workflow "${finalCatalogName}":`, error);
        toast.error({
          title: `Failed to create workflow "${finalCatalogName}"`,
          description: error.message,
        });
      }
    };

    if (selectedFolders.length > 0) {
      // Recurring schedule
      if (calledFrom === "recurring") {
        if (selectedOption === "createMultipleCatalogs") {
          for (const selectedFolderPath of selectedFolders) {
            const catalogName =
              selectedFolderPath === "Unfoldered"
                ? usedCatalog
                : selectedFolderPath;
            await handleCreateWorkflow(catalogName, [selectedFolderPath]);
          }
        } else {
          await handleCreateWorkflow(usedCatalog, selectedFolders);
        }
      }
      // One time schedule
      else {
        if (selectedOption === "createMultipleCatalogs") {
          for (const selectedFolderPath of selectedFolders) {
            const files = getFilesForFolder(selectedFolderPath);
            if (files.length > 0) {
              const catalogName =
                selectedFolderPath === "Unfoldered"
                  ? usedCatalog
                  : selectedFolderPath;
              await createCatalog(catalogName, files);
            }
          }
        } else {
          const allFiles = selectedFolders.flatMap(getFilesForFolder);
          await createCatalog(singleCatalogName, allFiles);
        }
      }
    }
  };

  const handleCreateCatalogButtonClick = () => {
    const selectedFolders = Object.keys(checkedFolders).filter(
      (folder) => checkedFolders[folder],
    );
    const foldersWithFileCount = selectedFolders.reduce((acc, folder) => {
      const fileCount = Object.values(checkedItems).filter(
        (item) =>
          item.isChecked &&
          (!item.folder ||
            item.folder === "/" ||
            item.folder === folder ||
            (item.folder && item.folder.startsWith(`${folder}/`))),
      ).length;
      acc[folder] = fileCount;
      return acc;
    }, {});

    const unfolderCount = Object.values(checkedItems).filter(
      (item) => item.isChecked && (!item.folder || item.folder === "/"),
    ).length;
    if (unfolderCount > 0) {
      foldersWithFileCount["Unfoldered"] = unfolderCount;
    }

    setSelectedFoldersForConfirmation(foldersWithFileCount);
    setIsConfirmationView(true);
  };

  const ConfirmationView = ({
    folders,
    onBack,
    onConfirm,
    currentCatalogName,
    checkedItems,
  }) => {
    const [confirmedFolders, setConfirmedFolders] = useState(() => {
      const initialState = {};

      Object.entries(checkedItems).forEach(([fileName, item]) => {
        if (item.isChecked) {
          const folderPath = item.folder || "Unfoldered";
          if (!initialState[folderPath]) {
            initialState[folderPath] = {
              totalFiles: 0,
              selectedFiles: 0,
              isSelected: true,
            };
          }
          initialState[folderPath].totalFiles++;
          initialState[folderPath].selectedFiles++;

          // Update parent folders
          let parentPath = folderPath.split("/").slice(0, -1).join("/");
          while (parentPath) {
            if (!initialState[parentPath]) {
              initialState[parentPath] = {
                totalFiles: 0,
                selectedFiles: 0,
                isSelected: false,
              };
            }
            initialState[parentPath].totalFiles++;
            // Don't increment selectedFiles for parent folders
            parentPath = parentPath.split("/").slice(0, -1).join("/");
          }
        }
      });

      // Second pass remains unchanged
      Object.keys(initialState).forEach((folderPath) => {
        const parts = folderPath.split("/");
        if (parts.length > 1) {
          const parentPath = parts.slice(0, -1).join("/");
          if (initialState[parentPath]) {
            initialState[parentPath].isSelected = false;
          }
        }
      });

      return initialState;
    });

    const [selectedOption, setSelectedOption] = useState(
      "createMultipleCatalogs",
    );
    const [singleCatalogName, setSingleCatalogName] = useState("");
    const [catalogNameError, setCatalogNameError] = useState("");

    const isOnlyOneFolder = Object.keys(confirmedFolders).length === 1;
    const isAllUnfoldered =
      Object.keys(confirmedFolders).length === 1 &&
      Object.keys(confirmedFolders)[0] === "Unfoldered";

    useEffect(() => {
      if (isOnlyOneFolder || isAllUnfoldered) {
        setSelectedOption("scheduleAllFiles");
      }
    }, [isOnlyOneFolder, isAllUnfoldered]);

    const handleToggleFolder = (folder) => {
      setConfirmedFolders((prev) => {
        const newState = { ...prev };
        const folderData = newState[folder];

        if (folderData) {
          folderData.isSelected = !folderData.isSelected;

          // If selecting, update parent folders
          if (folderData.isSelected) {
            let parentPath = folder.split("/").slice(0, -1).join("/");
            while (parentPath) {
              if (newState[parentPath]) {
                newState[parentPath].isSelected = false;
              }
              parentPath = parentPath.split("/").slice(0, -1).join("/");
            }
          }

          // Update selectedFiles count only for this folder
          folderData.selectedFiles = folderData.isSelected
            ? folderData.totalFiles
            : 0;
        }

        return newState;
      });
    };

    const renderFolderTree = () => {
      const sortedFolders = Object.keys(confirmedFolders).sort((a, b) =>
        a.localeCompare(b),
      );
      const folderStructure = {};

      // Build the folder structure
      sortedFolders.forEach((fullPath) => {
        const parts = fullPath.split("/");
        let currentLevel = folderStructure;
        parts.forEach((part, index) => {
          if (!currentLevel[part]) {
            currentLevel[part] = {
              fullPath: parts.slice(0, index + 1).join("/"),
              children: {},
            };
          }
          currentLevel = currentLevel[part].children;
        });
      });

      // Recursive function to render folders
      const renderFolder = (folderObj, name, level = 0) => {
        const fullPath = folderObj.fullPath;
        const folderData = confirmedFolders[fullPath] || {
          totalFiles: 0,
          selectedFiles: 0,
          isSelected: false,
        };
        const hasSelectedSubfolders = Object.keys(folderObj.children).some(
          (childName) =>
            confirmedFolders[`${fullPath}/${childName}`]?.isSelected,
        );

        const isUnfoldered = fullPath === "Unfoldered";
        const displayName = isUnfoldered ? currentCatalogName : name;

        const folderComponent = (
          <div key={fullPath} style={{ marginLeft: `${level * 20}px` }}>
            <div className="flex items-center p-2 bg-gray-100 rounded mb-2">
              <input
                type="checkbox"
                id={fullPath}
                checked={folderData.isSelected}
                onChange={() => handleToggleFolder(fullPath)}
                className="mr-2"
              />
              <FontAwesomeIcon
                icon={faFolder}
                className="text-yellow-500 mr-2"
              />
              <label
                htmlFor={fullPath}
                className="flex-grow text-sm font-medium"
              >
                {displayName} (
                <span className="font-bold">{folderData.selectedFiles}</span>{" "}
                files)
                {hasSelectedSubfolders && (
                  <span className="ml-2 text-xs text-blue-600">
                    (subfolder selected)
                  </span>
                )}
                {isUnfoldered && (
                  <span className="ml-2 text-xs text-green-600 font-semibold">
                    (current catalog)
                  </span>
                )}
              </label>
            </div>
            {Object.entries(folderObj.children).map(([childName, childObj]) =>
              renderFolder(childObj, childName, level + 1),
            )}
          </div>
        );

        return folderComponent;
      };

      // Render the top-level folders
      return Object.entries(folderStructure).map(([name, obj]) =>
        renderFolder(obj, name),
      );
    };

    const handleCatalogNameChange = (e) => {
      setSingleCatalogName(e.target.value);
      setCatalogNameError("");
    };

    const handleConfirm = () => {
      if (selectedOption === "scheduleAllFiles" && !singleCatalogName.trim()) {
        setCatalogNameError("Please enter a valid catalog name");
        return;
      }
      onConfirm(
        confirmedFolders,
        selectedOption,
        singleCatalogName,
        selectedPeriod,
      );
    };

    const selectedFolderCount = Object.values(confirmedFolders).filter(
      (f) => f.isSelected && f.selectedFiles > 0,
    ).length;
    const selectedFileCount = Object.values(confirmedFolders).reduce(
      (sum, f) => (f.isSelected ? sum + f.selectedFiles : sum),
      0,
    );

    return (
      <div className="flex flex-col w-full h-full">
        <ConfirmSchedule
          selectedFolderCount={selectedFolderCount}
          selectedFileCount={selectedFileCount}
          renderFolderTree={renderFolderTree}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          singleCatalogName={singleCatalogName}
          handleCatalogNameChange={handleCatalogNameChange}
          catalogNameError={catalogNameError}
          isOnlyOneFolder={isOnlyOneFolder}
          isAllUnfoldered={isAllUnfoldered}
          setTimeSelectionModalOpen={setTimeSelectionModalOpen}
          calledFrom={calledFrom}
        />
        <div className="flex justify-between items-center my-4">
          <button
            onClick={onBack}
            className="bg-gray-100 rounded-md p-4 flex items-center max-w-lg"
          >
            <FontAwesomeIcon icon={faArrowLeft} className="text-primary mr-3" />
            Back to Schedule Settings
          </button>
          <button
            onClick={handleConfirm}
            className="bg-primary text-white text-lg font-bold py-3 px-10 rounded-md hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50 transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
            disabled={
              selectedOption === "scheduleAllFiles" && !singleCatalogName.trim()
            }
          >
            Confirm {calledFrom === "recurring" ? "Workflow" : "Schedule"}
          </button>
        </div>
      </div>
    );
  };

  if (isConfirmationView) {
    return (
      <div className="flex flex-col px-5 items-center w-full h-full">
        <ConfirmationView
          folders={selectedFoldersForConfirmation}
          onBack={() => setIsConfirmationView(false)}
          onConfirm={(
            confirmedFolders,
            selectedOption,
            singleCatalogName,
            selectedPeriod,
          ) => {
            setIsConfirmationView(false);
            handleCreateCatalogWithFilesAndTags(
              confirmedFolders,
              selectedOption,
              singleCatalogName,
              selectedPeriod,
            );
          }}
          currentCatalogName={usedCatalog}
          checkedItems={checkedItems}
        />
      </div>
    );
  }

  return (
    <div className="h-full w-full">
      <div className="flex flex-col max-h-full overflow-hidden">
        <div className="flex flex-col pt-5 px-5 items-center w-full">
          <div className="flex flex-row justify-between w-full h-full gap-3">
            {calledFrom === "catalog" && (
              <button
                className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                onClick={() => setTimeSelectionModalOpen(false)}
              >
                <FontAwesomeIcon icon={faTimes} className="text-xl" />
              </button>
            )}
            <SelectData
              folders={folders}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
              filteredFolderKeys={filteredFolderKeys}
              checkedFolders={checkedFolders}
              setCheckedFolders={setCheckedFolders}
              isFolderListModalOpen={isFolderListModalOpen}
              integration={integration}
              setShowFilePreview={setShowFilePreview}
              handleIntegrationClick={handleIntegrationClick}
              setIsFolderListModalOpen={setIsFolderListModalOpen}
              calledFrom={calledFrom}
            />
            <ScheduleSettingCalendar
              minTime={minTime}
              maxTime={maxTime}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              selectedPeriod={selectedPeriod}
              setSelectedPeriod={setSelectedPeriod}
              selectedHour={selectedHour}
              setSelectedHour={setSelectedHour}
              selectedDayOfWeek={selectedDayOfWeek}
              setSelectedDayOfWeek={setSelectedDayOfWeek}
              selectedDayOfMonth={selectedDayOfMonth}
              setSelectedDayOfMonth={setSelectedDayOfMonth}
              calledFrom={calledFrom}
            />
          </div>
          <div className="flex w-full justify-between items-center my-4">
            {isFolderListModalOpen ? (
              <button
                onClick={() => {
                  setCheckedItems({});
                  setCheckedFolders({});
                  setIsFolderListModalOpen(false);
                }}
                className="bg-gray-100 rounded-md p-4 flex items-center max-w-lg"
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="text-primary mr-3"
                />
                Back to Select Datasource
              </button>
            ) : (
              <div className="bg-gray-100 rounded-md p-4 flex items-center max-w-lg">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="text-primary mr-3"
                />
                <p className="text-sm text-gray-700">
                  {`To preview scheduled tasks go to `}
                  <i>
                    {calledFrom === "catalog" ? "Task scheduler -> " : ""}
                    {`Scheduler History`}
                  </i>
                </p>
              </div>
            )}
            <button
              onClick={handleCreateCatalogButtonClick}
              disabled={Object.keys(checkedItems).length === 0}
              className="bg-primary text-white text-lg font-bold py-3 px-10 rounded-md hover:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-opacity-50 transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              Next Step
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleSettingComponent;
