import { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataContext } from "../../../context/DataContext";
import { useAtom } from "jotai";

import {
  faFolder,
  faChevronRight,
  faChevronDown,
  faFileAlt,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFileImage,
  faFileCode,
  faFileAudio,
  faFileVideo,
  faFileArchive,
  faCheckCircle,
  faTimesCircle,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import { pdfAtom, pdfSearchAtom } from "../../../atoms";
import { DocumentViewer } from "../DocumentViewer/DocumentViewer";
import { useCatalogDocumentInfoLoader } from "../../../../src/api/queryHooks";

const getCompositeKey = (folderKey, fileName) => `${folderKey}/${fileName}`;

const FolderList = ({
  checkedItems,
  currentFolder,
  filteredFolderKeys,
  folders,
  integration,
  searchText,
  setCheckedItems,
  setCurrentFolder,
  setSearchText,
  mode = "standard",
  filenameFilterList = null, // if it is null, then we do not filter by filenames
  checkedFolders = {},
  setCheckedFolders = () => {},
  foldersOnly = false,
  externalLoading = false,
}) => {
  const {
    catalogFiles,
    quarantinedFiles,
    catalogGetsRenamed,
    usedCatalog,
    preferences,
    setDocumentPreviewDatastoreName,
    documentPreviewDatastoreName,
  } = useContext(DataContext);

  const [pdf, setPdf] = useAtom(pdfAtom);
  const [, setPdfSearch] = useAtom(pdfSearchAtom);
  const loadDocumentInfo = useCatalogDocumentInfoLoader();
  const [isDataFiltered, setIsDataFiltered] = useState(false);
  const [expandedFolders, setExpandedFolders] = useState({});
  const [internalCheckedItems, setInternalCheckedItems] = useState({});
  const [filteredFileCount, setFilteredFileCount] = useState(0);
  const [selectedFilteredFileCount, setSelectedFilteredFileCount] = useState(0);
  const [selectedFolderCount, setSelectedFolderCount] = useState(0);
  const [filteredFolderCount, setFilteredFolderCount] = useState(0);

  const MAX_FILES_TO_DISPLAY = 100;

  const [fullyExpandedFolders, setFullyExpandedFolders] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const countContainedFilesRecursively = (folderContent) => {
    let count = 0;

    if (folderContent.__files__) {
      count += folderContent.__files__.filter(
        (file) => catalogFiles[file] || quarantinedFiles[file],
      ).length;
    }

    Object.keys(folderContent).forEach((key) => {
      if (key !== "__files__" && typeof folderContent[key] === "object") {
        count += countContainedFilesRecursively(folderContent[key]);
      }
    });

    return count;
  };

  const countFilteredFilesRecursively = (folderContent) => {
    let count = 0;

    if (folderContent.__files__) {
      const files = filenameFilterList
        ? folderContent.__files__.filter((file) =>
            filenameFilterList.includes(file),
          )
        : folderContent.__files__;
      count += files.length;
    }

    Object.keys(folderContent).forEach((key) => {
      if (key !== "__files__" && typeof folderContent[key] === "object") {
        count += countFilteredFilesRecursively(folderContent[key]);
      }
    });

    return count;
  };

  const buildHierarchy = (flatFolders) => {
    const root = {};

    for (const path in flatFolders) {
      const parts = path.split("/").filter(Boolean);
      let currentLevel = root;

      if (parts.length === 0) {
        if (!currentLevel[""]) {
          currentLevel[""] = { __files__: [] };
        }
        currentLevel[""].__files__ = flatFolders[path];
      } else {
        parts.forEach((part, index) => {
          if (!currentLevel[part]) {
            currentLevel[part] = { __files__: [] };
          }
          if (index === parts.length - 1) {
            currentLevel[part].__files__ = flatFolders[path];
          }
          currentLevel = currentLevel[part];
        });
      }
    }

    return root;
  };

  const getFileName = (file, folderContent) => {
    const isQdrant =
      folderContent.__files__.length > 0 &&
      typeof folderContent.__files__[0] === "object";

    if (typeof file === "object") {
      return file.file_name;
    }

    return isQdrant
      ? folderContent.__files__.find((f) => f.file_name === file).file_name
      : file;
  };

  const hierarchicalFolders = useMemo(() => buildHierarchy(folders), [folders]);

  const isFilteredFolder = (folderKey) => {
    if (filteredFolderKeys.length === 0) return true;
    return filteredFolderKeys.some((filteredKey) => {
      const normalizedFilteredKey = filteredKey.endsWith("/")
        ? filteredKey.slice(0, -1)
        : filteredKey;
      const normalizedFolderKey = folderKey.endsWith("/")
        ? folderKey.slice(0, -1)
        : folderKey;
      return (
        normalizedFolderKey === normalizedFilteredKey ||
        normalizedFilteredKey.startsWith(`${normalizedFolderKey}/`) ||
        normalizedFolderKey.startsWith(`${normalizedFilteredKey}/`)
      );
    });
  };

  const handleFolderCheckboxChange = (fullFolderKey) => {
    const isFolderChecked = !checkedFolders[fullFolderKey];

    setCheckedFolders((prevCheckedFolders) => {
      const newCheckedFolders = { ...prevCheckedFolders };

      const updateFolderState = (folderKey) => {
        newCheckedFolders[folderKey] = isFolderChecked;
        const folderContent = folderKey
          .split("/")
          .reduce((acc, key) => acc[key], hierarchicalFolders);

        Object.keys(folderContent).forEach((key) => {
          if (key !== "__files__" && typeof folderContent[key] === "object") {
            const childFolderKey = `${folderKey}/${key}`;
            updateFolderState(childFolderKey);
          }
        });
      };

      updateFolderState(fullFolderKey);
      return newCheckedFolders;
    });

    setInternalCheckedItems((prevInternalCheckedItems) => {
      const newInternalCheckedItems = { ...prevInternalCheckedItems };

      const updateFileState = (folderKey) => {
        const folderContent = folderKey
          .split("/")
          .reduce((acc, key) => acc[key], hierarchicalFolders);

        if (folderContent.__files__) {
          folderContent.__files__.forEach((file) => {
            const fileName = getFileName(file, folderContent);
            const compositeKey = `${folderKey}/${fileName}`;
            if (isFolderChecked) {
              newInternalCheckedItems[compositeKey] = true;
            } else {
              delete newInternalCheckedItems[compositeKey];
            }
          });
        }

        Object.keys(folderContent).forEach((key) => {
          if (key !== "__files__" && typeof folderContent[key] === "object") {
            const childFolderKey = `${folderKey}/${key}`;
            updateFileState(childFolderKey);
          }
        });
      };

      updateFileState(fullFolderKey);
      return newInternalCheckedItems;
    });

    // Update checkedItems to maintain its original structure
    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = { ...prevCheckedItems };
      const updateCheckedItems = (folderKey) => {
        const folderContent = folderKey
          .split("/")
          .reduce((acc, key) => acc[key], hierarchicalFolders);
        if (folderContent.__files__) {
          folderContent.__files__.forEach((file) => {
            const fileName = getFileName(file, folderContent);
            if (isFolderChecked) {
              newCheckedItems[fileName] = {
                isChecked: true,
                folder: folderKey,
                source: integration,
                ...(typeof file === "object" && { point_ids: file.point_ids }),
              };
            } else {
              delete newCheckedItems[fileName];
            }
          });
        }
        Object.keys(folderContent).forEach((key) => {
          if (key !== "__files__" && typeof folderContent[key] === "object") {
            const childFolderKey = `${folderKey}/${key}`;
            updateCheckedItems(childFolderKey);
          }
        });
      };
      updateCheckedItems(fullFolderKey);
      return newCheckedItems;
    });

    if (!isFolderChecked) {
      setExpandedFolders((prevExpandedFolders) => {
        const newExpandedFolders = { ...prevExpandedFolders };
        const foldSubfolders = (folderKey) => {
          delete newExpandedFolders[folderKey];
          const folderContent = folderKey
            .split("/")
            .reduce((acc, key) => acc[key], hierarchicalFolders);
          Object.keys(folderContent).forEach((key) => {
            if (key !== "__files__" && typeof folderContent[key] === "object") {
              const childFolderKey = `${folderKey}/${key}`;
              foldSubfolders(childFolderKey);
            }
          });
        };
        foldSubfolders(fullFolderKey);
        return newExpandedFolders;
      });
    }
  };

  const handleCheckboxChange = (e, file, folderKey) => {
    const getFolderContent = (path) => {
      return path
        .split("/")
        .reduce((acc, key) => acc && acc[key], hierarchicalFolders);
    };

    const folderContent = getFolderContent(folderKey);
    if (!folderContent || !folderContent.__files__) {
      setCheckedItems((prevState) => {
        const isChecked = !(prevState[file]?.isChecked ?? false);
        if (!isChecked && prevState[file]) {
          const { [file]: removedFile, ...rest } = prevState;
          return rest;
        }
        return {
          ...prevState,
          [file]: {
            isChecked,
            folder: folderKey,
            source: integration,
          },
        };
      });
      return;
    }

    const isQdrant =
      folderContent.__files__.length > 0 &&
      typeof folderContent.__files__[0] === "object";

    file = getFileName(file, folderContent);

    const compositeKey = `${folderKey}/${file}`;
    const isChecked = !internalCheckedItems[compositeKey];

    setInternalCheckedItems((prevInternalCheckedItems) => ({
      ...prevInternalCheckedItems,
      [compositeKey]: isChecked,
    }));

    setCheckedItems((prevState) => {
      if (!isChecked && prevState[file]) {
        const { [file]: removedFile, ...rest } = prevState;
        return rest;
      }
      return {
        ...prevState,
        [file]: {
          isChecked,
          folder: folderKey,
          source: integration,
          ...(isQdrant && {
            point_ids: folderContent.__files__.find((f) => f.file_name === file)
              .point_ids,
          }),
        },
      };
    });

    setCheckedFolders((prevCheckedFolders) => {
      const newCheckedFolders = { ...prevCheckedFolders };
      const folderContent = folderKey
        .split("/")
        .reduce((acc, key) => acc[key], hierarchicalFolders);
      const allFiles = folderContent.__files__ || [];
      const allChecked = allFiles.every((f) => {
        const fileName = getFileName(f, folderContent);
        return internalCheckedItems[`${folderKey}/${fileName}`];
      });
      newCheckedFolders[folderKey] = allChecked;
      return newCheckedFolders;
    });

    updateFileCounts();
  };

  const countTotalFiles = (folderContent) => {
    let count = folderContent.__files__?.length || 0;

    Object.keys(folderContent).forEach((key) => {
      if (key !== "__files__" && typeof folderContent[key] === "object") {
        count += countTotalFiles(folderContent[key]);
      }
    });

    return count;
  };

  const isPrepared = (fileData) => {
    return (
      fileData &&
      fileData.Key_Questions &&
      fileData.Key_Questions.length > 0 &&
      fileData.Themes &&
      fileData.Themes.length > 0 &&
      fileData.Entities &&
      fileData.Entities.length > 0
    );
  };

  const calculatePreparedPercentage = (folderContent) => {
    let totalFiles = 0;
    let preparedFiles = 0;

    const countFiles = (content) => {
      if (content.__files__) {
        totalFiles += content.__files__.length;
        preparedFiles += content.__files__.filter((file) =>
          isPrepared(catalogFiles[file]),
        ).length;
      }

      Object.keys(content).forEach((key) => {
        if (key !== "__files__" && typeof content[key] === "object") {
          countFiles(content[key]);
        }
      });
    };

    countFiles(folderContent);

    return totalFiles > 0 ? Math.round((preparedFiles / totalFiles) * 100) : 0;
  };

  const handleFolderClick = (folderKey) => {
    setExpandedFolders((prevExpandedFolders) => {
      if (prevExpandedFolders[folderKey]) {
        const { [folderKey]: _, ...rest } = prevExpandedFolders;
        return rest;
      } else {
        return {
          ...prevExpandedFolders,
          [folderKey]: true,
        };
      }
    });
  };

  const openFile = useCallback(
    async (file, folderKey) => {
      const file_name = file;
      if (
        file_name?.toLowerCase().endsWith(".pdf") ||
        file_name?.toLowerCase().endsWith(".docx")
      ) {
        if (catalogFiles.hasOwnProperty(file_name)) {
          setPdf(`${catalogFiles[file_name].file_directory}/${file_name}`);
          setDocumentPreviewDatastoreName(
            catalogFiles[file_name].data_store_name,
          );
        } else {
          setPdf(
            `${preferences.webapp_profile.DATA_STORES[integration].base_path}/${folderKey}/${file_name}`,
          );
          setDocumentPreviewDatastoreName(integration);
        }
        setPdfSearch({});
      } else {
        const documentInfo = await loadDocumentInfo(
          file,
          integration,
          folderKey,
        );
        window.open(documentInfo.file_url, "_blank");
      }
    },
    [integration, loadDocumentInfo, setPdf, setPdfSearch],
  );

  const handleSelectPrepared = async () => {
    setIsLoading(true);
    const allPreparedFiles = Object.keys(folders).flatMap((folderKey) =>
      (folders[folderKey] || []).filter(
        (file) =>
          (filenameFilterList === null || filenameFilterList.includes(file)) &&
          isPrepared(catalogFiles[file]) &&
          catalogFiles.hasOwnProperty(file),
      ),
    );

    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = { ...prevCheckedItems };
      allPreparedFiles.forEach((file) => {
        newCheckedItems[file] = {
          isChecked: true,
          folder: Object.keys(folders).find((key) =>
            folders[key].includes(file),
          ),
          source: integration,
        };
      });
      return newCheckedItems;
    });
    setIsDataFiltered(true);
    setIsLoading(false);
  };

  const handleSelectUnpreparedFiles = async () => {
    setIsLoading(true);
    const allUnpreparedFiles = Object.keys(folders).flatMap((folderKey) =>
      (folders[folderKey] || []).filter(
        (file) =>
          (filenameFilterList === null || filenameFilterList.includes(file)) &&
          !isPrepared(catalogFiles[file]) &&
          catalogFiles.hasOwnProperty(file),
      ),
    );

    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = { ...prevCheckedItems };
      allUnpreparedFiles.forEach((file) => {
        newCheckedItems[file] = {
          isChecked: true,
          folder: Object.keys(folders).find((key) =>
            folders[key].includes(file),
          ),
          source: integration,
        };
      });
      return newCheckedItems;
    });
    setIsDataFiltered(true);
    setIsLoading(false);
  };

  const handleSearchChange = (event) => {
    const searchValue = event.target.value.toLowerCase();
    setSearchText(searchValue);

    setExpandedFolders((prevExpandedFolders) => {
      const newExpandedFolders = { ...prevExpandedFolders };

      const expandFoldersWithMatchingFiles = (
        folderContent,
        currentPath = "",
      ) => {
        let hasMatch = false;

        if (folderContent.__files__) {
          const matchingFiles = folderContent.__files__.filter((file) =>
            (typeof file === "object" ? file.file_name : file)
              .toLowerCase()
              .includes(searchValue),
          );
          if (matchingFiles.length > 0) {
            hasMatch = true;
          }
        }

        Object.keys(folderContent).forEach((key) => {
          if (key !== "__files__" && typeof folderContent[key] === "object") {
            const newPath = currentPath ? `${currentPath}/${key}` : key;
            const subFolderHasMatch = expandFoldersWithMatchingFiles(
              folderContent[key],
              newPath,
            );
            if (subFolderHasMatch) {
              newExpandedFolders[newPath] = true;
              hasMatch = true;
            }
          }
        });

        if (hasMatch && currentPath) {
          newExpandedFolders[currentPath] = true;
        }

        return hasMatch;
      };

      expandFoldersWithMatchingFiles(hierarchicalFolders);

      return newExpandedFolders;
    });
  };

  const handleSelectAll = () => {
    let allFiles = [];
    let allFolders = [];

    const collectFilesAndFoldersRecursively = (
      folderContent,
      currentPath = "",
    ) => {
      if (folderContent.__files__) {
        const files = folderContent.__files__.map((file) =>
          typeof file === "object" ? file.file_name : file,
        );
        allFiles = allFiles.concat(
          files.map((file) => ({ file, folder: currentPath })),
        );
      }

      Object.keys(folderContent).forEach((key) => {
        if (key !== "__files__" && typeof folderContent[key] === "object") {
          const newPath = currentPath ? `${currentPath}/${key}` : key;
          allFolders.push(newPath);
          collectFilesAndFoldersRecursively(folderContent[key], newPath);
        }
      });
    };

    collectFilesAndFoldersRecursively(hierarchicalFolders);

    if (mode === "autoCreation") {
      allFiles = allFiles.filter(
        ({ file }) =>
          (filenameFilterList === null || filenameFilterList.includes(file)) &&
          catalogFiles.hasOwnProperty(file),
      );
    } else {
      allFiles = allFiles.filter(
        ({ file }) =>
          filenameFilterList === null || filenameFilterList.includes(file),
      );
    }

    const anyChecked =
      allFiles.some(({ file }) => checkedItems[file]?.isChecked) ||
      allFolders.some((folder) => checkedFolders[folder]);

    setCheckedItems((prevCheckedItems) => {
      const newCheckedItems = { ...prevCheckedItems };

      if (anyChecked) {
        allFiles.forEach(({ file }) => {
          delete newCheckedItems[file];
        });
      } else {
        allFiles.forEach(({ file, folder }) => {
          newCheckedItems[file] = {
            isChecked: true,
            folder: folder,
            source: integration,
          };
        });
      }

      return newCheckedItems;
    });

    setCheckedFolders((prevCheckedFolders) => {
      const newCheckedFolders = { ...prevCheckedFolders };
      allFolders.forEach((folder) => {
        newCheckedFolders[folder] = !anyChecked;
      });
      return newCheckedFolders;
    });

    // Fold everything when deselecting all
    if (anyChecked) {
      setExpandedFolders({});
    }

    setIsDataFiltered(!anyChecked);
  };

  const getFileIcon = (fileName) => {
    const extension = fileName.split(".").pop().toLowerCase();
    switch (extension) {
      case "pdf":
        return faFilePdf;
      case "doc":
      case "docx":
        return faFileWord;
      case "xls":
      case "xlsx":
        return faFileExcel;
      case "ppt":
      case "pptx":
        return faFilePowerpoint;
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return faFileImage;
      case "js":
      case "py":
      case "java":
      case "html":
      case "css":
        return faFileCode;
      case "mp3":
      case "wav":
        return faFileAudio;
      case "mp4":
      case "avi":
        return faFileVideo;
      case "zip":
      case "rar":
        return faFileArchive;
      default:
        return faFileAlt;
    }
  };

  const countFilteredFiles = (folderContent) => {
    let count = 0;
    if (folderContent.__files__) {
      count += filenameFilterList
        ? folderContent.__files__.filter((file) =>
            filenameFilterList.includes(file),
          ).length
        : folderContent.__files__.length;
    }
    Object.keys(folderContent).forEach((key) => {
      if (key !== "__files__" && typeof folderContent[key] === "object") {
        count += countFilteredFiles(folderContent[key]);
      }
    });
    return count;
  };

  // Function to update filtered and selected file counts
  const updateFileCounts = useCallback(() => {
    let filteredCount = 0;
    let selectedCount = 0;

    const countFiles = (folderContent) => {
      if (folderContent.__files__) {
        const filteredFiles = filenameFilterList
          ? folderContent.__files__.filter((file) =>
              filenameFilterList.includes(file),
            )
          : folderContent.__files__;

        filteredCount += filteredFiles.length;
        selectedCount += filteredFiles.filter(
          (file) => checkedItems[file]?.isChecked,
        ).length;
      }

      Object.keys(folderContent).forEach((key) => {
        if (key !== "__files__" && typeof folderContent[key] === "object") {
          countFiles(folderContent[key]);
        }
      });
    };

    countFiles(hierarchicalFolders);
    setFilteredFileCount(filteredCount);
    setSelectedFilteredFileCount(selectedCount);
  }, [hierarchicalFolders, filenameFilterList, checkedItems]);

  const countFolders = useMemo(() => {
    const countRecursive = (folderContent, isFiltered = true) => {
      let count = 0;
      Object.keys(folderContent).forEach((key) => {
        if (key !== "__files__" && typeof folderContent[key] === "object") {
          if (isFiltered && isFilteredFolder(`${key}`)) {
            count++;
          }
          count += countRecursive(folderContent[key], isFiltered);
        }
      });
      return count;
    };

    const filteredCount = countRecursive(hierarchicalFolders);
    const selectedCount = Object.values(checkedFolders).filter(Boolean).length;

    return { filteredCount, selectedCount };
  }, [hierarchicalFolders, checkedFolders, isFilteredFolder]);

  useEffect(() => {
    setFilteredFolderCount(countFolders.filteredCount);
    setSelectedFolderCount(countFolders.selectedCount);
  }, [countFolders]);

  useEffect(() => {
    updateFileCounts();
  }, [
    updateFileCounts,
    filteredFolderKeys,
    filenameFilterList,
    checkedItems,
    checkedFolders,
  ]);

  const renderFolder = (
    folderKey,
    folderContent,
    parentKey = "",
    level = 0,
  ) => {
    const fullFolderKey = parentKey ? `${parentKey}/${folderKey}` : folderKey;

    if (Array.isArray(filenameFilterList) && filenameFilterList.length === 0) {
      return null;
    }

    if (!isFilteredFolder(fullFolderKey)) {
      return null;
    }

    let filesInFolder = folderContent.__files__ || [];
    const subfolders = Object.keys(folderContent).filter(
      (key) => key !== "__files__",
    );

    if (filenameFilterList !== null) {
      filesInFolder = filesInFolder.filter((file) =>
        filenameFilterList.includes(file),
      );
    }

    const shouldRenderFolder =
      filesInFolder.length > 0 ||
      subfolders.some((subKey) =>
        isFilteredFolder(`${fullFolderKey}/${subKey}`),
      );

    if (!shouldRenderFolder) {
      return null;
    }

    const filteredTotalFiles = countFilteredFilesRecursively(folderContent);
    const containedFiles = countContainedFilesRecursively(folderContent);
    const containedFilesPercentage =
      filteredTotalFiles > 0
        ? Math.round((containedFiles / filteredTotalFiles) * 100)
        : 0;

    const isFolderSelected = checkedFolders[fullFolderKey];

    const preparedFilesPercentage = calculatePreparedPercentage(folderContent);

    const handleFolderCheckboxClick = (e) => {
      e.stopPropagation();
      handleFolderCheckboxChange(fullFolderKey);
    };

    const handleFolderExpandClick = () => {
      handleFolderClick(fullFolderKey);
    };

    const folderPadding = level * 20;
    const filePadding = folderPadding + 30;

    return (
      <li
        key={fullFolderKey}
        className={`border-b px-2 ${isFolderSelected ? "selected" : ""} ${
          containedFilesPercentage === 100 && mode === "standard"
            ? "!bg-light !bg-opacity-40"
            : "bg-slate-100"
        }`}
      >
        <div
          className="flex items-center py-2 bg-slate"
          style={{ paddingLeft: `${folderPadding}px` }}
        >
          <div
            className="cursor-pointer w-4 mr-2"
            onClick={handleFolderExpandClick}
          >
            <FontAwesomeIcon
              icon={
                expandedFolders[fullFolderKey] ? faChevronDown : faChevronRight
              }
            />
          </div>
          <input
            type="checkbox"
            id={`checkbox-folder-${fullFolderKey}`}
            className="appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 transition duration-200 ease-in-out cursor-pointer mr-2 flex-shrink-0"
            onChange={handleFolderCheckboxClick}
            checked={isFolderSelected}
          />

          <div
            className="cursor-pointer flex-grow flex justify-between items-center"
            onClick={handleFolderExpandClick}
          >
            <div>
              <FontAwesomeIcon
                icon={faFolder}
                className="pr-1 text-yellow-400"
              />
              {folderKey}
            </div>
            <span className="file-count ml-2 text-gray-500">
              {filteredTotalFiles} file{filteredTotalFiles !== 1 ? "s" : ""}
              {mode !== "autoCreation" && (
                <> ({containedFilesPercentage}% contained)</>
              )}
              {mode === "autoCreation" && (
                <> ({preparedFilesPercentage}% prepared)</>
              )}
            </span>
          </div>
        </div>

        {expandedFolders[fullFolderKey] && (
          <>
            {filesInFolder.length > 0 && (
              <ul className="file-tree">
                {filesInFolder
                  .filter((file) =>
                    file.toLowerCase().includes(searchText.toLowerCase()),
                  )
                  .slice(
                    0,
                    fullyExpandedFolders[fullFolderKey]
                      ? undefined
                      : MAX_FILES_TO_DISPLAY,
                  )
                  .map((file, index) => {
                    const isContained =
                      catalogFiles[file] || quarantinedFiles[file];
                    const hasDataArtifacts =
                      isPrepared(catalogFiles[file]) ?? false;
                    const fileIcon = getFileIcon(file);

                    return (
                      <li
                        key={getCompositeKey(fullFolderKey, file)}
                        className={`p-2 flex gap-2 items-center border-b border-x justify-between md:flex-nowrap flex-wrap break-all ${
                          isContained &&
                          (mode === "standard" || mode === "tagStudio")
                            ? "bg-light bg-opacity-30"
                            : index % 2
                              ? "bg-slate-50"
                              : "bg-slate-100"
                        }`}
                        style={{ paddingLeft: `${filePadding}px` }}
                      >
                        <div className="flex items-center">
                          {!foldersOnly && (
                            <input
                              type="checkbox"
                              className="appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 transition duration-200 ease-in-out cursor-pointer mr-2 flex-shrink-0"
                              onChange={(e) =>
                                handleCheckboxChange(e, file, fullFolderKey)
                              }
                              checked={
                                internalCheckedItems[
                                  getCompositeKey(fullFolderKey, file)
                                ]
                              }
                            />
                          )}
                          <FontAwesomeIcon
                            icon={fileIcon}
                            className="text-black text-sm mr-2"
                          />
                          {file}
                        </div>
                        <div className="flex items-center md:w-auto w-full justify-end">
                          {mode === "autoCreation" && (
                            <FontAwesomeIcon
                              icon={
                                hasDataArtifacts ? faCheckCircle : faTimesCircle
                              }
                              className={
                                hasDataArtifacts
                                  ? "text-green-500"
                                  : "text-red-500"
                              }
                              title={
                                hasDataArtifacts
                                  ? "Data is prepared"
                                  : "Data is not prepared"
                              }
                            />
                          )}
                          <button
                            onClick={() => openFile(file, fullFolderKey)}
                            className={`px-2 py-1 text-buttonGrey rounded-md whitespace-nowrap ml-2 flex items-center justify-center ${
                              file.toLowerCase().endsWith(".txt")
                                ? "opacity-50 cursor-not-allowed"
                                : "hover:bg-buttonGrey hover:text-white"
                            }`}
                            disabled={file.toLowerCase().endsWith(".txt")}
                          >
                            <FontAwesomeIcon icon={faEye} />
                          </button>
                        </div>
                      </li>
                    );
                  })}
                {filesInFolder.length > MAX_FILES_TO_DISPLAY &&
                  !fullyExpandedFolders[fullFolderKey] && (
                    <li
                      className="p-2 text-center text-gray-500 cursor-pointer"
                      onClick={() => {
                        setFullyExpandedFolders((prevState) => ({
                          ...prevState,
                          [fullFolderKey]: true,
                        }));
                      }}
                    >
                      Show more files...
                    </li>
                  )}
              </ul>
            )}
            {subfolders.length > 0 && (
              <ul className="subfolder-list">
                {subfolders.map((subKey) =>
                  renderFolder(
                    subKey,
                    folderContent[subKey],
                    fullFolderKey,
                    level + 1,
                  ),
                )}
              </ul>
            )}
          </>
        )}
      </li>
    );
  };

  const renderFolderList = () => {
    if (isLoading || catalogGetsRenamed || externalLoading) {
      return (
        <div className="h-full p-4 flex flex-col rounded-md overflow-hidden justify-center items-center">
          <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-primary"></div>
          <p className="mt-4 text-base font-semibold">
            {isLoading || externalLoading ? "Processing..." : `Loading ${usedCatalog}...`}
          </p>
        </div>
      );
    }

    return (
      <>
        <div className="w-full">
          <div className="flex flex-col items-start gap-3 p-1">
            <input
              type="text"
              placeholder="Search..."
              className="w-full px-4 py-1.5 rounded-md outline-none border text-sm"
              value={searchText}
              onChange={handleSearchChange}
            />
            <div className="flex flex-wrap gap-2">
              <button
                onClick={handleSelectAll}
                className={`rounded-md text-sm border-2 border-primary whitespace-nowrap px-2 py-1 ${
                  isDataFiltered
                    ? "bg-primary text-white"
                    : "bg-white text-primary hover:bg-primary hover:text-white"
                }`}
              >
                {isDataFiltered
                  ? "Deselect all selected data"
                  : "Select all data"}
              </button>
              {mode === "autoCreation" && (
                <>
                  <button
                    onClick={handleSelectPrepared}
                    className="rounded-md text-sm border-2 border-primary whitespace-nowrap px-2 py-1 bg-white text-primary hover:bg-primary hover:text-white"
                  >
                    Select Prepared Files
                  </button>
                  <button
                    onClick={handleSelectUnpreparedFiles}
                    className="rounded-md text-sm border-2 border-primary whitespace-nowrap px-2 py-1 bg-white text-primary hover:bg-primary hover:text-white"
                  >
                    Select Unprepared Files
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="w-full px-4 py-2 bg-gray-100 border-t border-b flex justify-end items-center">
          {!foldersOnly ? (
            <span className="font-semibold text-primary text-sm">
              {selectedFilteredFileCount} of {filteredFileCount} file
              {filteredFileCount !== 1 ? "s" : ""} selected
            </span>
          ) : (
            <span className="font-semibold text-primary text-sm">
              {selectedFolderCount} of {filteredFolderCount} folder
              {filteredFolderCount !== 1 ? "s" : ""} selected
            </span>
          )}
        </div>
        <div className="flex flex-col overflow-hidden pb-[10vh]">
          <ul className="w-full text-xs h-full overflow-auto">
            {Object.keys(hierarchicalFolders).map((folderKey) =>
              renderFolder(folderKey, hierarchicalFolders[folderKey]),
            )}
          </ul>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (Object.keys(checkedItems).length === 0) {
      setCheckedItems([]);
    }
    // Update internalCheckedItems based on checkedItems
    setInternalCheckedItems((prevInternalCheckedItems) => {
      const newInternalCheckedItems = {};
      Object.keys(checkedItems).forEach((file) => {
        if (checkedItems[file].isChecked) {
          const compositeKey = `${checkedItems[file].folder}/${file}`;
          newInternalCheckedItems[compositeKey] = true;
        }
      });
      return newInternalCheckedItems;
    });

    // Update checkedFolders based on checkedItems and internalCheckedItems
    setCheckedFolders((prevCheckedFolders) => {
      const newCheckedFolders = { ...prevCheckedFolders };

      const updateFolderState = (folderKey, folderContent) => {
        if (folderContent.__files__) {
          const allChecked = folderContent.__files__.every((file) => {
            const fileName = getFileName(file, folderContent);
            const compositeKey = `${folderKey}/${fileName}`;
            return internalCheckedItems[compositeKey];
          });
          newCheckedFolders[folderKey] = allChecked && folderContent.__files__.length > 0;
        }

        Object.keys(folderContent).forEach((key) => {
          if (key !== "__files__" && typeof folderContent[key] === "object") {
            const childFolderKey = `${folderKey}/${key}`;
            updateFolderState(childFolderKey, folderContent[key]);
          }
        });
      };

      Object.keys(hierarchicalFolders).forEach((folderKey) => {
        updateFolderState(folderKey, hierarchicalFolders[folderKey]);
      });

      return newCheckedFolders;
    });

    updateFileCounts();
  }, []);

  return (
    <div className="w-full h-full overflow-hidden flex flex-col">
      {pdf && (
        <DocumentViewer
          path={pdf}
          data_store_name={documentPreviewDatastoreName}
        />
      )}
      {renderFolderList()}
    </div>
  );
};

export default FolderList;
