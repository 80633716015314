import React, { useContext } from "react";
import "./ProgressBar.css";
import { TagContext } from "../../../../../context/TagContext";
import { runningTasksAtom } from "../../../../../atoms";
import { useAtom } from "jotai";

export const TaskProgressBar = () => {
  const [runningTasks] = useAtom(runningTasksAtom);
  const { processingTags } = useContext(TagContext);
  const getPercentage = () => {
    const filteredTasks = runningTasks.filter((task) => task.aborted !== true);
    const percentage =
      ((filteredTasks.length - processingTags.length) * 100) /
      filteredTasks.length;

    return isNaN(percentage) ? 0 : percentage;
  };

  if (getPercentage() === 100 || getPercentage() === 0) return <></>;

  return (
    <div className="relative bg-secondary bg-opacity-20 text-center text-xs py-0.5 text-zinc-500">
      <div
        className="absolute inset-y-0 bg-light bg-opacity-30 whitespace-nowrap"
        style={{
          width: `${getPercentage()}%`,
        }}
      ></div>
      {`Tasks running ${getPercentage().toFixed(2)}%`}
    </div>
  );
};

export const NonTaskProgressBar = ({ progress }) => {
  return (
    <div
      data-testid="progressBar"
      className="w-full bg-gray-200 rounded-full relative overflow-hidden shadow-lg items-center justify-center h-5 text-sm"
    >
      <div
        className="bg-deasieTurquoise h-full rounded-full animate-pulse transition-all duration-500 ease-out"
        style={{ width: `${progress}%` }}
      >
        <div
          className="text-white absolute left-0 top-0 h-full bg-gradient-to-r from-transparent via-white to-transparent opacity-50 w-12 animate-slide"
          style={{ transform: `translateX(${progress}%)` }}
        ></div>
      </div>
      <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
        <span className="text-white font-bold">{progress}%</span>
      </div>
    </div>
  );
};
