import { Modal, Box, Typography, CircularProgress } from "@mui/material";
import { useUpateTeamPreferencesMutation } from "../../../api/queryHooks";
import { toast } from "../Toast";
import UserPreferences from "../../pages/Home/HomeComponents/Preferences/Preferences";
export const TeamPreferencesModal = ({ onClose }) => {
  const mutation = useUpateTeamPreferencesMutation({
    onSuccess: () => {
      toast.success({ title: "Team preferences updated", description: "" });
    },
  });
  return (
    <Modal open onClose={onClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "80vw",
          overflow: "auto",
          height: "80vh",
          background: "white",
          p: 4,
          "&>div": {
            overflow: "hidden",
            width: "100%",
          },
        }}
      >
        <Box className="modalHeader">
          <Typography variant="h4" gutterBottom>
            Set preferences for your team
          </Typography>
          <button className="closeButton" type="button" onClick={onClose}>
            X
          </button>
        </Box>
        {mutation.isPending && <CircularProgress />}

        {!mutation.isPending && (
          <UserPreferences
            onSubmit={(values) => mutation.mutate(values)}
            visibleTabs={["profile", "webapp_profile", "hidden_tags"]}
          />
        )}
      </Box>
    </Modal>
  );
};
