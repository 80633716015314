import { useContext, useState } from "react";
import { TagContext } from "../../../../../context/TagContext";
import TabComponent from "../AddNewTag/Tabs.js";
import { SchedulerHistory } from "../../../../utilities/ScheduledTasksModal";
import TagDefinitions from "../DataCatalog/TagDefinitions";
import ScheduleSettingComponent from "../DataCatalog/DataCatalogComponents/Scheduling/ScheduleSettingComponent";

export default function TaskScheduler() {
  const { activeTab, setActiveTab } = useContext(TagContext);

  const [checkedItems, setCheckedItems] = useState({});
  const [folders, setFolders] = useState([]);
  const [filteredFolderKeys] = useState([]);
  const [, setTimeSelectionModalOpen] = useState(false);

  const tabs = [
    {
      label: "Schedule Tagging",
      action: (index) => setActiveTab(index),
    },
    {
      label: "Recurring Tagging",
      action: (index) => setActiveTab(index),
    },
    {
      label: "Scheduler History",
      action: (index) => setActiveTab(index),
    },
  ];

  return (
    <div className="relative flex w-full overflow-hidden h-full transition-all duration-300">
      <div className={`flex flex-row gap-2 w-full`}>
        <div className="w-1/5 transition-all duration-800">
          <TagDefinitions calledFrom={"taskScheduler"} />
        </div>
        <div
          className={`flex-grow h-full bg-white rounded-md overflow-auto flex flex-col text-sm w-full`}
        >
          <div className="h-full overflow-hidden hide-scrollbar flex flex-col">
            <TabComponent tabs={tabs} index_offset={5} />
            {activeTab === 5 && (
              <ScheduleSettingComponent
                folders={folders}
                checkedItems={checkedItems}
                setCheckedItems={setCheckedItems}
                filteredFolderKeys={filteredFolderKeys}
                setFolders={setFolders}
                setTimeSelectionModalOpen={setTimeSelectionModalOpen}
                calledFrom="scheduleTagging"
              />
            )}
            {activeTab === 6 && (
              <ScheduleSettingComponent
                folders={folders}
                checkedItems={checkedItems}
                setCheckedItems={setCheckedItems}
                filteredFolderKeys={filteredFolderKeys}
                setFolders={setFolders}
                setTimeSelectionModalOpen={setTimeSelectionModalOpen}
                calledFrom="recurring"
              />
            )}
            {activeTab === 7 && <SchedulerHistory />}
          </div>
        </div>
      </div>
    </div>
  );
}
