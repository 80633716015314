// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/AdminDashboard.css */

.admin-dashboard {
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 16px;
  }
  
  .admin-dashboard-header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 100%;
    background-color: #f8f9fa;
    border-bottom: 1px solid #dee2e6;
  }
  
  .admin-dashboard-title {
    font-size: 1.5rem;
    color: #343a40;
  }
  
  .admin-dashboard-table {
    width: 100%;
    border-collapse: collapse;
    margin: 0 auto;
  }
  
  .admin-dashboard-table th,
  .admin-dashboard-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .admin-dashboard-table th {
    background-color: #4CAF50;
    color: white;
    text-align: center;
  }
  
  .admin-dashboard-table td {
    text-align: center;
  }
  
  .admin-dashboard-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .admin-dashboard-table tr:hover {
    background-color: #ddd;
  }
  
  .admin-dashboard-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #4CAF50;
    color: white;
  }
  
  .loading {
    text-align: center;
    font-size: 20px;
    color: #4CAF50;
  }`, "",{"version":3,"sources":["webpack://./src/components/pages/Home/HomeComponents/AdminDashboard/AdminDashboard.css"],"names":[],"mappings":"AAAA,sCAAsC;;AAEtC;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;IACb,SAAS;EACX;;EAEA;IACE,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,WAAW;IACX,yBAAyB;IACzB,gCAAgC;EAClC;;EAEA;IACE,iBAAiB;IACjB,cAAc;EAChB;;EAEA;IACE,WAAW;IACX,yBAAyB;IACzB,cAAc;EAChB;;EAEA;;IAEE,sBAAsB;IACtB,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;EACxB;;EAEA;IACE,iBAAiB;IACjB,oBAAoB;IACpB,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,cAAc;EAChB","sourcesContent":["/* src/components/AdminDashboard.css */\n\n.admin-dashboard {\n    display: flex;\n    flex-direction: column;\n    padding: 16px;\n    gap: 16px;\n  }\n  \n  .admin-dashboard-header {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    padding: 10px;\n    width: 100%;\n    background-color: #f8f9fa;\n    border-bottom: 1px solid #dee2e6;\n  }\n  \n  .admin-dashboard-title {\n    font-size: 1.5rem;\n    color: #343a40;\n  }\n  \n  .admin-dashboard-table {\n    width: 100%;\n    border-collapse: collapse;\n    margin: 0 auto;\n  }\n  \n  .admin-dashboard-table th,\n  .admin-dashboard-table td {\n    border: 1px solid #ddd;\n    padding: 8px;\n  }\n  \n  .admin-dashboard-table th {\n    background-color: #4CAF50;\n    color: white;\n    text-align: center;\n  }\n  \n  .admin-dashboard-table td {\n    text-align: center;\n  }\n  \n  .admin-dashboard-table tr:nth-child(even) {\n    background-color: #f2f2f2;\n  }\n  \n  .admin-dashboard-table tr:hover {\n    background-color: #ddd;\n  }\n  \n  .admin-dashboard-table th {\n    padding-top: 12px;\n    padding-bottom: 12px;\n    text-align: center;\n    background-color: #4CAF50;\n    color: white;\n  }\n  \n  .loading {\n    text-align: center;\n    font-size: 20px;\n    color: #4CAF50;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
