import {
  createContext,
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
  useMemo,
} from "react";
import Auth from "../auth/AuthProvider";

import {
  updateCatalog,
  deleteFilesInCatalog,
  getRules,
  renameCatalog,
} from "../components/utilities/functions/apiCalls";
import { ENDPOINTS } from "../api/endpoints";
import { sendRequest } from "../components/utilities/functions/api";
import { LABELS_TO_EXCLUDE_FOR_SEARCH } from "../constants/labelConfig";

import {
  deleteLabelParentLabelObj,
  updateModelVersion,
} from "../components/utilities/functions/utils";
import { toast } from "../components/utilities/Toast";
import { Queue } from "../utils";
import { useCatalogChangeSubscription } from "../hooks/CatalogChangeSubscription";
import { useFailedTags } from "../hooks/FailedTags";
import { useUserProfile } from "./UserProfile";
import { useCreateCatalogMutation, useCatalogNames } from "../api/queryHooks";
import { selectedCatalogItemsAtom } from "../atoms";
import { useAtom } from "jotai";
import { usePersistedState } from "../pages/hooks";

export const DataContext = createContext();

const DEFAULT_CATALOG_NAME = "catalog";

export const DataProvider = ({ children }) => {
  const userProfile = useUserProfile();
  const [preferences, setPreferences] = useState(userProfile);

  const defaultCurrentTag = {
    tag_uuid: "",
    version_uuid: "",
    name: "",
    tagType: "classification",
    description: "",
    availableValues: [],
    max_number_of_values: null,
    allow_other_values: false,
    reference_file: "",
    max_words: 1,
    examples: [],
    neg_examples: [],
    option: "aiGenerated",
    risk_level: "",
    risk_level_description: {
      low: "",
      medium: "",
      high: "",
    },
    type: "word",
    date_format: "MM/DD/YYYY",
    selected_tag_model: [],
    customFields: {},
    is_global: false,
    is_user_level: false,
  };

  const [isLoading, setIsLoading] = useState(true);
  const [showScreen, setShowScreen] = useState("catalog");
  const [usedCatalog, setUsedCatalog] = usePersistedState(
    "selectedCatalog",
    userProfile.system.EXISTING_CATALOG,
  );
  const [taggingRunning, setTaggingRunning] = useState(false);
  const [catalogSummary, setCatalogSummary] = useState({});
  const [dataGroups, setDataGroups] = useState({});
  const [catalogFiles, setCatalogFiles] = useState({});
  const [currentDataGroup, setCurrentDataGroup] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [detectedDataGroup, setDetectedGroup] = useState({});
  const [useCases, setUseCases] = useState([]);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [usecaseSelected, setUsecaseSelected] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [fileUploadProgress, setFileUploadProgress] = useState(-1);
  const [availableTags, setAvailableTags] = useState({});
  const [availableRules, setAvailableRules] = useState({});
  const [ruleDict, setRuleDict] = useState({});
  const [searchDetails, setSearchDetails] = useState({});
  const [quarantinedFiles, setQuarantinedFiles] = useState({});
  const [processingFile, setProcessingFile] = useState(-1);
  const [showConnectData, setShowConnectData] = useState(false);
  const [isEvidenceModalOpen, setIsEvidenceModalOpen] = useState(false);
  const [isTagSelected, setIsTagSelected] = useState(false);
  const [isDocumentEvidenceModalOpen, setIsDocumentEvidenceModalOpen] =
    useState(false);
  const [evidenceData, setEvidenceData] = useState([]);
  const [showFileOnPage, setShowFileOnPage] = useState(null);
  const [catalogGetsRenamed, setCatalogGetsRenamed] = useState(false);
  const [isGloballyExpanded, setIsGloballyExpanded] = useState(false);
  const [validatedScores, setValidatedScores] = useState({});
  const [validatingTag, setValidatingTag] = useState(null);

  const [isTagDefinitionsExpanded, setIsTagDefinitionsExpanded] = useState(true);

  const [currentTag, setCurrentTag] = useState(defaultCurrentTag);
  const [currentProcessCount, setCurrentProcessCount] = useState(0);
  const [currentTotalProcessCount, setCurrentTotalProcessCount] = useState(0);
  const [hiddenCategories, setHiddenCategories] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [startPoint, setStartPoint] = useState(0);
  const fetchCatalogQueueRef = useRef(new Queue({ maxTasks: 2 }));
  const [tagsToBeDeleted, setTagsToBeDeleted] = useState([]);
  const [showFilePreview, setShowFilePreview] = useState(false);
  const [view, setView] = useState("options");
  const [, setSelectedCatalogItems] = useAtom(selectedCatalogItemsAtom);
  const [catalogGetsCreated, setCatalogGetsCreated] = useState(false);
  const [abortedTasks, setAbortedTasks] = useState([]);

  const [catalogTablePage, setCatalogTablePage] = useState(1);
  const [uploadDataProgress, setUploadDataProgress] = useState(0);

  const [autoCreateProgress, setAutoCreateProgress] = useState(0);
  const [isAutoCreating, setIsAutoCreating] = useState(false);

  const [uploadFileTask, setUploadFileTask] = useState(null);
  const [uploadFileTaskProgress, setUploadFileTaskProgress] = useState(null);

  const [documentPreviewDatastoreName, setDocumentPreviewDatastoreName] = useState("");

  const { failedTags, tagReRun, hasTagFailedFor, failedTagDeleted } =
    useFailedTags(currentDataGroup);

  const createCatalogMutation = useCreateCatalogMutation();

  useEffect(() => {
    setSelectedCatalogItems(new Set());
  }, [setSelectedCatalogItems, usedCatalog]);
  const [fetchingCatalog, setFetchingCatalog] = useState(false);

  const getCatalogAndUpdateCatalog = async (
    catalog_name,
    load_chunk = true,
    force_run = false,
  ) => {
    if (!force_run && (!taggingRunning || fetchingCatalog)) return;
    try {
      setFetchingCatalog(true);

      const [SafeFiles] = await Promise.all([
        sendRequest(
          {
            catalog_name: catalog_name,
            load_chunk: load_chunk,
            [preferences.system.API_USERNAME_KEYWORD]: (
              await Auth.currentAuthenticatedUser()
            ).username,
          },
          ENDPOINTS["get_catalog"],
        ).then((r) => r.json()),
      ]);
      setCatalogFiles(SafeFiles.catalog);
      setCatalogSummary(SafeFiles.filter_map);
      setCurrentDataGroup(SafeFiles.catalog);
      setSearchDetails(SafeFiles.search_details);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setFetchingCatalog(false);
    }
  };

  const fetchInitialCatalog = useCallback(
    async (catalog_name = null) => {
      try {
        const [SafeFiles, UnsafeFiles] = await Promise.all([
          sendRequest(
            {
              catalog_name: catalog_name,
              [preferences.system.API_USERNAME_KEYWORD]: (
                await Auth.currentAuthenticatedUser()
              ).username,
            },
            ENDPOINTS["get_catalog"],
          ).then((r) => r.json()),
          sendRequest(
            {
              catalog_name: preferences.system.QUARANTINECATALOG,
              [preferences.system.API_USERNAME_KEYWORD]: (
                await Auth.currentAuthenticatedUser()
              ).username,
            },
            ENDPOINTS["get_catalog"],
          ).then((r) => r.json()),
        ]);

        for (const key in UnsafeFiles.catalog) {
          if (SafeFiles.catalog[key]) {
            delete SafeFiles.catalog[key];
          }
        }
        setCatalogFiles(SafeFiles.catalog);
        setCatalogSummary(SafeFiles.filter_map);
        setCurrentDataGroup(SafeFiles.catalog);
        setSearchDetails(SafeFiles.search_details);
        setQuarantinedFiles(UnsafeFiles.catalog);
        return UnsafeFiles.catalog;
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    [
      preferences.system.API_USERNAME_KEYWORD,
    ],
  );
  const handleTableUpdate = useCallback(() => {
    fetchCatalogQueueRef.current.addNewTask(async () => {
      await fetchInitialCatalog(usedCatalog);
    }, true);
  }, [usedCatalog, fetchInitialCatalog]);

  const handleQuarantinedFileChange = useCallback((fileName, fileEntry) => {
    setQuarantinedFiles((prevQuarantinedFiles) => ({
      ...prevQuarantinedFiles,
      [fileName]: fileEntry,
    }));
  }, []);

  useCatalogChangeSubscription({
    activeCatalog: usedCatalog,
    onChange: handleTableUpdate,
    onQuarantinedFileChange: handleQuarantinedFileChange,
  });

  const {
    isSuccess: wereCatalogNamesSuccesfullyFetched,
    data: catalogNames = [],
    refetch: refetchCatalogNames,
  } = useCatalogNames();

  const hasToCreateDefaultCatalog = useMemo(() => {
    return (
      !catalogNames.includes(DEFAULT_CATALOG_NAME) &&
      wereCatalogNamesSuccesfullyFetched
    );
  }, [catalogNames, wereCatalogNamesSuccesfullyFetched]);

  useEffect(() => {
    if (!hasToCreateDefaultCatalog) return;
    (async () => {
      await createCatalogMutation.mutateAsync(DEFAULT_CATALOG_NAME, {
        key: "catalogAutoCreation",
      });
      refetchCatalogNames();
    })();
  }, [hasToCreateDefaultCatalog, refetchCatalogNames, setUsedCatalog]);

  useEffect(() => {
    getRules(usedCatalog)
      .then((fetchedRules) => {
        const acc = {};
        const rulesToTagDict = fetchedRules.map((rule) => {
          const conditionsDescription = rule.rules
            .map(
              (condition) =>
                `if '${condition.tag_name}' equals '${condition.value}'`,
            )
            .join(" and ");

          const ruleDict = {
            name: rule.output_tag,
            tagType: "rule",
            description: `Apply this tag on conditions: ${conditionsDescription}.`,
            availableValues: [],
            allow_other_values: false,
            reference_file: "",
            max_words: 1,
            examples: null,
            option: "aiGenerated",
            risk_level: "",
            risk_level_description: {
              low: "",
              medium: "",
              high: "",
            },
            type: "word",
          };

          acc[rule.output_tag] = ruleDict;
          return acc;
        });
        setRuleDict(acc);
      })
      .catch((error) => {
        console.error("Failed to fetch rules:", error);
      });
  }, [usedCatalog]);

  useEffect(() => {
    const fetchPreferences = async () => {
      try {
        setPreferences(userProfile);
        setAvailableTags(userProfile.system.TAGGER_LIST);
        const hiddenTags = userProfile.hidden_tags;
        setHiddenCategories([...hiddenCategories, ...hiddenTags]);
      } catch (error) {
        console.error("Error fetching preferences:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchPreferences();
  }, []);

  const handleLabelChange = async (itemKey, labelKey, newValue) => {
    const afterLabelChange = (prevState) => ({
      ...prevState,
      [itemKey]: {
        ...prevState[itemKey],
        [labelKey]: newValue,
      },
    });
    setCurrentDataGroup(afterLabelChange);
    setCatalogFiles(afterLabelChange);

    await updateCatalog(usedCatalog, afterLabelChange(catalogFiles));
    setModalOpen(false);
  };

  const clearAllFilters = () => {
    setSelectedFilters({});
    setHiddenCategories([]);
    setSearchTerm("");
  };

  const deleteAllLabels = async (e, tagNames) => {
    e.stopPropagation();
    const isAdmin = userProfile?.permissions.teams.canEdit === true;
    const tagsToDelete = tagNames
      .map((tagName) => availableTags.llm.tagger_params.tag_dict?.[tagName])
      .filter((tag) => tag !== undefined);

    if (tagsToDelete.length === 0) {
      return;
    }

    const deletingSingleTag = tagsToDelete.length === 1;
    const confirmMessage = deletingSingleTag
      ? `Are you sure you want to delete the label \`${tagsToDelete[0].name}\`?`
      : `Are you sure you want to delete ${tagsToDelete.length} tags?`;

    if (!window.confirm(confirmMessage)) {
      return;
    }

    toast.info({
      title: "Info",
      description: `Preparing ${deletingSingleTag ? `tag ${tagsToDelete[0].name}` : `${tagsToDelete.length} tags`} to be deleted`,
    });

    setTagsToBeDeleted((prev) => [...prev, ...tagsToDelete.map(tag => tag.name)]);

    let NEW_TAGGER_LIST = { ...availableTags };
    tagsToDelete.forEach((tag) => {
      NEW_TAGGER_LIST = deleteLabelParentLabelObj(NEW_TAGGER_LIST, [tag.name]);
    });

    const rawResponse = await sendRequest(
      {
        catalog: JSON.stringify(catalogFiles),
        [preferences.system.API_USERNAME_KEYWORD]: (
          await Auth.currentAuthenticatedUser()
        ).username,
        tags: { ...tagsToDelete },
        catalog_name: usedCatalog,
        new_tagger_list: JSON.stringify(NEW_TAGGER_LIST),
      },
      ENDPOINTS["delete_label"],
    );
    const response = await rawResponse.json();

    toast.success({
      title: "Success",
      description: `${tagsToDelete.length} ${deletingSingleTag ? "tag" : "tags"} successfully deleted`,
    });

    setAvailableTags(NEW_TAGGER_LIST);
    setTagsToBeDeleted((prev) =>
      prev.filter((tag) => !tagsToDelete.map(t => t.name).includes(tag))
    );
    failedTagDeleted(tagsToDelete.map((tag) => tag.name));

    return response;
  };

  const handleDatasetDelete = async (filename) => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the dataset "${filename}"?`,
    );
    if (!confirmDelete) {
      return;
    }
    toast.info({
      title: "Info",
      description: `Preparing dataset ${filename} to be deleted`,
    });

    try {
      const afterDataDelete = (prevState) => {
        const updatedState = { ...prevState };
        delete updatedState[filename];
        return updatedState;
      };

      await deleteFilesInCatalog(usedCatalog, [filename]);
      setCurrentDataGroup(afterDataDelete);
      setCatalogFiles(afterDataDelete);

      toast.success({
        title: "Success",
        description: `Successfully deleted dataset ${filename}`,
      });
    } catch (error) {
      toast.error({
        title: "Error",
        description: `An error happened trying to delete your dataset ${filename}: ${String(
          error,
        )}`,
      });
    }
  };

  const deleteMultipleQuarantine = async (keysToDelete) => {
    if (
      !window.confirm(
        `Are you sure you want to delete ${keysToDelete.length} datasets from the quarantine?`,
      )
    ) {
      return;
    }

    const newDataGroup = { ...quarantinedFiles };

    keysToDelete.forEach((key) => {
      delete newDataGroup[key];
    });

    try {
      await updateCatalog(preferences.system.QUARANTINECATALOG, newDataGroup);
      setQuarantinedFiles(newDataGroup);

      toast.success({
        title: "Success",
        description: `Successfully deleted ${keysToDelete.length} datasets from the catalog`,
      });
    } catch (error) {
      toast.error({
        title: "Error",
        description: `An error occurred while trying to update the catalog: ${String(
          error,
        )}`,
      });
    }
  };

  const deleteMultipleDatasets = async (keysToDelete) => {
    if (
      !window.confirm(
        `Are you sure you want to delete ${keysToDelete.length} datasets from the catalog?`,
      )
    ) {
      return;
    }

    const newDataGroup = { ...catalogFiles };

    keysToDelete.forEach((key) => {
      delete newDataGroup[key];
    });

    try {
      await deleteFilesInCatalog(usedCatalog, keysToDelete);
      setCatalogFiles(newDataGroup);
      setCurrentDataGroup(newDataGroup);
      toast.success({
        title: "Success",
        description: `Successfully deleted ${keysToDelete.length} datasets from the catalog`,
      });
    } catch (error) {
      toast.error({
        title: "Error",
        description: `An error occurred while trying to update the catalog: ${String(
          error,
        )}`,
      });
    }
  };

  const handleMultipleDelete = () => {
    const allKeys = Object.keys(currentDataGroup);
    deleteMultipleDatasets(allKeys);
    setSelectedFilters({});
  };

  const updateTagDict = (newTagDict) => {
    const cleanedTagDict = Object.keys(newTagDict)
      .filter((key) => !LABELS_TO_EXCLUDE_FOR_SEARCH.includes(key))
      .reduce((obj, key) => {
        obj[key] = availableTags.llm.tagger_params.tag_dict[key];
        return obj;
      }, {});

    setAvailableTags((prevState) => ({
      ...prevState,
      llm: {
        ...prevState.llm,
        tagger_params: {
          ...prevState.llm.tagger_params,
          tag_dict: cleanedTagDict,
        },
      },
    }));
  };

  const fetchInitialTaggerList = async (catalog_name = null) => {
    const rawResponse = await sendRequest(
      {
        [preferences.system.API_USERNAME_KEYWORD]: (
          await Auth.currentAuthenticatedUser()
        ).username,
        catalog_name: catalog_name || usedCatalog,
      },
      ENDPOINTS["get_tags"],
    );
    const response = await rawResponse.json();
    if (Object.keys(response.tags).length > 0) {
      let updatedTaggers = updateModelVersion(
        response.tags,
        preferences.webapp_profile.MODEL_USED,
        preferences.webapp_profile.PROVIDER_USED,
      );
      setAvailableTags(() => {
        return updatedTaggers;
      });
    } else {
      setAvailableTags(() => {
        return preferences.system.TAGGER_LIST;
      });
    }
  };

  const toggleCategoryVisibility = (e, category) => {
    e.stopPropagation();
    if (hiddenCategories.includes(category)) {
      setHiddenCategories(hiddenCategories.filter((c) => c !== category));
      toast.success({
        title: "Success",
        description: `Category ${category} successfully unhidden`,
      });
    } else {
      setHiddenCategories([...hiddenCategories, category]);
      toast.success({
        title: "Success",
        description: `Category ${category} successfully hidden`,
      });
    }
  };

  const handleCatalogChange = async (event) => {
    const value = event.target.value;

    if (value === "newCatalog") {
      setCatalogGetsCreated(true);
    } else {
      setCatalogGetsRenamed(true);

      toast.info({
        title: "Info",
        description: "Loading " + value,
      });
      setUsedCatalog(value);
      await fetchInitialCatalog(value);
      await fetchInitialTaggerList(value);
      toast.success({
        title: "Success",
        description: `
        Successfully loaded datasets from ${value}`,
      });
      setCatalogGetsRenamed(false);
    }
  };

  const refreshCurrentCatalog = async () => {
    toast.info({
      title: "Info",
      description: "Refreshing catalog: " + usedCatalog,
    });
  
    try {
      await fetchInitialCatalog(usedCatalog);
      // await fetchInitialTaggerList(usedCatalog);
      
      toast.success({
        title: "Success",
        description: `Successfully refreshed datasets from ${usedCatalog}`,
      });
    } catch (error) {
      console.error("Error refreshing catalog:", error);
      toast.error({
        title: "Error",
        description: `Failed to refresh catalog: ${usedCatalog}`,
      });
    }
  };

  const handleCatalogRename = async (oldName, newName) => {
    try {
      const response = await renameCatalog(newName, oldName);
      if (!response.success) {
        throw new Error("Failed to rename catalog");
      }
      setUsedCatalog(newName);
      await fetchInitialCatalog(newName);
      await fetchInitialTaggerList(newName);
      toast.success({
        title: "Success",
        description: `Successfully renamed to ${newName}`,
      });
      return { success: true };
    } catch (err) {
      setUsedCatalog(oldName);
      return { success: false };
    }
  };

  if (isLoading) {
    return <div>Loading preferences...</div>;
  }

  const handleEvidenceButtonClick = (selectedFiles) => {
    const allEvidenceData = [];

    Object.keys(currentDataGroup).forEach((itemKey) => {
      const item = currentDataGroup[itemKey];
      const groupedEvidence = {};
      const fileName = itemKey;

      if (item.hasOwnProperty("chunks")) {
        Object.entries(item.chunks).forEach(([chunkKey, chunkValue]) => {
          Object.entries(chunkValue).forEach(([tag, details]) => {
            if (!groupedEvidence[tag]) {
              groupedEvidence[tag] = [];
            }
            if (
              (!selectedFiles || selectedFiles.includes(itemKey)) &&
              details?.evidence
            ) {
              groupedEvidence[tag].push({
                chunk: chunkKey,
                evidence: details.evidence,
                values: details.value,
                isValid: details.isValid ?? true,
                fileName: fileName,
                pageRange: chunkValue.page_range || [],
              });
            }
          });
        });
      }

      const evidenceList = Object.entries(groupedEvidence)
        .map(([tag, evidences]) => ({
          tag,
          evidences,
          ...(item.hasOwnProperty("total_size") && {
            total_size: item.total_size,
          }),
          ...(item.hasOwnProperty("tipm") && {
            tipm: item.tipm,
          }),
          ...(item.hasOwnProperty("page_count") && {
            page_count: item.page_count,
          }),
        }))
        .filter(({ evidences }) => evidences.length > 0);
      allEvidenceData.push(...evidenceList);
    });

    setEvidenceData(allEvidenceData);
    setIsEvidenceModalOpen(true);
  };

  const clearLabels = async (e, labels) => {
    e.stopPropagation();

    const tagName = labels.join(", ");
    const confirmClear = window.confirm(
      `Are you sure you want to clear the labels \`${tagName}\`?`,
    );

    if (!confirmClear) {
      return;
    }

    toast.info({
      title: "Info",
      description: `Preparing labels ${tagName} to be cleared`,
    });

    const rawResponse = await sendRequest(
      {
        catalog: JSON.stringify(catalogFiles),
        [preferences.system.API_USERNAME_KEYWORD]: (
          await Auth.currentAuthenticatedUser()
        ).username,
        labels: labels,
        catalog_name: usedCatalog,
      },
      ENDPOINTS["clear_label"],
    );
    const response = await rawResponse.json();

    toast.success({
      title: "Success",
      description: `Tag ${tagName} successfully cleared`,
    });
    await getCatalogAndUpdateCatalog(usedCatalog, true, true);

    return response;
  };

  return (
    <DataContext.Provider
      value={{
        // Getters
        isLoading,
        showScreen,
        usedCatalog,
        catalogSummary,
        dataGroups,
        catalogFiles,
        currentDataGroup,
        searchTerm,
        detectedDataGroup,
        useCases,
        usecaseSelected,
        isModalOpen,
        fileUploadProgress,
        availableTags,
        availableRules,
        searchDetails,
        quarantinedFiles,
        processingFile,
        currentTag,
        currentProcessCount,
        currentTotalProcessCount,
        dateRange,
        hiddenCategories,
        selectedFilters,
        preferences,
        startPoint,
        tagsToBeDeleted,
        failedTags,
        ruleDict,
        showFilePreview,
        view,
        showConnectData,
        isEvidenceModalOpen,
        evidenceData,
        catalogGetsRenamed,
        catalogGetsCreated,
        showFileOnPage,
        isGloballyExpanded,
        validatedScores,
        validatingTag,
        defaultCurrentTag,
        abortedTasks,
        autoCreateProgress,
        isAutoCreating,
        uploadFileTask,
        uploadFileTaskProgress,
        taggingRunning,
        isDocumentEvidenceModalOpen,
        catalogTablePage,
        isTagDefinitionsExpanded,
        uploadDataProgress,
        userProfile,
        documentPreviewDatastoreName,
        isTagSelected,

        // Setters
        setIsTagSelected,
        setDocumentPreviewDatastoreName,
        setUploadDataProgress,
        setIsTagDefinitionsExpanded,
        setCatalogTablePage,
        setTaggingRunning,
        setValidatingTag,
        setIsAutoCreating,
        setValidatedScores,
        setAutoCreateProgress,
        setIsGloballyExpanded,
        setCatalogGetsCreated,
        setShowFileOnPage,
        setCatalogGetsRenamed,
        setShowFilePreview,
        setIsEvidenceModalOpen,
        setIsDocumentEvidenceModalOpen,
        setShowConnectData,
        setView,
        setHiddenCategories,
        setStartPoint,
        setIsLoading,
        setShowScreen,
        setUsedCatalog,
        setCatalogSummary,
        setDataGroups,
        setCatalogFiles,
        setCurrentDataGroup,
        setSearchTerm,
        setDetectedGroup,
        setUseCases,
        setDateRange,
        setUsecaseSelected,
        setModalOpen,
        setFileUploadProgress,
        setAvailableTags,
        setAvailableRules,
        setSearchDetails,
        setQuarantinedFiles,
        setProcessingFile,
        setCurrentTag,
        setCurrentProcessCount,
        setCurrentTotalProcessCount,
        setSelectedFilters,
        setPreferences,
        setRuleDict,
        setEvidenceData,
        // Functions
        handleLabelChange,
        deleteAllLabels,
        handleDatasetDelete,
        updateTagDict,
        getCatalogAndUpdateCatalog,
        fetchInitialCatalog,
        fetchInitialTaggerList,
        toggleCategoryVisibility,
        handleMultipleDelete,
        deleteMultipleQuarantine,
        clearAllFilters,
        handleCatalogChange,
        refreshCurrentCatalog,
        handleCatalogRename,
        handleEvidenceButtonClick,
        tagReRun,
        hasTagFailedFor,
        setAbortedTasks,
        clearLabels,
        setUploadFileTask,
        setUploadFileTaskProgress,
        deleteMultipleDatasets,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useDataContext = () => useContext(DataContext);
