import { TagSettings } from "./TagSettings";
import { CatalogComponent } from "./CatalogComponent";
import { UserTokenUsageComponent } from "./UserTokenUsageComponent";
import { TeamTokenUsageComponent } from "./TeamTokenUsageComponent";
import { TeamSettingsComponent } from "./TeamSettingsComponent";
import { HiddenTags } from "./HiddenTags";
import { DataStoreTable } from "./DataStoreTable";

export const TabContent = ({ activeTab, currentFormValues, setCurrentFormValues }) => {
  switch (activeTab) {
    case "hidden_tags":
      return (
        <HiddenTags 
          currentFormValues={currentFormValues}
          setCurrentFormValues={setCurrentFormValues}
        />
      );
    case "profile":
      return (
        <TagSettings
          activeTab={activeTab}
          profileData={currentFormValues?.profile}
          onDataChange={(newData) => setCurrentFormValues(prevFormValues => ({
            ...prevFormValues,
            profile: {
              ...prevFormValues.profile,
              ...newData
            }
          }))}
        />
      );
    case "webapp_profile":
      return (
        <DataStoreTable
          dataSources={currentFormValues?.webapp_profile?.DATA_STORES}
          onDataChange={(newData) => setCurrentFormValues(prevFormValues => ({
            ...prevFormValues,
            webapp_profile: {
              ...prevFormValues.webapp_profile,
              DATA_STORES: newData
            }
          }))}
        />
      );
    case "catalogs":
      return <CatalogComponent />;
    case "token_usage":
      return <UserTokenUsageComponent />;
    case "team_usage":
      return <TeamTokenUsageComponent />;
    case "team_settings":
      return (
        <TeamSettingsComponent
          currentFormValues={currentFormValues}
          setCurrentFormValues={setCurrentFormValues}
        />
      );
    default:
      return <div>Select a tab</div>;
  }
};
