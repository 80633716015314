import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAmazon, faMicrosoft } from "@fortawesome/free-brands-svg-icons";
import { faFile, faTimes } from "@fortawesome/free-solid-svg-icons";
import FolderList from "../../../../../../../utilities/NavigationBar/FolderList";
import { useState, useContext } from "react";
import { DataContext } from "../../../../../../../../context/DataContext";

export default function SelectData({
  folders,
  checkedItems,
  setCheckedItems,
  filteredFolderKeys,
  checkedFolders,
  setCheckedFolders,
  isFolderListModalOpen,
  integration,
  setShowFilePreview,
  handleIntegrationClick,
  calledFrom = "",
}) {
  const [searchText, setSearchText] = useState("");
  const [isExternalOptionsOpen, setIsExternalOptionsOpen] = useState(false);
  const { preferences } = useContext(DataContext);
  const handleRemoveFile = (filename) => {
    setCheckedItems((prev) => {
      const newCheckedItems = { ...prev };
      delete newCheckedItems[filename];
      return newCheckedItems;
    });
  };

  return (
    <div className="w-3/5 bg-white p-10 rounded-lg shadow-md" style={{ height: '72vh' }}>
      <h2 className="text-2xl font-bold mb-5">Select Data</h2>
      {isFolderListModalOpen ? (
        <div className="w-full h-full flex justify-center items-center overflow-y-auto">
          <FolderList
            folders={folders}
            checkedItems={checkedItems}
            searchText={searchText}
            setCheckedItems={setCheckedItems}
            integration={integration}
            setShowFilePreview={setShowFilePreview}
            filteredFolderKeys={filteredFolderKeys}
            setSearchText={setSearchText}
            mode="scheduling"
            checkedFolders={checkedFolders}
            setCheckedFolders={setCheckedFolders}
          />
        </div>
      ) : (
        <>
          {Object.keys(checkedItems).length !== 0 ? (
            <div className="h-full flex flex-col">
              <div className="mb-5">
                <label className="text-gray-700">
                  Files selected from current catalog:
                </label>
              </div>
              <div className="grow flex flex-col items-start hide-scrollbar overflow-y-auto mb-5">
                {Object.keys(checkedItems).map(
                  (filename) =>
                    checkedItems[filename] && (
                      <div
                        key={filename}
                        className="bg-white border border-gray-200 rounded-lg shadow-sm px-4 py-3 mb-3 flex items-center hover:bg-gray-50 transition-colors duration-150 w-full"
                      >
                        <FontAwesomeIcon
                          icon={faFile}
                          className="text-blue-500 mr-3 flex-shrink-0"
                        />
                        <div className="flex-grow min-w-0 overflow-hidden">
                          <p className="text-sm font-medium text-gray-900 truncate">
                            {filename}
                          </p>
                        </div>
                        <button
                          onClick={() => handleRemoveFile(filename)}
                          className="ml-2 p-1 rounded-full text-gray-400 hover:text-red-500 hover:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 transition-colors duration-150 flex-shrink-0"
                        >
                          <FontAwesomeIcon icon={faTimes} className="w-4 h-4" />
                        </button>
                      </div>
                    ),
                )}
              </div>
            </div>
          ) : (
            <p className="text-gray-600 text-sm">
              You haven't selected any files
            </p>
          )}
          {/* {calledFrom !== "catalog" && ( */}
            <div className="mt-4">
              <button
                className="border-primary border-2 text-primary hover:opacity-80 hover:bg-primary hover:text-white px-3 py-2 rounded-md transition duration-300 text-sm"
                onClick={() => setIsExternalOptionsOpen(true)}
              >
                Connect to external data source
              </button>
            </div>
          {/* )} */}
          {isExternalOptionsOpen && (
            <div className="mt-3 space-y-1.5">
              {Object.entries(preferences.webapp_profile.DATA_STORES).map(
                ([key, value]) => (
                  <div
                    key={key}
                    className="flex items-center p-2 bg-gray-100 rounded-md cursor-pointer hover:bg-gray-200 transition duration-300 text-sm pt-3"
                    onClick={() => handleIntegrationClick(key, "s3List")}
                  >
                    <FontAwesomeIcon
                      icon={
                        value["storage"]["type"] === "s3"
                          ? faAmazon
                          : value["storage"]["type"] === "sharepoint"
                            ? faMicrosoft
                            : faFile
                      }
                      className={`mr-1.5 ${
                        value["storage"]["type"] === "s3"
                          ? "text-yellow-500"
                          : "text-blue-500"
                      }`}
                    />
                    <span>{`Connect to ${key}`}</span>
                  </div>
                ),
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}
