import { signInWithPopup, signInWithEmailAndPassword, GoogleAuthProvider, createUserWithEmailAndPassword } from "firebase/auth";
import { firebaseAuth as auth } from "./GCPAuthProvider";
import { useEffect, useState } from "react";
import Logo from "../assets/images/Logo.png"
import GoogleG from "../assets/images/GoogleG.png"

function FirebaseProviderComponent({ children }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isSignUp, setIsSignUp] = useState(false);
    const [username, setUsername] = useState("");
    const [failedLogin, setFailedLogin] = useState(false);
    const [weakPassword, setWeakPassword] = useState(false);
    const [emailExists, setEmailExists] = useState(false);

    const googleProvider = new GoogleAuthProvider();
    googleProvider.setCustomParameters({   
        prompt : "select_account "
    });

    const loginWithGoogle = async (e) => {
        e.preventDefault();
        const response = await signInWithPopup(auth, googleProvider);
        setUsername(response.user.uid);
    };
    const signUpWithGoogle = async () => {
        try {
            const provider = new GoogleAuthProvider();
            const result = await signInWithPopup(auth, provider)

            const credential = GoogleAuthProvider.credentialFromResult(result);
            if (!credential){
                console.error("Error in user Credential")
                return
            }
            const token = credential.accessToken;
            setUsername(result.user);
        } catch (error) {
            console.log("errorCode:", error.code, "errorMessage:", error.message);
        }
    };

    const loginWithEmail = async (e) => {
        e.preventDefault()
        if (!email || !password) {
            console.error("Provide Email and Password");
            return;
        }
        try {
            const userCredential = await signInWithEmailAndPassword(
                auth,
                email,
                password
            );
            setUsername(userCredential.user);
        } catch (error) {
            setFailedLogin(true);
        }
    };
    const signupWithEmail = async (e) => {
        e.preventDefault()
        if (!email || !password) {
            console.error("Provide Email and Password");
            return;
        }
        try {
            const userCredential = await createUserWithEmailAndPassword(
                auth,
                email,
                password
            );
    
            const user = userCredential.user;
        } catch (error) {
            if (error.code === "auth/weak-password") {
                setWeakPassword(true);
            } else if (error.code === "auth/email-already-in-use" || error.code === "auth/invalid-email") {
                setEmailExists(true);
            } else {
                console.log("Error in signing up");
            }
        }
    };

    const signOut = () => {
        signOut(auth).then(() => {
            setEmail("");
            setPassword("");
            setIsSignUp(false);
            setUsername("");
        }).catch((error) => {
            console.log("Error in signing out");
        });
    };

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            if (user) {
                setUsername(user.uid);
            } else {
                setUsername("");
                setEmail("");
                setPassword("");
                setFailedLogin(false);
                setIsSignUp(false);
            }
        });
        return unsubscribe;
    }, []);


    if (username) {
        return (
            <>
                {children({ signOut, username})}
            </>
        )
    }

    return (
        <div className="login-container flex justify-center items-center min-h-screen">
            <div className="login-card w-full max-w-lg p-6 bg-white rounded-lg shadow-lg">
                <div className="flex flex-col justify-center">
                    <div className="flex items-center justify-center pb-4">
                        <img src={Logo} className="h-[80px] logo rounded" alt="LogoImageNavBar" />
                    </div>
                    <div className="flex flex-col items-center justify-center space-y-4">
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => {
                                setFailedLogin(false);
                                setEmail(e.target.value);
                                setEmailExists(false);
                            }}
                            className={`w-full p-3 border ${failedLogin || emailExists ? 'border-red-500' : 'border-gray-300'} rounded`}
                        />
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => {
                                setFailedLogin(false);
                                setPassword(e.target.value);
                                setWeakPassword(false);
                            }}
                            className={`w-full p-3 border ${failedLogin || weakPassword ? 'border-red-500' : 'border-gray-300'} rounded`}
                        />
                        {!isSignUp ? (
                            <>
                                <div onClick={loginWithEmail} className="bg-primary text-white p-3 rounded w-full text-center cursor-pointer">
                                    Sign In
                                </div>
                                <div
                                    className="flex items-center w-full bg-white text-primary p-3 rounded cursor-pointer"
                                    onClick={loginWithGoogle}
                                >
                                    <img src={GoogleG} className="h-[40px] w-[40px] cursor-pointer mr-2" alt="GoogleLogo" />
                                    <div className="text-primary p-2 ps-0 rounded w-full text-center">
                                        Sign In with Google
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div onClick={signupWithEmail} className="bg-primary text-white p-3 rounded w-full text-center cursor-pointer">
                                    Sign Up
                                </div>
                                <div
                                    onClick={signUpWithGoogle}
                                    className="flex items-center w-full bg-white text-primary p-3 rounded cursor-pointer"
                                >
                                    <img src={GoogleG} className="h-[40px] w-[40px] cursor-pointer mr-2" alt="GoogleLogo" />
                                    <div className="text-primary p-2 ps-0 rounded w-full text-center">
                                        Sign Up with Google
                                    </div>
                                </div>
                            </>
                        )}
                        <div className="mt-4 text-center text-sm text-gray-600">
                            {isSignUp ? (
                                <>
                                    Already have an account?{' '}
                                    <span onClick={() => setIsSignUp(false)} className="text-primary cursor-pointer">
                                        Sign In
                                    </span>
                                </>
                            ) : (
                                <>
                                    Don't have an account?{' '}
                                    <span onClick={() => setIsSignUp(true)} className="text-primary cursor-pointer">
                                        Sign Up
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default FirebaseProviderComponent;
