import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { CustomTooltip } from "./CustomTooltip";
import { GraphProps } from "./types";
import { colors } from "../../../../../../../twExtend";
export const TokenUsageGraph: React.FC<GraphProps> = ({ data }) => {
  if (!data || data.length === 0) {
    return (
      <div className="text-gray-500 text-sm text-center py-10">
        No data available for the selected date range.
      </div>
    );
  }
  return (
    <ResponsiveContainer width="100%" height={400}>
      <BarChart
        data={data}
        margin={{ top: 10, right: 20, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="2 3" />
        <XAxis dataKey="endpoint" tick={{ fontSize: 12 }}/>
        <YAxis yAxisId="left" orientation="left" stroke="#8884d8" tick={{ fontSize: 12 }}/>
        <YAxis yAxisId="right" orientation="right" stroke="#82ca9d" tick={{ fontSize: 12 }}/>
        <Tooltip content={<CustomTooltip />} wrapperStyle={{ fontSize: 12 }}/>
        <Legend wrapperStyle={{ fontSize: 12 }}/>
        <Bar
          yAxisId="left"
          dataKey="tokensUsed"
          fill="#f74a4a"
          name="Tokens Used"
          stackId="a"
        />
        <Bar
          yAxisId="left"
          dataKey="tokensLeft"
          fill={colors.primary}
          name="Available Tokens"
          stackId="a"
        />
        <Bar
          yAxisId="left"
          dataKey="promptTokens"
          fill={colors.secondary}
          name="Prompt Tokens"
          stackId="a"
        />
        <Bar
          yAxisId="left"
          dataKey="completionTokens"
          fill={colors.light}
          name="Completion Tokens"
          stackId="a"
        />
      </BarChart>
    </ResponsiveContainer>
  );
};
