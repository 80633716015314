import { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import InfoIcon from "../../../../../utilities/InfoIcon/InfoIcon";
import ValidationScoreCard from "./ValidationScoreCard";
import { motion } from "framer-motion";
import { PermissionGuard } from "../../../../../utilities/PermissionGuard";
import { DataContext } from "../../../../../../context/DataContext";

const GeneralSection = ({
  currentTag,
  setCurrentTag,
  handleChangeOption,
  handleAddValue,
  handleDeleteValue,
  currentValue,
  setCurrentValue,
  handleRiskLevelChange,
  activeTab,
  validatingTag,
  validatedScores,
  allGroups,
  generateTagDescription,
  options,
}) => {
  const [isEditingGroup, setIsEditingGroup] = useState(false);
  const [tempGroupName, setTempGroupName] = useState("");
  const { userProfile } = useContext(DataContext);

  const sensitivityTagType = "Sensitivity";
  const HeaderStyle =
    "gap-2 flex text-grey font-bold rounded-md pr-2 pt-2 pb-2 whitespace-nowrap";

  const handleGroupChange = (e) => {
    const value = e.target.value;
    setTempGroupName(value);
  };

  const handleNewGroupSubmit = () => {
    if (tempGroupName.trim()) {
      setCurrentTag((prevTag) => ({
        ...prevTag,
        group: tempGroupName.trim(),
      }));
      setIsEditingGroup(false);
    }
  };

  const startEditingGroup = () => {
    setTempGroupName(currentTag.group || "");
    setIsEditingGroup(true);
  };

  const cancelEditingGroup = () => {
    setTempGroupName(currentTag.group || "");
    setIsEditingGroup(false);
  };

  const renderGroupSection = () => (
    <div className="flex flex-col">
      <div className="flex items-center justify-start">
        <p className="gap-2 flex text-grey font-bold rounded-md pr-2 pt-2 pb-2 whitespace-nowrap">
          Tag Group
          <InfoIcon infoText="Tag group this tag belongs to" />
        </p>
      </div>
      {isEditingGroup ? (
        <div className="flex flex-col mt-2">
          <p className="text-sm text-gray-600 mb-2">
            Select an existing group or enter a new group name:
          </p>
          <div className="flex items-center">
            <select
              value={tempGroupName}
              onChange={handleGroupChange}
              className="px-4 py-2 border rounded-md mr-2"
            >
              <option value="">Select or create a group</option>
              {allGroups.map((group) => (
                <option key={group} value={group}>
                  {group}
                </option>
              ))}
            </select>
            <input
              type="text"
              value={tempGroupName}
              onChange={(e) => setTempGroupName(e.target.value)}
              placeholder="Or enter new group name"
              className="px-4 py-2 border rounded-md mr-2"
            />
            <div className="flex flex-row gap-1">
              <button
                onClick={handleNewGroupSubmit}
                className="bg-primary text-white px-2 py-2 rounded-md"
                title="Confirm"
              >
                <p>Confirm</p>
              </button>
              <button
                onClick={cancelEditingGroup}
                className="bg-grey text-white px-2 py-2 rounded-md"
                title="Cancel"
              >
                <p>Cancel</p>
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-row gap-2 items-center">
          <p className="text-sm text-gray-600">
            Current group:{" "}
            <span className="font-semibold text-sm">
              {currentTag.group || "Ungrouped"}
            </span>
          </p>
          {!isEditingGroup && (
            <button
              onClick={startEditingGroup}
              className="text-primary hover:text-primary-dark"
            >
              <FontAwesomeIcon icon={faPencilAlt} /> Edit
            </button>
          )}
        </div>
      )}
    </div>
  );

  const changeOutputType = (newType) => {
    setCurrentTag((prevTag) => ({
      ...prevTag,
      type: newType,
    }));
  };

  const changeDateFormat = (newFormat) => {
    setCurrentTag((prevTag) => ({
      ...prevTag,
      date_format: newFormat,
    }));
  };

  const isGlobalTag =
    (currentTag.username !== userProfile.username &&
      currentTag.catalog_name === "Global") ||
    currentTag?.is_global === true;
  const isUserLevelTag =
    (currentTag.username === userProfile.username &&
      currentTag.catalog_name === "Global") ||
    currentTag?.is_user_level === true;

  return (
    <div
      className={`flex flex-col p-4 gap-2.5 pb-[35vh] h-[calc(100vh+5vh)] overflow-y-auto hide-scrollbar 
        ${activeTab === 1 && !options.find((option) => option.value === currentTag.name) && "pointer-events-none opacity-50"}`}
    >
      {renderGroupSection()}
      {/* Tag name section */}
      <div className="flex flex-col">
        <p className={HeaderStyle}>Tag name</p>
        <input
          type="text"
          placeholder="Enter tag name"
          className={`px-4 py-2 text-sm outline-none border-[1.5px] border-buttonGrey rounded-md ${activeTab === 1 && "opacity-50"}`}
          value={currentTag.name}
          onChange={(e) =>
            setCurrentTag((prevTag) => ({
              ...prevTag,
              name: e.target.value.replace(/ /g, "_").replace(/,/g, "_"),
            }))
          }
          disabled={activeTab === 1}
          title={
            activeTab === 1 &&
            "Changing the tag name will create a new tag, go to the 'Manually define tag' tab"
          }
        />
      </div>
      <div className="flex flex-col w-full">
        <div className="flex justify-center w-full flex-col">
          <div className="flex flex-row items-center">
            <p className="gap-2 flex text-grey font-bold rounded-md pr-2 pt-2 pb-2 whitespace-nowrap">
              Tag description
              <div className="whitespace-normal">
                <InfoIcon
                  className="ml-1"
                  infoText="Describe your tag in plain English, for example: A 1 - 2 sentence summary of the data. Does the document contain an auto-renewal clause?"
                />
              </div>
            </p>
            <button
              className="border-primary border-[1.5px] p-1 text-primary font-bold rounded-md hover:bg-primary hover:text-white cursor-pointer"
              onClick={generateTagDescription}
            >
              Refine description
            </button>
          </div>
          <p className="text-xs text-gray-600 mb-2">
            Provide a clear description of your tag to help Deasie understand
            its purpose and use it accurately.
          </p>
        </div>
        <textarea
          placeholder="Enter description"
          rows="4"
          className="flex px-4 py-2 text-sm rounded-md border-[1.5px] border-buttonGrey hide-scrollbar"
          value={currentTag.description}
          onChange={(e) =>
            setCurrentTag((prevTag) => ({
              ...prevTag,
              description: e.target.value,
            }))
          }
        ></textarea>
      </div>
      {/* Output constraint section */}
      <div
        className="w-full flex flex-col"
        style={{
          display:
            currentTag.tagType && currentTag.tagType === sensitivityTagType
              ? "none"
              : "flex",
        }}
      >
        <p className={HeaderStyle}>
          Output constraint
          <div className="whitespace-normal">
            <InfoIcon infoText="Tags can be open-ended (i.e., the model will generate the output values), or you can specific target values to map to (e.g., Yes/No, or a list of specific values) " />
          </div>
        </p>
        <div className="flex">
          <select
            value={currentTag.option}
            onChange={(e) => handleChangeOption(e.target.value)}
            className="px-4 py-2 text-sm rounded-md flex border-2 border-buttonGrey"
          >
            <option value="aiGenerated" disabled hidden>
              I don't have possible values
            </option>
            <option value="aiGenerated">I don't have possible values</option>
            <option value="yesNo">Yes/No</option>
            <option value="custom">Custom</option>
          </select>
        </div>
        {currentTag.option === "custom" && (
          <>
            <div className="flex flex-col gap-1 mt-2 py-3">
              <div className="text-xs text-zinc-400">
                Note: Enter a possible value and hit Enter to save it
              </div>
              <div className="flex gap-1">
                <input
                  type="text"
                  placeholder=""
                  className="px-4 py-4 rounded-md border outline-none min-w-[30vw]"
                  value={currentValue}
                  onChange={(e) => setCurrentValue(e.target.value)}
                  onKeyDown={handleAddValue}
                />
              </div>
            </div>
            {currentTag.option &&
              currentTag.availableValues &&
              currentTag.availableValues.length > 0 && (
                <div className="flex flex-wrap gap-2 pt-2 max-h-40 overflow-y-auto">
                  {currentTag.availableValues.map((value, index) => (
                    <div
                      key={index}
                      className="px-2 py-1 bg-primary rounded-md text-white flex items-center"
                    >
                      {value}
                      <span
                        className="font-black cursor-pointer ml-2"
                        onClick={() => handleDeleteValue(value)}
                      >
                        &times;
                      </span>
                    </div>
                  ))}
                </div>
              )}
            <div className="flex flex-col gap-1 mt-2 py-3">
              <div className="text-xs text-zinc-400">
                Note: Enter the maximum number of values allowed
              </div>
              <div className="flex gap-1">
                <input
                  type="number"
                  placeholder=""
                  className="px-4 py-4 rounded-md border outline-none min-w-[30vw]"
                  value={currentTag.max_number_of_values}
                  onChange={(e) =>
                    setCurrentTag((prevTag) => ({
                      ...prevTag,
                      max_number_of_values: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </>
        )}
      </div>
      {/* Risk Level section */}
      {currentTag.tagType === sensitivityTagType && (
        <div className="flex flex-col">
          <p className="font-semibold text-base">3. Risk Level</p>
          <div>
            <select
              value={currentTag.risk_level}
              onChange={(e) => handleRiskLevelChange(e.target.value)}
              className="flex border px-4 py-2 rounded"
            >
              <option value="High">High</option>
              <option value="Medium">Medium</option>
              <option value="Low">Low</option>
            </select>
          </div>
          <div className="flex flex-col gap-2 py-2">
            <p className="font-semibold text-sm">Define Low Risk</p>
            <textarea
              placeholder="Input Low Risk (Optional)"
              className="flex px-4 py-2 text-xs rounded-md border w-full"
              value={currentTag.risk_level_description?.low || ""}
              onChange={(e) =>
                setCurrentTag((prevTag) => {
                  prevTag.risk_level_description =
                    prevTag.risk_level_description || {};
                  prevTag.risk_level_description.low = e.target.value;
                  return {
                    ...prevTag,
                  };
                })
              }
            ></textarea>
            <p className="font-semibold text-sm">Define Medium Risk</p>
            <textarea
              placeholder="Input Medium Risk (Optional)"
              className="flex px-4 py-2 text-xs rounded-md border w-full"
              value={currentTag.risk_level_description?.medium || ""}
              onChange={(e) =>
                setCurrentTag((prevTag) => {
                  prevTag.risk_level_description =
                    prevTag.risk_level_description || {};
                  prevTag.risk_level_description.medium = e.target.value;
                  return {
                    ...prevTag,
                  };
                })
              }
            ></textarea>
            <p className="font-semibold text-sm">Define High Risk</p>
            <textarea
              placeholder="Input High Risk (Optional)"
              className="flex px-4 py-2 text-xs rounded-md border w-full"
              value={currentTag.risk_level_description?.high || ""}
              onChange={(e) =>
                setCurrentTag((prevTag) => {
                  prevTag.risk_level_description =
                    prevTag.risk_level_description || {};
                  prevTag.risk_level_description.high = e.target.value;
                  return {
                    ...prevTag,
                  };
                })
              }
            ></textarea>
          </div>
        </div>
      )}
      {/* Output type section */}
      <div
        className="flex flex-col"
        style={{
          display:
            currentTag.tagType && currentTag.tagType === sensitivityTagType
              ? "none"
              : "flex",
        }}
      >
        <PermissionGuard scope="teams" level="canEdit">
          <p className={HeaderStyle}>
            Global tag
            <div className="whitespace-normal">
              <InfoIcon infoText="This is a global tag, it will be applied users in your team." />
            </div>
          </p>
          <div
            className={`shadow-md w-12 h-6 rounded-full transition-transform duration-300 ease-in-out ${isGlobalTag ? "bg-primary" : "bg-gray-300"} flex items-center`}
            onClick={() => {
              if (!currentTag.tag_uuid) {
                setCurrentTag((prevTag) => ({
                  ...prevTag,
                  is_global: !prevTag.is_global,
                }));
              }
            }}
          >
            <div
              className={`w-5 h-5 bg-white rounded-full transform ${
                isGlobalTag ? "translate-x-6" : "translate-x-1"
              } transition-transform duration-300 ease-in-out`}
            />
          </div>
          {currentTag.tag_uuid && (
            <p className="text-xs text-gray-500 mt-1">
              This tag has already been created and cannot be changed to/from a
              global tag.
            </p>
          )}
        </PermissionGuard>
        <PermissionGuard scope="tags" level="canEdit">
          <p className={HeaderStyle}>
            User level tag
            <div className="whitespace-normal">
              <InfoIcon infoText="This is a user level tag, it will be included in all of your catalogs." />
            </div>
          </p>
          <div
            className={`shadow-md w-12 h-6 rounded-full transition-transform duration-300 ease-in-out ${isUserLevelTag ? "bg-primary" : "bg-gray-300"} flex items-center ${currentTag.tag_uuid ? "pointer-events-none opacity-50" : "cursor-pointer"}`}
            onClick={() => {
              if (!currentTag.tag_uuid) {
                setCurrentTag((prevTag) => ({
                  ...prevTag,
                  is_user_level: !prevTag.is_user_level,
                }));
              }
            }}
          >
            <div
              className={`w-5 h-5 bg-white rounded-full transform ${
                isUserLevelTag ? "translate-x-6" : "translate-x-1"
              } transition-transform duration-300 ease-in-out`}
            />
          </div>
          {currentTag.tag_uuid && (
            <p className="text-xs text-gray-500 mt-1">
              This tag has already been created and cannot be changed to/from a
              user level tag.
            </p>
          )}
        </PermissionGuard>
        <p className={HeaderStyle}>
          Output type
          <div className="whitespace-normal">
            <InfoIcon infoText="Is this tag a number, a word, or a date?" />
          </div>
        </p>
        <div className="flex">
          <select
            value={currentTag.type}
            onChange={(e) => changeOutputType(e.target.value)}
            className="px-4 py-2 text-sm border rounded-md flex"
          >
            <option value="word">Word</option>
            <option value="number">Number</option>
            <option value="date">Date</option>
          </select>
        </div>
        {currentTag.type === "date" && (
          <div className="flex flex-col mt-2">
            <label htmlFor="date-format" className={HeaderStyle}>
              Date Format
            </label>
            <select
              id="date-format"
              value={currentTag.date_format}
              onChange={(e) => changeDateFormat(e.target.value)}
              className="px-4 py-2 border rounded-md flex"
            >
              <option value="MM/DD/YYYY">MM/DD/YYYY</option>
              <option value="DD/MM/YYYY">DD/MM/YYYY</option>
              <option value="YYYY/MM/DD">YYYY/MM/DD</option>
              <option value="MM-DD-YYYY">MM-DD-YYYY</option>
              <option value="DD-MM-YYYY">DD-MM-YYYY</option>
              <option value="YYYY-MM-DD">YYYY-MM-DD</option>
            </select>
          </div>
        )}
      </div>
      {/* Validation Score Card */}
      <div className="w-full flex h-full justify-start">
        <motion.div transition={{ duration: 0.3 }} className="self-center">
          <ValidationScoreCard
            tag={currentTag}
            evaluatingTag={validatingTag}
            evaluatedScores={validatedScores}
          />
        </motion.div>
      </div>
    </div>
  );
};

export default GeneralSection;
