import { colors } from "../../../twExtend";
import "./PieChart.css";
import { PieChart } from "react-minimal-pie-chart";
export default function ProgressPie({ percentage }) {
  const sanitizedPercentage =
    isNaN(percentage) || typeof percentage !== "number" ? 0 : percentage;
  const displayValue = sanitizedPercentage.toFixed(0); // Rounds the percentage

  return (
    <div title={`You tagged ${displayValue}% of datasets with this tag`}>
      <PieChart
        data={[
          {
            title: "Tagged",
            value: sanitizedPercentage,
            color: colors.primary,
          },
          {
            title: "Untagged",
            value: 100 - sanitizedPercentage,
            color: colors.containerLight,
          },
        ]}
        animate
        animationDuration={1000}
        animationEasing="ease-out"
        lineWidth={15}
        rounded
        paddingAngle={5}
        totalValue={100}
        startAngle={270}
        label={({ dataEntry }) =>
          dataEntry.value > 0 ? `${displayValue}%` : ""
        }
        labelStyle={{
          fontSize: "25px",
          fontWeight: "600",
          fill: colors.grey,
        }}
        labelPosition={0}
      />
    </div>
  );
}
