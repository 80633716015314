
const ExampleLimitModal = ({ onClose, exampleLimitType }) => {
    const exampleLabel = exampleLimitType === 'positive' ? 'positive examples' : 'negative examples';
    console.log('exampleLimitType', exampleLimitType);
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full mx-4">
          <h2 className="text-base font-semibold text-gray-800 mb-4">
            {exampleLimitType === 'positive' ? 'Positive Example Limit Exceeded' : 'Negative Example Limit Exceeded'}
          </h2>
          <p className="text-gray-600 mb-6">
            The maximum number of {exampleLabel} is 10. Please edit or delete current examples to proceed.
          </p>
          <button
            onClick={onClose}
            className="bg-primary text-white p-3 rounded-lg hover:bg-primary-dark transition-all duration-300 w-full"
          >
            OK
          </button>
        </div>
      </div>
    );
  };
  
  export default ExampleLimitModal;
