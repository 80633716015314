import { IAuthProvider, IUser } from "./IAuthProvider";
import { AWSAuthProvider } from "./AWSAuthProvider";
import { AzureAuthProvider } from "./AzureAuthProvider";
import { GCPAuthProvider } from "./GCPAuthProvider";

class Auth {
  private static provider: IAuthProvider<IUser>;;

  static initialize() {
    const authProvider = process.env.REACT_APP_AUTH_PROVIDER ?? "AWS";
    switch (authProvider) {
      case "AWS":
        Auth.provider = new AWSAuthProvider();
        break;
      case "Azure":
        Auth.provider = new AzureAuthProvider();
        break;
      case "GCP":
        Auth.provider = new GCPAuthProvider();
        break
      default:
        throw new Error("No valid authentication provider found.");
    }
  }

  static async currentAuthenticatedUser(): Promise<IUser> {
    const user = await Auth.provider.currentAuthenticatedUser();
    if ('localAccountId' in user) {
      user.username = user.localAccountId;
    }
    return user;
  }

  static async getIdToken(): Promise<string> {
    return Auth.provider.getIdToken();
  }

  static async getAccessToken(): Promise<string> {
    return Auth.provider.getAccessToken();
  }
}

Auth.initialize();

export default Auth;