// src/context/UserProfile.tsx
import { Backdrop, CircularProgress } from "@mui/material";
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { StorageInfo } from "../api/types";
import { getPreferences } from "./../components/utilities/functions/apiCalls";

export type PermissionFlags = {
  canView: boolean;
  canEdit: boolean;
};

export type Permissions = {
  tags: PermissionFlags;
  catalogs: PermissionFlags;
  rules: PermissionFlags;
  usecases: PermissionFlags;
  teams: PermissionFlags;
  feedback: PermissionFlags; // Ensure feedback permissions are included
};

export type UserProfile = {
  username: string;
  user_group: string;
  email: string;
  permissions: Permissions;
  catalog_team: string | null;
  team: string;
  profile: any;
  hidden_tags: Record<string, any>;
  webapp_profile: Record<string, any>;
  system: Record<string, any>;
  rate_limits: Record<string, number>;
  getStorageInfo: (storageType: string) => StorageInfo;
};

const UserProfileContext = createContext<UserProfile>({} as UserProfile);

UserProfileContext.displayName = "UserProfileContext";

export const UserProfileProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userProfile, setUserProfile] = useState<UserProfile>(
    {} as UserProfile,
  );

  const getStorageInfo = useCallback(
    (storageType: string): StorageInfo => {
      const storageEntry = userProfile.webapp_profile.DATA_STORES[storageType];
      return {
        kind: storageEntry.storage.type,
        name: storageEntry.storage.name,
        base_path: storageEntry.base_path,
        credentials: storageEntry.storage.credentials,
      };
    },
    [userProfile],
  );

  useEffect(() => {
    getPreferences().then((profile) => {
      setUserProfile({
        ...profile,
        permissions: {
          tags: {
            canView: profile.permissions.tags.can_view,
            canEdit: profile.permissions.tags.can_edit,
          },
          catalogs: {
            canView: profile.permissions.catalogs.can_view,
            canEdit: profile.permissions.catalogs.can_edit,
          },
          catalog_create_w_data: {
            canView: profile.permissions.catalog_create_w_data.can_view,
            canEdit: profile.permissions.catalog_create_w_data.can_edit,
          },
          usecases: {
            canView: profile.permissions.usecases.can_view,
            canEdit: profile.permissions.usecases.can_edit,
          },
          rules: {
            canView: profile.permissions.rules.can_view,
            canEdit: profile.permissions.rules.can_edit,
          },
          teams: {
            canView: profile.permissions.teams.can_view,
            canEdit: profile.permissions.teams.can_edit,
          },
          feedback: {
            canView: profile.permissions.feedback.can_view,
            canEdit: profile.permissions.feedback.can_edit,
          }, // Include feedback permissions
        },
      });
      setIsLoading(false);
    });
  }, []);

  if (isLoading) {
    return (
      <Backdrop open>
        <CircularProgress />
      </Backdrop>
    );
  }

  return (
    <UserProfileContext.Provider
      value={{
        ...userProfile,
        getStorageInfo,
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};

export const useUserProfile = () => useContext(UserProfileContext);
