import { useContext } from "react";
import { COLOURS } from "../../../../../assets/colours";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import Tags from "../DataCatalog/DataCatalogComponents/Tags/Tags";
import { DataContext } from "../../../../../context/DataContext";
import { TagContext } from "../../../../../context/TagContext";

export default function TagLibrarySidebar() {
  const { isTagLibraryCollapsed, setIsTagLibraryCollapsed } =
    useContext(TagContext);
  const { availableTags } = useContext(DataContext);
  const tagCount = availableTags
    ? Object.keys({
        ...availableTags.llm.tagger_params.tag_dict,
        ...availableTags.sensitivity.tagger_params.tag_dict,
      }).length
    : 0;
  return (
    <div
      className={`relative bg-[rgb(231, 237, 244)] bg-white rounded-md flex flex-col transition-all duration-300 text-sm`}
      style={{
        width: isTagLibraryCollapsed ? "5vw" : "25vw",
        opacity: isTagLibraryCollapsed ? 0.5 : 1,
        transition: "width 0.5s ease-in-out, opacity 0.3s ease-in-out",
      }}
    >
      {isTagLibraryCollapsed ? (
        <>
          <div
            className="flex flex-row w-[5vw] justify-between p-1 items-center "
            style={{ backgroundColor: COLOURS["HeaderBackground"] }}
          >
            <FontAwesomeIcon icon={faBook} className="text-grey mr-2 text-xl" />
            <button
              onClick={() => setIsTagLibraryCollapsed(false)}
              className="text-grey p-1 rounded-md"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-gray-500"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </button>
          </div>
          <div className="flex flex-col items-center justify-center">
            <span className="text-grey font-bold items-center justify-center flex flex-row mt-4 text-xl">
              {tagCount}
            </span>
            <span className="text-md">tags</span>
          </div>
        </>
      ) : (
        <div
          className="flex flex-col rounded-t-md overflow-auto text-lg"
          style={{ width: "100%" }}
        >
          <Tags title="Tag Library" tagTypes={["llm", "sensitivity"]} />
        </div>
      )}
    </div>
  );
}
