// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TagRuleFilterContainer {
    --primary-color: rgb(40 164 144 / 1);
    background-color: white;
    position: absolute;
    top: 50%;
    right: 50%;
    border-radius: 4px;
    transform: translate(50%, -50%);
    width: 50%;
    height: 500px;
}
.TagRuleFilterContainer .MuiTableContainer-root {
    height: 375px;
    overflow: auto;
}
.TagRuleFilterContainer > h4 {
    margin: auto;
    font-size: 20px;
    padding: 20px;
    background-color: #f5f5f5;
}
.TagRuleFilterFooter {
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.TagRuleFilterFooter button {
  text-transform: none;
  background-color: white;
  border: 1px solid var(--primary-color);
  font-weight: bold;
  padding: 5px 15px;
  color: var(--primary-color);
}
.TagRuleFilterFooter button[type="submit"] {
    background-color: var(--primary-color);
    color: white;
}
`, "",{"version":3,"sources":["webpack://./src/components/pages/Home/HomeComponents/DataCatalog/DataCatalogComponents/TagRuleFilter/TagRuleFilter.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,uBAAuB;IACvB,kBAAkB;IAClB,QAAQ;IACR,UAAU;IACV,kBAAkB;IAClB,+BAA+B;IAC/B,UAAU;IACV,aAAa;AACjB;AACA;IACI,aAAa;IACb,cAAc;AAClB;AACA;IACI,YAAY;IACZ,eAAe;IACf,aAAa;IACb,yBAAyB;AAC7B;AACA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;AACA;EACE,oBAAoB;EACpB,uBAAuB;EACvB,sCAAsC;EACtC,iBAAiB;EACjB,iBAAiB;EACjB,2BAA2B;AAC7B;AACA;IACI,sCAAsC;IACtC,YAAY;AAChB","sourcesContent":[".TagRuleFilterContainer {\n    --primary-color: rgb(40 164 144 / 1);\n    background-color: white;\n    position: absolute;\n    top: 50%;\n    right: 50%;\n    border-radius: 4px;\n    transform: translate(50%, -50%);\n    width: 50%;\n    height: 500px;\n}\n.TagRuleFilterContainer .MuiTableContainer-root {\n    height: 375px;\n    overflow: auto;\n}\n.TagRuleFilterContainer > h4 {\n    margin: auto;\n    font-size: 20px;\n    padding: 20px;\n    background-color: #f5f5f5;\n}\n.TagRuleFilterFooter {\n    padding: 10px;\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n}\n.TagRuleFilterFooter button {\n  text-transform: none;\n  background-color: white;\n  border: 1px solid var(--primary-color);\n  font-weight: bold;\n  padding: 5px 15px;\n  color: var(--primary-color);\n}\n.TagRuleFilterFooter button[type=\"submit\"] {\n    background-color: var(--primary-color);\n    color: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
