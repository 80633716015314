import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useAtom } from "jotai";
import { selectedTagKeysAtom } from "../../../../../../../../atoms";

export default function ConfirmSchedule({
  selectedFolderCount,
  selectedFileCount,
  renderFolderTree,
  selectedOption,
  setSelectedOption,
  singleCatalogName,
  handleCatalogNameChange,
  catalogNameError,
  isOnlyOneFolder,
  isAllUnfoldered,
  setTimeSelectionModalOpen,
  calledFrom = "",
}) {
  const [selectedTagKeys] = useAtom(selectedTagKeysAtom);
  return (
    <div className="grow flex justify-between w-full gap-3">
      {calledFrom === "catalog" && (
        <button
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
          onClick={() => setTimeSelectionModalOpen(false)}
        >
          <FontAwesomeIcon icon={faTimes} className="text-xl" />
        </button>
      )}
      <div className="w-full bg-white p-8 rounded-lg shadow-md flex flex-col"
        style={{ height: '72vh' }}
      >
        <h2 className="text-2xl font-bold mb-5">Confirm Selected Folders</h2>
        <p className="text-sm text-gray-600 mb-4">
          <FontAwesomeIcon icon={faFolderOpen} className="mr-2" />
          You've selected{" "}
          <span className="font-bold text-primary">
            {selectedFolderCount}
          </span>{" "}
          folders,{" "}
          <span className="font-bold text-primary">{selectedFileCount}</span>{" "}
          files in total, with{" "}
          <span className="font-bold text-primary">
            {selectedTagKeys.length}
          </span>{" "}
          tags.
        </p>
        <div className="flex flex-grow overflow-hidden">
          <div className="w-3/5 pr-4 overflow-y-auto">
            <div className="space-y-2 text-sm">{renderFolderTree()}</div>
          </div>
          <div className="w-2/5 bg-gray-100 p-4 rounded-lg overflow-y-auto">
            <div className="flex items-center mb-3">
              <h3 className="text-lg font-bold p-2">
                Choose Scheduling Option:
              </h3>
            </div>
            <div className="space-y-3">
              <div
                className={`flex items-center p-3 border border-gray-200 rounded-xl bg-white ${isOnlyOneFolder || isAllUnfoldered ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-50"} transition-colors`}
              >
                <input
                  type="radio"
                  id="createMultipleCatalogs"
                  checked={selectedOption === "createMultipleCatalogs"}
                  onChange={() => setSelectedOption("createMultipleCatalogs")}
                  className="mr-2 h-4 w-4 text-primary"
                  disabled={isOnlyOneFolder || isAllUnfoldered}
                />
                <label
                  htmlFor="createMultipleCatalogs"
                  className={`text-sm font-medium ${isOnlyOneFolder || isAllUnfoldered ? "cursor-not-allowed" : "cursor-pointer"} flex-grow`}
                >
                  <span className="block text-base mb-1">
                    Schedule in Multiple Catalogs
                  </span>
                  <span className="block text-xs text-gray-600">
                    Will be creating{" "}
                    <span className="font-bold text-primary">
                      {selectedFolderCount}
                    </span>{" "}
                    catalogs
                  </span>
                </label>
              </div>
              <div className="flex items-center p-3 border border-gray-200 rounded-xl bg-white hover:bg-gray-50 transition-colors">
                <input
                  type="radio"
                  id="scheduleAllFiles"
                  checked={selectedOption === "scheduleAllFiles"}
                  onChange={() => setSelectedOption("scheduleAllFiles")}
                  className="mr-2 h-4 w-4 text-primary"
                />
                <label
                  htmlFor="scheduleAllFiles"
                  className="text-sm font-medium cursor-pointer flex-grow"
                >
                  <span className="block text-base mb-1">
                    Schedule in One Catalog
                  </span>
                  <span className="block text-xs text-gray-600">
                    <span className="font-bold text-primary">
                      {selectedFileCount}
                    </span>{" "}
                    files will be scheduled in a single catalog
                  </span>
                </label>
              </div>
              {selectedOption === "scheduleAllFiles" && (
                <div className="mt-4">
                  <label
                    htmlFor="singleCatalogName"
                    className="block text-sm font-medium text-gray-700 mb-1"
                  >
                    Catalog Name
                  </label>
                  <div className="relative">
                    <input
                      type="text"
                      id="singleCatalogName"
                      value={singleCatalogName}
                      onChange={handleCatalogNameChange}
                      className={`text-sm w-full p-2 pl-4 border ${catalogNameError ? "border-red-500" : "border-gray-300"} rounded-md focus:ring-blue-500 focus:border-blue-500`}
                      placeholder="Enter a name for the catalog"
                    />
                  </div>
                  {catalogNameError && (
                    <p className="mt-1 text-sm text-red-600">
                      {catalogNameError}
                    </p>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
