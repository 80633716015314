import { useContext } from "react";
import { DataContext } from "../../../../../context/DataContext";
import { TagContext } from "../../../../../context/TagContext";

export default function TabComponent({ tabs, index_offset = 0 }) {
  const { currentTag, availableTags } = useContext(DataContext);
  const { activeTab } = useContext(TagContext);

  const tagDict = {
    ...availableTags.llm.tagger_params.tag_dict,
  };

  return (
    <div className="w-full flex bg-gray-200 flex-row justify-between">
      {tabs.map((tab, index) => {
        let isDisabled = false;
        let tabTitle = "";
        const tabIndex = index + index_offset;

        if (
          tabIndex === 1 &&
          tagDict &&
          !Object.keys(tagDict).includes(currentTag.name)
        ) {
          isDisabled = true;
          tabTitle = "Please select or save a tag to test it";
        } else if (isDisabled) {
          tabTitle = "Feature not available in sandbox";
        }

        return (
          <div
            key={tabIndex}
            className={`border-gray-400 w-full px-4 py-2 cursor-pointer duration-300 translate-all
              ${
                activeTab === tabIndex
                  ? "text-bold bg-primary text-white"
                  : "bg-gray-200 text-gray-600 hover:bg-gray-300 hover:text-grey"
              }
              ${tabIndex !== tabs.length - 1 ? "border-r" : ""}
              `}
            onClick={() => {
              tab.action(tabIndex);
            }}
            title={tabTitle}
          >
            {tab.label}
          </div>
        );
      })}
    </div>
  );
}
