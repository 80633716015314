// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FileUploadContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  height: 450px;
  width: 450px;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 4px;
  padding: 20px;
}
.FileUploadContainer input[type="file"] {
  display: none;
}
.FileUploadLoadingIndicator {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
  height: 450px;
  width: 450px;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 4px;
  padding: 20px;
}

.FileUploadControls {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
`, "",{"version":3,"sources":["webpack://./src/components/utilities/FileUploadModal/styles.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,8BAA8B;EAC9B,QAAQ;EACR,SAAS;EACT,aAAa;EACb,YAAY;EACZ,gCAAgC;EAChC,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;AACf;AACA;EACE,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,QAAQ;EACR,SAAS;EACT,aAAa;EACb,YAAY;EACZ,gCAAgC;EAChC,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC","sourcesContent":[".FileUploadContainer {\n  position: absolute;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  top: 50%;\n  left: 50%;\n  height: 450px;\n  width: 450px;\n  transform: translate(-50%, -50%);\n  background-color: white;\n  border-radius: 4px;\n  padding: 20px;\n}\n.FileUploadContainer input[type=\"file\"] {\n  display: none;\n}\n.FileUploadLoadingIndicator {\n  position: absolute;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  top: 50%;\n  left: 50%;\n  height: 450px;\n  width: 450px;\n  transform: translate(-50%, -50%);\n  background-color: white;\n  border-radius: 4px;\n  padding: 20px;\n}\n\n.FileUploadControls {\n  display: flex;\n  flex-direction: row;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
