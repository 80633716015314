import InfoIcon from "../../../../../utilities/InfoIcon/InfoIcon";

interface TeamSettingsComponentProps {
  currentFormValues: any;
  setCurrentFormValues: (formValues: any) => void;
}

export const TeamSettingsComponent: React.FC<TeamSettingsComponentProps> = ({
  currentFormValues,
  setCurrentFormValues,
}) => {
  const handleViewTeamCatalogsChange = (newValue: boolean) => {
    setCurrentFormValues((prevValues: any) => ({
      ...prevValues,
      "view_team_catalogs": newValue,
    }));
  }
  return (
    <div className="mb-4 bg-gray-100 p-3 mt-5 rounded-lg shadow-md">
      <div className="flex flex-col">
        <p className="gap-2 flex text-grey font-bold text-base rounded-md pr-2 pt-2 pb-2 whitespace-nowrap">
          View Catalog{" "}
          <div className="whitespace-normal text-sm">
            <InfoIcon
              infoText="Selecting which set of catalogs to view" />
          </div>
        </p>
        <div className="flex gap-2 text-sm">
          {
            currentFormValues.user_group && currentFormValues.catalog_team &&
            <div className={`px-4 py-2 border-2 rounded-md cursor-pointer  ${
            currentFormValues.view_team_catalogs
              ? "text-primary font-bold border-primary"
              : "border-2 border-buttonGrey"
          }`}
               onClick={() => handleViewTeamCatalogsChange(true)}
          >
            Team
          </div>
          }
          <div className={`px-4 py-2 border-2 rounded-md cursor-pointer ${
            currentFormValues.view_team_catalogs
              ? "border-2 border-buttonGrey" 
              : "text-primary font-bold border-primary"
          }`}
               onClick={() => handleViewTeamCatalogsChange(false)}
          >
            User
          </div>
        </div>
      </div>
    </div>
  )
}