import ReactDatePicker from "react-datepicker";
import PeriodicCalendar from "./PeriodicCalendar";

export default function ScheduleSettingCalendar({
  minTime,
  maxTime,
  selectedDate,
  setSelectedDate,
  selectedPeriod,
  setSelectedPeriod,
  selectedHour,
  setSelectedHour,
  selectedDayOfWeek,
  setSelectedDayOfWeek,
  selectedDayOfMonth,
  setSelectedDayOfMonth,
  calledFrom = "",
}) {
  return (
    <div className="w-2/5 bg-white p-10 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-5">
        {`Configure ${calledFrom === "recurring" ? "Recurring" : ""} Schedule`}
      </h2>
      <hr className="mb-5" />
      <form className="space-y-6">
        {calledFrom === "recurring" ? (
          <PeriodicCalendar
            selectedPeriod={selectedPeriod}
            setSelectedPeriod={setSelectedPeriod}
            selectedHour={selectedHour}
            setSelectedHour={setSelectedHour}
            selectedDayOfWeek={selectedDayOfWeek}
            setSelectedDayOfWeek={setSelectedDayOfWeek}
            selectedDayOfMonth={selectedDayOfMonth}
            setSelectedDayOfMonth={setSelectedDayOfMonth}
          />
        ) : (
          <div>
            <label
              htmlFor="date"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Start date and time
            </label>
            <ReactDatePicker
              id="date"
              selected={selectedDate || new Date()} // Set default to current date and time
              onChange={(date) => setSelectedDate(date)}
              showTimeSelect
              showTimeInput
              dateFormat="MMM d, yyyy h:mm aa"
              minDate={new Date()}
              minTime={minTime}
              maxTime={maxTime}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500 cursor-pointer text-sm"
              popperPlacement="bottom-start"
              calendarClassName="bg-white shadow-lg rounded-lg border border-gray-200"
              showPopperArrow={false}
            />
          </div>
        )}
      </form>
    </div>
  );
}
