import { useState } from "react";

const ContextModal = ({ isOpen, onClose, onSubmit }) => {
  const [input, setInput] = useState("");

  if (!isOpen) return null;

  const handleSubmit = (context) => {
    onSubmit(context.trim());
    setInput("");
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg">
        <h2 className="text-xl mb-2">
          <strong>Narrow auto-suggestion domain</strong> (optional)
        </h2>
        <p className="text-base text-gray-600 mb-4">
          Provide a few words of context to focus tag suggestion on a certain
          domain
        </p>
        <div>
          <textarea
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className="border p-2 w-full mb-4"
            placeholder="Enter context (optional)"
            rows="3"
          />
          <div className="flex justify-end">
            <button
              type="button"
              onClick={onClose}
              className="mr-2 px-4 py-2 bg-gray-200 rounded"
            >
              Cancel
            </button>
            <button
              type="button"
              className="mr-2 px-4 py-2 bg-primary text-white rounded"
              onClick={() => handleSubmit(input)}
            >
              Generate tags
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContextModal;
