import { useState, useContext } from "react";
import { DataContext } from "../../../../../../context/DataContext";

export function HiddenTags({ currentFormValues, setCurrentFormValues }) {
  const { preferences, availableTags } = useContext(DataContext);
  const [selectedTag, setSelectedTag] = useState("");
  const [selectedTags, setSelectedTags] = useState(currentFormValues?.hidden_tags?.HIDDEN_TAGS);

  const handleChange = (newData) => {
    setCurrentFormValues((prevFormValues) => ({
      ...prevFormValues,
      hidden_tags: {
        ...prevFormValues.hidden_tags,
        HIDDEN_TAGS: newData,
      },
    }));
  };

  const addTag = () => {
    if (selectedTag && !selectedTags.includes(selectedTag)) {
      const updatedTags = [...selectedTags, selectedTag];
      handleChange(updatedTags);
      setSelectedTags(updatedTags);
    }
  };

  const removeTag = (tagToRemove) => {
    const updatedTags = selectedTags.filter((tag) => tag !== tagToRemove);
    handleChange(updatedTags);
    setSelectedTags(updatedTags);
  };

  const renderTagDropdown = () => {
    const tags = Object.keys({
      ...availableTags.llm.tagger_params.tag_dict,
      ...availableTags.sensitivity.tagger_params.tag_dict,
      ...Object.fromEntries(
        preferences.system.EXCLUDE_TAGS.map((tag) => [tag, true]),
      ),
    });

    return (
      <div className="flex items-center gap-4 mb-6">
        <select
          className="flex-grow px-4 py-2 border-2 border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-primary transition duration-200 ease-in-out"
          value={selectedTag}
          onChange={(e) => setSelectedTag(e.target.value)}
        >
          <option value="">Select a tag</option>
          {tags.map(
            (tag) =>
              !selectedTags.includes(tag) && (
                <option key={tag} value={tag}>
                  {tag}
                </option>
              ),
          )}
        </select>
        <button
          type="button"
          className="px-6 py-2 bg-primary text-white font-semibold rounded-lg hover:bg-deasieTurquoise transition duration-200 ease-in-out"
          onClick={addTag}
          aria-label="Add tag"
        >
          Add Tag
        </button>
      </div>
    );
  };

  const renderSelectedTags = () => {
    return (
      <ul className="flex flex-wrap gap-2 mb-6">
        {Array.isArray(selectedTags) &&
          selectedTags.map((tag) => (
            <li
              key={tag}
              className="bg-gray-200 rounded-full px-4 py-2 flex items-center"
            >
              <span className="mr-2">{tag}</span>
              <button
                type="button"
                className="text-red-500 hover:text-red-700 focus:outline-none focus:text-red-800"
                onClick={() => removeTag(tag)}
                aria-label={`Remove ${tag}`}
              >
                ✕
              </button>
            </li>
          ))}
      </ul>
    );
  };

  return (
    <div className="p-8 bg-white rounded-xl shadow-lg border-2 border-gray-200 transition-colors duration-300">
      {renderTagDropdown()}
      {renderSelectedTags()}
    </div>
  );
}
