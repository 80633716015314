import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

export default function PeriodicCalendar({
  selectedPeriod,
  setSelectedPeriod,
  selectedHour,
  setSelectedHour,
  selectedDayOfWeek,
  setSelectedDayOfWeek,
  selectedDayOfMonth,
  setSelectedDayOfMonth,
}) {
  return (
    <div className="space-y-4">
      <div className="bg-green-50 border-l-4 border-primary p-4">
        <p className="text-sm text-green-700">
          <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
          Periodic scheduling means that the selected folders will be
          continuously scanned for new files added to the folder. Any detected
          changes will trigger automatic tagging of the affected files.
        </p>
      </div>
      <div>
        <label
          htmlFor="period"
          className="block text-sm font-medium text-gray-700 mb-1"
        >
          Scheduling period
        </label>
        <select
          id="period"
          value={selectedPeriod}
          onChange={(e) => setSelectedPeriod(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
        >
          <option value="HOURLY">Hourly</option>
          <option value="DAILY">Daily</option>
          <option value="WEEKLY">Weekly</option>
          <option value="MONTHLY">Monthly</option>
        </select>
      </div>

      {selectedPeriod === "DAILY" && (
        <div>
          <label
            htmlFor="hour"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Hour of the day
          </label>
          <select
            id="hour"
            value={selectedHour}
            onChange={(e) => setSelectedHour(parseInt(e.target.value))}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
          >
            {[...Array(24)].map((_, i) => (
              <option key={i} value={i}>
                {i.toString().padStart(2, "0")}:00
              </option>
            ))}
          </select>
        </div>
      )}

      {selectedPeriod === "WEEKLY" && (
        <>
          <div>
            <label
              htmlFor="dayOfWeek"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Day of the week
            </label>
            <select
              id="dayOfWeek"
              value={selectedDayOfWeek}
              onChange={(e) => setSelectedDayOfWeek(parseInt(e.target.value))}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            >
              {[
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
              ].map((day, index) => (
                <option key={index} value={index}>
                  {day}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label
              htmlFor="hour"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Hour of the day
            </label>
            <select
              id="hour"
              value={selectedHour}
              onChange={(e) => setSelectedHour(parseInt(e.target.value))}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            >
              {[...Array(24)].map((_, i) => (
                <option key={i} value={i}>
                  {i.toString().padStart(2, "0")}:00
                </option>
              ))}
            </select>
          </div>
        </>
      )}

      {selectedPeriod === "MONTHLY" && (
        <>
          <div>
            <label
              htmlFor="dayOfMonth"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Day of the month
            </label>
            <select
              id="dayOfMonth"
              value={selectedDayOfMonth}
              onChange={(e) => setSelectedDayOfMonth(parseInt(e.target.value))}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            >
              {[...Array(31)].map((_, i) => (
                <option key={i + 1} value={i + 1}>
                  {i + 1}
                </option>
              ))}
            </select>
          </div>
          <div>
            <label
              htmlFor="hour"
              className="block text-sm font-medium text-gray-700 mb-1"
            >
              Hour of the day
            </label>
            <select
              id="hour"
              value={selectedHour}
              onChange={(e) => setSelectedHour(parseInt(e.target.value))}
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            >
              {[...Array(24)].map((_, i) => (
                <option key={i} value={i}>
                  {i.toString().padStart(2, "0")}:00
                </option>
              ))}
            </select>
          </div>
        </>
      )}
    </div>
  );
}
