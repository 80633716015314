import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useUserProfile } from "../../../../../context/UserProfile";
import "./AdminDashboard.css";

const AdminDashboard = () => {
  const { permissions } = useUserProfile();
  const navigate = useNavigate();
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    console.log("Checking admin permissions:", permissions);

    if (!permissions.feedback?.canEdit || !permissions.feedback?.canView) {
      console.log("Not an admin, redirecting to home.");
      navigate("/"); // Redirect to home if the user is not an admin
      return;
    }

    const fetchTags = async () => {
      try {
        const response = await axios.get("http://127.0.0.1:8000/api/tags/list");
        setTags(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching tags:", error);
        setLoading(false);
      }
    };

    fetchTags();
  }, [permissions, navigate]);

  if (loading) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <div className="admin-dashboard">
      <div className="admin-dashboard-header">
        <h1 className="admin-dashboard-title">Admin Dashboard</h1>
      </div>
      <table className="admin-dashboard-table">
        <thead>
          <tr>
            <th>Tag Name</th>
            <th>Chunk</th>
            <th>Value</th>
            <th>Old Value</th>
            <th>Is Correct</th>
            <th>Comment</th>
            <th>File Name</th>
            <th>Evidence</th>
            <th>Timestamp</th>
            <th>Username</th>
          </tr>
        </thead>
        <tbody>
          {tags.map((tag) => (
            <tr key={`${tag.tag_name}-${tag.chunk}`}>
              <td>{tag.tag_name}</td>
              <td>{tag.chunk}</td>
              <td>{tag.value}</td>
              <td>{tag.old_value}</td>
              <td>{tag.is_correct ? "Yes" : "No"}</td>
              <td>{tag.comment}</td>
              <td>{tag.file_name}</td>
              <td>{tag.evidence}</td>
              <td>{tag.timestamp}</td>
              <td>{tag.username}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AdminDashboard;
