import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormControl } from "@mui/base";
import { InputLabel } from "@mui/material";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";

import "./styles.css";

export const SchedulingModalContent = ({ onChange }) => {
  const [selectedDate, setSelectedDate] = useState();
  const [selectedPeriod, setSelectedPeriod] = useState(null);

  return (
    <div className="scheduling-datetime--container">
      <FormControl>
        <InputLabel id="scheduling-datetime">Select date and time</InputLabel>
        <ReactDatePicker
          showIcon
          className="scheduling-datetime--datepicker"
          icon={<FontAwesomeIcon icon={faCalendar} />}
          selected={selectedDate}
          onChange={(date) => {
            setSelectedDate(date);
            onChange({
              scheduleAt: date,
              schedulingPeriod: selectedPeriod,
            });
          }}
          showTimeSelect
          showTimeInput
          dateFormat="MMM d, yyyy h:mm aa"
          minDate={new Date()}
        />
      </FormControl>
      <FormControl>
        <InputLabel id="scheduling-period-label">Scheduling period</InputLabel>
        <select
          className="scheduling-period--select"
          labelId="scheduling-period-label"
          id="scheduling-period"
          onChange={(event) => {
            setSelectedPeriod(event.target.value);
            onChange({
              scheduleAt: selectedDate,
              schedulingPeriod: event.target.value,
            });
          }}
          value={selectedPeriod}
        >
          <option value="HOURLY">Hourly</option>
          <option value="MONTHLY">Monthly</option>
          <option value="WEEKLY">Weekly</option>
          <option value="DAILY">Daily</option>
        </select>
      </FormControl>
    </div>
  );
};
