import { TableCell } from "@aws-amplify/ui-react";
import { faCheck, faX } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Chip,
  CircularProgress,
  Modal,
  Paper,
  Tab,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import {
  EvidenceReportEntry,
  useEvidenceReports,
} from "../../../api/queryHooks";
import "./styles.css";

type Props = {
  onClose: () => void;
};

export const EvidenceReport: FC<Props> = ({ onClose }) => {
  const [isLoading, setIsLoading] = useState(true);
  const { isFetching, data = { reports: [] } } = useEvidenceReports();
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' }>({ key: 'total', direction: 'desc' });

  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  const groupedByTagname: [string, number, number, number, number, number][] = useMemo(() => {
    const grouped: Record<string, { total: number; valid: number; invalid: number }> = data.reports.reduce(
      (acc: Record<string, { total: number; valid: number; invalid: number }>, entry: EvidenceReportEntry) => {
        if (!acc[entry.tag_name]) {
          acc[entry.tag_name] = { total: 0, valid: 0, invalid: 0 };
        }
        acc[entry.tag_name].total += 1;
        if (entry.is_valid) {
          acc[entry.tag_name].valid += 1;
        } else {
          acc[entry.tag_name].invalid += 1;
        }
        return acc;
      },
      {} as Record<string, { total: number; valid: number; invalid: number }>,
    );

    const sortedData = Object.entries(grouped).map(([tag_name, counts]) => {
      const validPercentage = (counts.valid / counts.total);
      const invalidPercentage = (counts.invalid / counts.total);
      return [
        tag_name,
        counts.total,
        counts.valid,
        counts.invalid,
        validPercentage,
        invalidPercentage,
      ] as [string, number, number, number, number, number];
    });

    sortedData.sort((a, b) => {
      const aValue = a[sortConfig.key === 'tag_name' ? 0 : sortConfig.key === 'total' ? 1 : sortConfig.key === 'valid' ? 2 : sortConfig.key === 'invalid' ? 3 : sortConfig.key === 'validPercentage' ? 4 : 5];
      const bValue = b[sortConfig.key === 'tag_name' ? 0 : sortConfig.key === 'total' ? 1 : sortConfig.key === 'valid' ? 2 : sortConfig.key === 'invalid' ? 3 : sortConfig.key === 'validPercentage' ? 4 : 5];
      if (aValue < bValue) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (aValue > bValue) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });

    return sortedData;
  }, [data, sortConfig]);

  const handleSort = (key: string) => {
    let direction: 'asc' | 'desc' = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  if (isLoading) {
    return (
      <Modal open onClose={onClose}>
        <Box className="EvidenceReportsModal LoadingIndicator">
          <CircularProgress />
        </Box>
      </Modal>
    );
  }

  return (
    <Modal open onClose={onClose}>
      <Box className="EvidenceReportsModal">
        <Typography
          variant="h4"
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            gap: "1rem",
          }}
        >
          Evidence Report{" "}
          <Chip variant="filled" color="info" label={data.reports.length} />
        </Typography>
        <Tabs
          value={activeTabIndex}
          onChange={(_, newTabIndex: number) => setActiveTabIndex(newTabIndex)}
        >
          <Tab label="All" />
          <Tab label="Grouped by Tagname" />
        </Tabs>
        {activeTabIndex === 0 && (
          <TableContainer className="EvidenceReports">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell>Tagname</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Catalogname</TableCell>
                  <TableCell>Catalog Team</TableCell>
                  <TableCell>Is Correct</TableCell>
                  <TableCell>Is Submitted</TableCell>
                  <TableCell>Filename</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.reports.map(
                  (entry: EvidenceReportEntry, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{entry.tag_name}</TableCell>
                      <TableCell>{entry.email}</TableCell>
                      <TableCell>{entry.catalog_name}</TableCell>
                      <TableCell>{entry.catalog_team}</TableCell>
                      <TableCell className="centered">
                        {entry.is_valid ? (
                          <FontAwesomeIcon icon={faCheck} color="green" />
                        ) : (
                          <FontAwesomeIcon icon={faX} color="red" />
                        )}
                      </TableCell>
                      <TableCell className="centered">
                        {entry.is_submitted ? (
                          <FontAwesomeIcon icon={faCheck} color="green" />
                        ) : (
                          <FontAwesomeIcon icon={faX} color="red" />
                        )}
                      </TableCell>
                      <TableCell>{entry.filename}</TableCell>
                    </TableRow>
                  ),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {activeTabIndex === 1 && (
          <TableContainer className="EvidenceReports">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell onClick={() => handleSort('tag_name')}>Tagname</TableCell>
                  <TableCell onClick={() => handleSort('total')}>Total Count</TableCell>
                  <TableCell onClick={() => handleSort('valid')}>Correct</TableCell>
                  <TableCell onClick={() => handleSort('invalid')}>Incorrect</TableCell>
                  <TableCell onClick={() => handleSort('validPercentage')}>% Correct</TableCell>
                  <TableCell onClick={() => handleSort('invalidPercentage')}>% Incorrect</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {groupedByTagname.map(([tag_name, total, valid, invalid, validPercentage, invalidPercentage], index) => (
                  <TableRow key={index}>
                    <TableCell>{tag_name}</TableCell>
                    <TableCell>{total}</TableCell>
                    <TableCell>{valid}</TableCell>
                    <TableCell>{invalid}</TableCell>
                    <TableCell>
                      {validPercentage.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                        style: "percent",
                      })}
                    </TableCell>
                    <TableCell>
                      {invalidPercentage.toLocaleString(undefined, {
                        maximumFractionDigits: 2,
                        minimumFractionDigits: 2,
                        style: "percent",
                      })}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
    </Modal>
  );
};
