import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const CoreTagsModal = ({ isOpen, onClose, selectedTags = [], onConfirm }) => {
  const [coreTags, setCoreTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const handleCoreTagChange = (tag) => {
    if (coreTags.includes(tag)) {
      setCoreTags(coreTags.filter((t) => t !== tag));
    } else {
      setCoreTags([...coreTags, tag]);
    }
  };

  const filteredTags = selectedTags.filter((tag) =>
    tag.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  if (!isOpen) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex justify-center items-center">
      <div className="bg-white p-8 rounded-lg shadow-xl w-1/2">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-2xl font-semibold text-gray-800">
            Select tags that will always run
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            &times;
          </button>
        </div>
        <div className="bg-green-50 border-l-4 border-primary p-4">
          <p className="text-sm text-green-700">
            <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
            Select the tags that you want to <strong>always apply</strong> in
            the <strong>smart-selection</strong> process.
          </p>
        </div>
        <div></div>
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Search tags..."
          className="w-full mb-4 mt-4 p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 text-sm"
        />
        <div className="max-h-60 overflow-y-auto">
          {filteredTags.map((tag) => (
            <div
              key={tag}
              className="flex items-center mb-2 p-2 hover:bg-gray-100 rounded-lg text-base"
            >
              <input
                type="checkbox"
                id={tag}
                checked={coreTags.includes(tag)}
                onChange={() => handleCoreTagChange(tag)}
                className="appearance-none h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 transition duration-200 ease-in-out cursor-pointer"
              />
              <label
                htmlFor={tag}
                className="text-gray-700 select-none cursor-pointer ml-3"
              >
                {tag}
              </label>
            </div>
          ))}
        </div>
        <div className="mt-6 flex justify-end">
          <button
            onClick={() => {
              onConfirm(coreTags);
              onClose();
            }}
            className="bg-primary text-white text-base px-6 py-2 rounded-lg shadow focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default CoreTagsModal;
