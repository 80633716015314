// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ProgressBarContainer {
  overflow: hidden;
  width: 70%;
}

.ProgressBar {
  color: white;
  text-align: center;
  border-radius: 5px;
  transition: width 0.5s ease-in-out;
  white-space: nowrap;
  height: 100%;
  display: flex;
  align-items: center;
  padding-left: 1vw;
  padding-right: 1vw;
}
`, "",{"version":3,"sources":["webpack://./src/components/utilities/NavigationBar/Components/ProgressBar/ProgressBar.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,kBAAkB;EAClB,kCAAkC;EAClC,mBAAmB;EACnB,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".ProgressBarContainer {\n  overflow: hidden;\n  width: 70%;\n}\n\n.ProgressBar {\n  color: white;\n  text-align: center;\n  border-radius: 5px;\n  transition: width 0.5s ease-in-out;\n  white-space: nowrap;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  padding-left: 1vw;\n  padding-right: 1vw;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
