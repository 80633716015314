import React, { useContext, useState, useEffect } from "react";
import { DataContext } from "../../../../../../context/DataContext";
import {
  addNewCatalog,
  deleteCatalog,
} from "../../../../../utilities/functions/apiCalls";
import { useCatalogNames } from "../../../../../../api/queryHooks";
import { toast } from "../../../../../utilities/Toast";

type DataContextType = {
  catalogNames: string[];
  setCatalogNames: React.Dispatch<React.SetStateAction<string[]>>;
  setCatalogGetsCreated: any;
  setShowScreen: any;
  handleCatalogRename: (
    oldName: string,
    newName: string,
  ) => Promise<{ success: boolean }>;
};

export function CatalogComponent() {
  const { data: catalogNames = [] } = useCatalogNames();
  const { handleCatalogRename, setCatalogGetsCreated, setShowScreen } =
    useContext<DataContextType>(DataContext);
  const [catalogList, setCatalogList] = useState(catalogNames);
  const [filteredCatalogs, setFilteredCatalogs] = useState(catalogNames);
  const [newCatalogName, setNewCatalogName] = useState("");
  const [editingName, setEditingName] = useState<string | null>(null);
  const [editedName, setEditedName] = useState("");
  const [updatingCatalog, setUpdatingCatalog] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setFilteredCatalogs(
      catalogList.filter((catalog) =>
        catalog.toLowerCase().includes(searchTerm.toLowerCase()),
      ),
    );
  }, [searchTerm, catalogList]);

  const addCatalog = () => {
    if (newCatalogName && !catalogList.includes(newCatalogName)) {
      addNewCatalog(newCatalogName);
      const updatedCatalogs = [...catalogList, newCatalogName];
      setCatalogList(updatedCatalogs);
      setNewCatalogName("");
    }
  };

  const deleteFromCatalog = (name: string) => {
    const isConfirmed = window.confirm(
      `Deleting '${name}' will remove access to files in this catalog. Are you sure you want to proceed?`,
    );

    if (isConfirmed) {
      deleteCatalog(name)
        .then(() => {
          const updatedCatalogs = catalogList.filter(
            (catalog) => catalog !== name,
          );
          setCatalogList(updatedCatalogs);
          toast.success({
            title: "Success",
            description: `Catalog '${name}' deleted successfully!`,
          });
        })
        .catch((error) => {
          console.error("Failed to delete catalog:", error);
          toast.error({
            title: "Error",
            description: `Failed to delete catalog '${name}'. Please try again.`,
          });
        });
    }
  };

  const startEditing = (name: string) => {
    setEditedName(name);
    setEditingName(name);
  };

  const applyEdit = async (oldName: string) => {
    if (oldName === editedName || !editedName.trim()) {
      setEditingName(null);
      setEditedName("");
      return;
    }
    setUpdatingCatalog(true);
    try {
      const response = await handleCatalogRename(oldName, editedName);
      if (!response.success) {
        toast.error({
          title: "Error",
          description:
            editedName === "Global"
              ? "'Global' is a reserved catalog name."
              : "Failed to rename catalog. Please try again.",
        });
        setEditingName(null);
        setEditedName("");
        throw new Error("Failed to rename catalog");
      }
      const updatedCatalogs = [...catalogList];
      const index = updatedCatalogs.indexOf(oldName);
      if (index !== -1) {
        updatedCatalogs[index] = editedName;
        setCatalogList(updatedCatalogs);
      }
      setEditingName(null);
      setEditedName("");
    } catch (error) {
      console.error("Failed to update catalog name:", error);
    }
    setUpdatingCatalog(false);
  };

  return (
    <div className="p-8 bg-white rounded-xl shadow-lg border-2 border-gray-200 transition-colors duration-300">
      <div className="mb-6 flex flex-col md:flex-row justify-between items-center gap-4">
        <input
          type="text"
          className="w-full md:w-2/3 px-4 py-2 border-2 border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-primary transition duration-200 ease-in-out"
          placeholder="Search catalogs..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button
          className="w-full md:w-auto px-6 py-2 bg-primary hover:bg-deasieTurquoise text-white rounded-lg shadow transition duration-200 ease-in-out"
          onClick={() => {
            setCatalogGetsCreated(true);
            setShowScreen("catalog");
          }}
        >
          Add Catalog
        </button>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredCatalogs.map((name) => (
          <div
            key={name}
            className="flex items-center justify-between bg-gray-50 p-4 rounded-lg shadow-md border-2 border-gray-200 hover:border-primary transition-colors duration-300"
          >
            {editingName === name ? (
              updatingCatalog ? (
                <span className="text-gray-500">Updating...</span>
              ) : (
                <input
                  type="text"
                  className="w-full px-3 py-2 border-2 border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-primary transition duration-200 ease-in-out"
                  value={editedName}
                  onChange={(e) => setEditedName(e.target.value)}
                  onBlur={() => applyEdit(name)}
                  onKeyPress={(e) => e.key === "Enter" && applyEdit(name)}
                />
              )
            ) : (
              <span className="text-gray-800 truncate font-medium">{name}</span>
            )}
            <div className="flex items-center">
              {editingName !== name && !updatingCatalog && (
                <button
                  className="ml-2 text-blue-500 hover:text-blue-700 transition duration-200 ease-in-out"
                  onClick={() => startEditing(name)}
                >
                  <span className="sr-only">Edit</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                  </svg>
                </button>
              )}
              {editingName !== name && !updatingCatalog && (
                <button
                  className="ml-2 text-red-500 hover:text-red-700 transition duration-200 ease-in-out"
                  onClick={() => deleteFromCatalog(name)}
                >
                  <span className="sr-only">Delete</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                      clipRule="evenodd"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
