export function TabButton({ tab, activeTab, setActiveTab, label }) {
  return (
    <button
      onClick={() => setActiveTab(tab)}
      className={`text-left px-6 py-3 transition-colors duration-150 ease-in-out ${
        activeTab === tab
          ? "bg-deasieTurquoise text-gray-800 font-medium border-l-4 border-primary"
          : "text-gray-600 hover:bg-gray-50 hover:text-gray-900"
      }`}
    >
      {label}
    </button>
  );
}
