import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const TagVersionList = ({ versions, onClose, currentTag, setCurrentTag }) => {

  const tag_versions = versions.tag_versions;
  const [hoveredRow, setHoveredRow] = useState(null);
  const [checkedRow, setCheckedRow] = useState(null);
  const ref = useRef(null);

  const handleRowHover = (uuid) => {
    setHoveredRow(uuid);
  };

  const handleRowLeave = () => {
    setHoveredRow(null);
  };

  const handleCheckboxChange = (uuid) => {
    setCheckedRow(uuid === checkedRow ? null : uuid);
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    setCheckedRow(currentTag.version_uuid);
  }, [currentTag]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const parseNewValue = (attributes, newValues) => {

    try {
      return attributes.map((attr, index) => {
        const value = newValues[index];

        if (attr === 'examples' || attr === 'neg_examples') {
          if (Array.isArray(value) && typeof value[0] === 'object') {
            const result = value.map(example => example.value).join(', ');
            return result === '' ? `${attr}: empty` : `${attr}: ${result}`;
          }
        } else if (attr === 'availableValues') {
          const parsedValue = JSON.parse(value);
          const result = `${parsedValue.join('/')}`;
          return result === '' ? `${attr}: empty` : `${attr}: ${result}`;
        } else {
          const result = `${value.replace(/^"|"$/g, '')}`;
          return result === '' ? `${attr}: empty` : `${attr}: ${result}`;
        }
      }).join(' ');
    } catch (error) {
      return Array.isArray(newValues) ? newValues.join('; ') : newValues;
    }
  };

  return (
    <motion.div
      ref={ref}
      className="relative flex flex-col bg-white shadow-xl rounded-lg w-full max-w-4xl p-4 min-h-[300px] overflow-hidden"
      initial={{ opacity: 0, y: 0 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -10 }}
      transition={{ duration: 0.5 }}
    >

      <div className="relative w-full flex flex-col overflow-auto flex-grow">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-base ml-1">Tag versions</h2>
          <button
            onClick={onClose}
            className="text-red-500 hover:underline px-2 py-2 text-sm font-semibold bg-white transition-colors duration-300"
          >
            Close
          </button>
        </div>

        {tag_versions.length === 0 ? (
          <div className="flex items-center justify-center h-full text-center text-gray-500">No previous versions found</div>
        ) : (
          <table className="min-w-full divide-y divide-gray-200 table-fixed pt-3">
            <thead className="bg-gray-100">
              <tr>
                <th className="px-4 py-2 w-12"></th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-800 tracking-wider">
                  Updated Time
                </th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-800 tracking-wider">
                  Modified Attribute
                </th>
                <th className="px-4 py-2 text-left text-sm font-medium text-gray-800 tracking-wider">
                  Value
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
            {tag_versions.sort((a, b) => new Date(b.updated_time) - new Date(a.updated_time)).map((version) => (
                <tr
                  key={version.version_uuid}
                  className={`hover:bg-gray-100 transition-colors duration-200 ${hoveredRow === version.version_uuid ? 'bg-gray-50' : ''}`}
                  onMouseEnter={() => handleRowHover(version.version_uuid)}
                  onMouseLeave={handleRowLeave}
                >
                  <td className="px-4 py-2 whitespace-nowrap text-center">
                    <input
                      type="checkbox"
                      className="hover:cursor-pointer"
                      checked={checkedRow === version.version_uuid}
                      onChange={() => handleCheckboxChange(version.version_uuid)}
                    />
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-800">
                    {new Date(version.updated_time).toLocaleString()}
                  </td>
                  <td className="px-4 py-2 whitespace-nowrap text-sm text-gray-800">
                    {Array.isArray(version.modified_attributes)
                      ? version.modified_attributes.join(', ')
                      : version.modified_attributes}
                  </td>
                  <td className="px-4 py-2 text-sm text-gray-800 max-w-full truncate" data-tip data-for={`tooltip-${version.version_uuid}`}>
                    {parseNewValue(version.modified_attributes, version.new_values)}
                    <ReactTooltip id={`tooltip-${version.version_uuid}`} place="top" effect="solid" clickable={true} globalEventOff="click">
                      {parseNewValue(version.modified_attributes, version.new_values)}
                    </ReactTooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {checkedRow && (
          <div className="mt-auto flex justify-center mb-1 pt-2">
            <button
              className="px-4 py-2 bg-primary text-white rounded hover:bg-slate-500 transition-colors duration-20"
              onClick={() => {
                const selectedVersion = tag_versions.find(version => version.version_uuid === checkedRow);
                const newTag = selectedVersion.tag_details;
                newTag.version_uuid = selectedVersion.version_uuid;
                if (selectedVersion) {
                  setCurrentTag(newTag);
                }
                onClose();
              }}
            >
              Use this version
            </button>
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default TagVersionList;
