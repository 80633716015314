import { useState, useRef, useEffect, useContext } from "react";
import "./DataList.css";
import Auth from "../../../../../../../auth/AuthProvider";
import LoadComponent from "../../../../../../utilities/LoadComponent/LoadComponent";
import { DataContext } from "../../../../../../../context/DataContext";
import EvidenceTable from "./DataListComponent/EvidenceTable/EvidenceTable";
import { useUserProfile } from "../../../../../../../context/UserProfile";
import { useAtom } from "jotai";
import {
  documentsUploadTaskAtom,
  documentTaggingTaskAtom,
} from "../../../../../../../atoms";
import { abortTask } from "../../../../../../../utils/workers";
import CatalogTable from "../../../../../../../pages/CatalogTable";
import { NonTaskProgressBar } from "../../../../../../utilities/NavigationBar/Components/ProgressBar/ProgressBar";
import { TagContext } from "../../../../../../../context/TagContext";
import DocumentEvidenceTable from "./DataListComponent/EvidenceTable/DocumentEvidenceTable";
import { GrCatalog } from "react-icons/gr";
import { useCatalogNames } from "../../../../../../../api/queryHooks";

export default function DataList(props) {
  const legendRef = useRef(null);
  const [expandTags, setExpandTags] = useState(false);
  const [expandedRowKey, setExpandedRowKey] = useState(null);
  const [showAllEvidence, setShowAllEvidence] = useState(false);
  const [currentItemKey, setCurrentItemKey] = useState("");
  const [sortedChunkMappings, setSortedChunkMappings] = useState({});

  const [documentsUploadTask, setDocumentsUploadTask] = useAtom(
    documentsUploadTaskAtom,
  );
  const [documentTaggingTask, setDocumentTaggingTask] = useAtom(
    documentTaggingTaskAtom,
  );

  const {
    currentDataGroup,
    processingFile,
    currentProcessCount,
    currentTotalProcessCount,
    availableTags,
    handleEvidenceButtonClick,
    evidenceData,
    setEvidenceData,
    isEvidenceModalOpen,
    setIsEvidenceModalOpen,
    isDocumentEvidenceModalOpen,
    setIsDocumentEvidenceModalOpen,
    uploadDataProgress,
    usedCatalog,
  } = useContext(DataContext);

  const { data: catalogNames = [] } = useCatalogNames();

  const { processingTagTaskProgress } = useContext(TagContext);

  const { username } = useUserProfile();

  useEffect(() => {
    const sortedChunkKeys = Object.keys(
      currentDataGroup[currentItemKey]?.chunks || {},
    ).sort((a, b) => {
      const aStart = parseInt(a.split("_")[0], 10);
      const bStart = parseInt(b.split("_")[0], 10);
      return aStart - bStart;
    });

    const chunkMappings = {};
    sortedChunkKeys.forEach((chunkKey, index) => {
      chunkMappings[chunkKey] = `Chunk ${index + 1}`;
    });

    setSortedChunkMappings(chunkMappings);
  }, [currentDataGroup, currentItemKey]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!legendRef.current?.contains(event.target)) {
        setExpandTags(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [
    processingFile,
    expandedRowKey,
    currentProcessCount,
    currentTotalProcessCount,
  ]);

  if (props.answerLoading) {
    return (
      <div className="LoadComponentContainer">
        <LoadComponent />
      </div>
    );
  }

  return (
    <div className="overflow-hidden flex w-full h-full flex-col rounded-b-md">
      {(documentsUploadTask || documentTaggingTask) && (
        <div className="rounded-t-md w-full justify-betweem flex flex-row bg-white transition-all duration-200 p-4 items-center gap-4">
          {documentsUploadTask && (
            <>
              <NonTaskProgressBar progress={uploadDataProgress} />
              <button
                onClick={async () => {
                  await abortTask(
                    documentsUploadTask,
                    (await Auth.currentAuthenticatedUser()).username,
                    "Failed to stop the data upload process.",
                    "The data uploading process has been successfully stopped.",
                  );
                  setDocumentsUploadTask(null);
                }}
                className="text-sm bg-red-400 px-2 py-1 rounded-md text-white whitespace-nowrap"
              >
                Abort Upload
              </button>
            </>
          )}
          {documentTaggingTask && (
            <>
              <NonTaskProgressBar
                progress={(processingTagTaskProgress * 100).toFixed(2)}
              />
            </>
          )}
        </div>
      )}

      <div className="flex w-full h-full overflow-hidden">
        {catalogNames.includes(usedCatalog) ? (
          <CatalogTable
            onEvidenceClick={handleEvidenceButtonClick}
            availableTags={availableTags}
          />
        ) : (
          <div className="flex flex-col items-center justify-center h-full w-full bg-gray-100">
            <GrCatalog className="text-6xl text-gray-400 mb-4" />
            <h2 className="text-2xl font-bold text-gray-700 mb-2">
              No Catalog Selected
            </h2>
            <p className="text-gray-600 text-center max-w-md">
              Please select a catalog from your catalog list or create a new one to begin tagging and using Deasie.
            </p>
          </div>
        )}
      </div>
      <EvidenceTable
        showAllEvidence={showAllEvidence}
        setShowAllEvidence={setShowAllEvidence}
        evidenceData={evidenceData}
        setEvidenceData={setEvidenceData}
        currentItemKey={currentItemKey}
        isEvidenceModalOpen={isEvidenceModalOpen}
        setIsEvidenceModalOpen={setIsEvidenceModalOpen}
        sortedChunkMappings={sortedChunkMappings}
        username={username}
      />
      <DocumentEvidenceTable
        setShowAllEvidence={setShowAllEvidence}
        isDocumentEvidenceModalOpen={isDocumentEvidenceModalOpen}
        setIsDocumentEvidenceModalOpen={setIsDocumentEvidenceModalOpen}
      />
    </div>
  );
}
