import React, { useEffect, useState } from "react";
import { startOfMonth } from "date-fns";
import {
  TokenUsageTracking,
  useUserTokenUsage,
} from "../../../../../../api/queryHooks";
import { toast } from "../../../../../utilities/Toast";
import { CircularProgress } from "@mui/material";
import { formatDateInputValue } from "../../../../../../utils/datetime";
import { ChartEndpointEntry } from "./TokenGraphUtils/types";
import { TokenUsageGraph } from "./TokenGraphUtils/TokenUsageGraph";
import { tokenListToCharEndpointData } from "./TokenGraphUtils/utils";

export function UserTokenUsageComponent() {
  const [startDate, setStartDate] = useState(startOfMonth(new Date()));
  const [endDate, setEndDate] = useState(new Date());
  const [chartData, setChartData] = useState<ChartEndpointEntry[]>();

  const { isLoading, data, error } = useUserTokenUsage({
    startDate,
    endDate,
  });

  useEffect(() => {
    if (isLoading || !data) {
      return;
    }

    if (error) {
      toast.error({
        title: "Failed to fetch token usage data:",
        description: error,
      });
      return;
    }

    const transformedData = transformDataForGraph(data);
    setChartData(transformedData);
  }, [isLoading, data, error]);

  const transformDataForGraph = (
    apiResponse: TokenUsageTracking,
  ): ChartEndpointEntry[] => {
    return tokenListToCharEndpointData(apiResponse.token_list);
  };

  const handleStartDateChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const selectedDate = new Date(event.target.value);
    setStartDate(selectedDate);
  };

  const handleEndDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedDate = new Date(event.target.value);
    setEndDate(selectedDate);
  };

  return (
    <div className="p-8 bg-white rounded-xl shadow-lg border-2 border-gray-200 transition-colors duration-300">
      <h2 className="text-3xl font-bold text-gray-800 mb-6 pb-2 border-b-2 border-gray-200">Token Usage</h2>
      <div className="flex flex-col md:flex-row justify-between items-center gap-6 mb-8">
        <div className="w-full md:w-1/2">
          <label className="block text-gray-700 font-medium mb-2">Start Date:</label>
          <input
            type="date"
            disabled={isLoading}
            className="w-full px-4 py-2 border-2 border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-primary transition duration-200 ease-in-out"
            value={formatDateInputValue(startDate)}
            onChange={handleStartDateChange}
            max={formatDateInputValue(endDate)}
          />
        </div>
        <div className="w-full md:w-1/2">
          <label className="block text-gray-700 font-medium mb-2">End Date:</label>
          <input
            type="date"
            disabled={isLoading}
            className="w-full px-4 py-2 border-2 border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-primary transition duration-200 ease-in-out"
            value={formatDateInputValue(endDate)}
            onChange={handleEndDateChange}
            min={formatDateInputValue(startDate)}
            max={formatDateInputValue(new Date())}
          />
        </div>
      </div>
      {isLoading && (
        <div className="flex justify-center items-center py-20">
          <CircularProgress className="text-primary" />
        </div>
      )}
      {!isLoading && chartData && (
        <div className="bg-gray-50 rounded-lg p-6 border-2 border-gray-200">
          <TokenUsageGraph data={chartData} />
        </div>
      )}
    </div>
  );
}
