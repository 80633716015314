import React, { useState, useContext, useEffect } from "react";
import { useUserProfile } from "../../../../../context/UserProfile";
import { DataContext } from "../../../../../context/DataContext";
import { savePreferences } from "../../../../utilities/functions/apiCalls";
import { TabButton } from "./PreferencesComponents/SmallButtonComponents/TabButton";
import { TabContent } from "./PreferencesComponents/TabContent";
import { SaveButton } from "./PreferencesComponents/SmallButtonComponents/SaveButton";
import { toast } from "../../../../utilities/Toast";

const tabMapping = {
  profile: "Tag settings",
  hidden_tags: "Hidden Tags",
  webapp_profile: "Data Connectors",
  catalogs: "Catalogs",
  token_usage: "Usage",
  team_usage: "Team Usage",
  team_settings: "Team",
};

export default function UserPreferences({ onSubmit, visibleTabs }) {
  const { permissions } = useUserProfile();
  const { preferences } = useContext(DataContext);
  const [activeTab, setActiveTab] = useState("profile");
  const [isSaving, setIsSaving] = useState(false);

  const availableTabs = React.useMemo(() => {
    const basicTabs = Object.keys(tabMapping);
    return basicTabs.filter(
      (tab) =>
        (tab !== "team_usage" || permissions.teams.canView) &&
        (visibleTabs?.includes(tab) ?? true),
    );
  }, [permissions, visibleTabs]);
  const [currentFormValues, setCurrentFormValues] = useState(preferences);

  const handleSubmit = async () => {
    if (onSubmit) {
      onSubmit(currentFormValues);
      return;
    }

    setIsSaving(true);
    try {
      console.log("currentFormValuesHITT", currentFormValues);
      await savePreferences(currentFormValues);
      toast.success({
        title: "Preferences saved successfully.",
        description: "",
      });
    } catch (error) {
      toast.error({
        title: `Saving preferences failed: ${error}`,
        description: "",
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="flex bg-gray-100 rounded-lg shadow-lg w-full h-full overflow-hidden">
      <div className="flex-shrink-0 bg-white border-r border-gray-200 overflow-y-auto">
        <div className="py-4 px-3 bg-gray-50 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-gray-800">Preferences</h2>
        </div>
        <div className="mt-2 flex flex-col">
          {availableTabs.map((tab) => (
            <TabButton
              key={tab}
              tab={tab}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              label={tabMapping[tab]}
            />
          ))}
        </div>
      </div>
      <div className="flex-grow bg-gray-100 flex flex-col min-w-0">
        <div className="flex items-center justify-between px-6 py-4 bg-gray-50 border-b border-gray-200">
          <h3 className="text-xl font-semibold text-gray-800">
            {tabMapping[activeTab]}
          </h3>
          <SaveButton
            activeTab={activeTab}
            isSaving={isSaving}
            handleSubmit={handleSubmit}
          />
        </div>
        <div className="flex-1 overflow-auto p-6">
          {preferences ? (
            <TabContent
              activeTab={activeTab}
              currentFormValues={currentFormValues}
              setCurrentFormValues={setCurrentFormValues}
            />
          ) : (
            <div className="flex items-center justify-center h-full">
              <div className="animate-pulse flex space-x-4">
                <div className="rounded-full bg-gray-200 h-12 w-12"></div>
                <div className="flex-1 space-y-4 py-1">
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                  <div className="space-y-2">
                    <div className="h-4 bg-gray-200 rounded"></div>
                    <div className="h-4 bg-gray-200 rounded w-5/6"></div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
