// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.export-options-select-container{
    padding: 10px;   
    display: flex;
    flex-direction: column;

}

.export-options-container h3 {
  margin-bottom: 10px;
  color: #333;
  font-size: 1.2rem;
}

.export-options-container label {
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
}

.export-options-container input[type="checkbox"] {
  margin-right: 10px;
}

.export-options-container select {
  width: 100%;
  padding: 8px;
  margin-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: white;
}

.export-options-container button {
  display: block;
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.export-options-container button:hover {
  background-color: #30917a;
}
`, "",{"version":3,"sources":["webpack://./src/components/utilities/NavigationBar/Components/Export/Export.css"],"names":[],"mappings":";;AAEA;IACI,aAAa;IACb,aAAa;IACb,sBAAsB;;AAE1B;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,cAAc;EACd,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,sBAAsB;EACtB,uBAAuB;AACzB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,eAAe;EACf,eAAe;EACf,sCAAsC;AACxC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["\n\n.export-options-select-container{\n    padding: 10px;   \n    display: flex;\n    flex-direction: column;\n\n}\n\n.export-options-container h3 {\n  margin-bottom: 10px;\n  color: #333;\n  font-size: 1.2rem;\n}\n\n.export-options-container label {\n  display: block;\n  margin-bottom: 10px;\n  cursor: pointer;\n}\n\n.export-options-container input[type=\"checkbox\"] {\n  margin-right: 10px;\n}\n\n.export-options-container select {\n  width: 100%;\n  padding: 8px;\n  margin-bottom: 20px;\n  border-radius: 4px;\n  border: 1px solid #ddd;\n  background-color: white;\n}\n\n.export-options-container button {\n  display: block;\n  width: 100%;\n  padding: 10px;\n  border: none;\n  border-radius: 5px;\n  color: white;\n  font-size: 1rem;\n  cursor: pointer;\n  transition: background-color 0.3s ease;\n}\n\n.export-options-container button:hover {\n  background-color: #30917a;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
