// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scheduling-datetime--container {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}

.scheduling-period--select {
  background-color: white;
  border: 1px solid black;
  border-radius: 3px;
  padding: 10px;
}

.scheduling-datetime--datepicker {
  border: 1px solid black;
  border-radius: 3px;
  height: 41px;
}
`, "",{"version":3,"sources":["webpack://./src/components/utilities/SchedulingModalContent/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,uBAAuB;EACvB,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,YAAY;AACd","sourcesContent":[".scheduling-datetime--container {\n  display: flex;\n  flex-direction: row;\n  column-gap: 20px;\n}\n\n.scheduling-period--select {\n  background-color: white;\n  border: 1px solid black;\n  border-radius: 3px;\n  padding: 10px;\n}\n\n.scheduling-datetime--datepicker {\n  border: 1px solid black;\n  border-radius: 3px;\n  height: 41px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
