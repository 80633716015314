import React, { useState, useCallback, useContext, useEffect } from "react";
import InfoIcon from "../../../../../utilities/InfoIcon/InfoIcon";
import { DataContext } from "../../../../../../context/DataContext";
import ImageSelector from "./ImageSelector";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { useCatalogDocumentInfoLoader } from "../../../../../../api/queryHooks";
import useConfirm from "../../../../../../hooks/useConfirm";
import { TagContext } from "../../../../../../context/TagContext";
import { toast } from "../../../../../utilities/Toast";
import { FaExpandAlt, FaCompressAlt } from "react-icons/fa";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const ExamplesSection = ({
  addExamplePair,
  addNegExamplePair,
  updateExample,
  deleteExamplePair,
  deleteNegExamplePair,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const { currentTag, setCurrentTag } = useContext(DataContext);
  const { saveTag } = useContext(TagContext);
  const [isCollapsed, setIsCollapsed] = useState(true);

  const filterExamples = useCallback(
    (examples) => {
      if (!examples) return [];
      return examples.filter((ex) => {
        const evidenceText =
          typeof ex.evidence === "string"
            ? ex.evidence
            : ex.evidence.text || "";
        const valueText = ex.value || "";
        const nameText = ex.evidence.name || "";
        return (
          evidenceText.toLowerCase().includes(searchTerm.toLowerCase()) ||
          valueText.toLowerCase().includes(searchTerm.toLowerCase()) ||
          nameText.toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    },
    [searchTerm],
  );

  const filteredCorrectExamples = filterExamples(currentTag.examples);
  const filteredIncorrectExamples = filterExamples(currentTag.neg_examples);

  return (
    <div className="bg-white rounded-lg shadow-sm p-6 overflow-auto hide-scrollbar">
      <h2 className="text-xl font-semibold text-gray-800 mb-4 flex items-center gap-2">
        Training Examples
        <InfoIcon infoText="Provide example input-output pairs to teach the AI how to tag content. This improves accuracy and consistency in tagging." />
      </h2>

      <div className="bg-containerLight border-l-4 border-primary p-4 mb-6 rounded-r-md">
        <p className="text-sm text-gray-600">
          For best results:
          <ul className="list-disc ml-5 mt-2 space-y-1">
            <li>Include diverse examples covering different scenarios</li>
            <li>Provide both correct and incorrect examples</li>
            <li>Be specific and consistent in your output format</li>
          </ul>
        </p>
      </div>

      <div className="flex justify-between items-center mb-8">
        <div className="flex space-x-2">
          <button
            onClick={addExamplePair}
            className="border-2 border-primary text-primary hover:bg-containerLight font-medium py-1 px-2 rounded-md transition duration-300 text-sm"
          >
            Add Correct Example
          </button>
          <button
            onClick={addNegExamplePair}
            className="border-2 border-gray-300 text-gray-600 hover:bg-gray-100 font-medium py-1 px-2 rounded-md transition duration-300 text-sm"
          >
            Add Incorrect Example
          </button>
          <button
            onClick={() => setIsCollapsed(!isCollapsed)}
            className="bg-gray-200 hover:bg-gray-300 text-gray-700 font-medium py-2 px-4 rounded-md transition duration-300"
          >
            {isCollapsed ? <FaExpandAlt /> : <FaCompressAlt />}
          </button>
        </div>
      </div>

      <div className="mb-4">
        <input
          type="text"
          placeholder="Search examples..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary"
        />
      </div>

      <div className="max-h-[600px] overflow-y-auto pr-2 hide-scrollbar">
        <ExampleList
          title="Correct Examples"
          examples={filteredCorrectExamples}
          updateExample={updateExample}
          deleteExample={deleteExamplePair}
          type="examples"
          borderColor="border-primary"
          bgColor="bg-containerLight"
          isCollapsed={isCollapsed}
          addExample={addExamplePair}
        />
        <ExampleList
          title="Incorrect Examples"
          examples={filteredIncorrectExamples}
          updateExample={updateExample}
          deleteExample={deleteNegExamplePair}
          type="neg_examples"
          borderColor="border-red-200"
          bgColor="bg-red-50"
          isCollapsed={isCollapsed}
          addExample={addNegExamplePair}
        />
      </div>
    </div>
  );
};

const ExampleList = ({
  title,
  examples,
  updateExample,
  deleteExample,
  type,
  borderColor,
  bgColor,
  isCollapsed,
  addExample,
}) => {
  if (!examples || examples.length === 0) return null;

  return (
    <div className="mb-8">
      <h3 className="text-lg font-medium text-gray-800 mb-2 flex justify-between items-center">
        <span>{title}</span>
      </h3>
      <p className="text-sm text-gray-600 mb-4">
        {type === "examples"
          ? "Correct examples help the AI understand what to look for and how to correctly apply the tag."
          : "Incorrect examples teach the AI what not to tag, improving accuracy and reducing false positives."}
      </p>
      {examples.map((example, index) => (
        <ExamplePair
          key={index}
          example={example}
          index={index}
          updateExample={updateExample}
          deleteExample={deleteExample}
          type={type}
          borderColor={borderColor}
          bgColor={bgColor}
          isCollapsed={isCollapsed}
        />
      ))}
    </div>
  );
};

const ExamplePair = ({
  example,
  index,
  updateExample,
  deleteExample,
  type,
  borderColor,
  bgColor,
  isCollapsed,
}) => {
  const isImageEvidence = useCallback((evidence) => {
    try {
      const parsedEvidence = JSON.parse(evidence);
      return (
        parsedEvidence.type === "image" && parsedEvidence.text.includes("~|~")
      );
    } catch {
      return false;
    }
  }, []);

  const [showImageSelector, setShowImageSelector] = useState(false);
  const [inputType, setInputType] = useState(
    isImageEvidence(example.evidence) ? "image" : "text",
  );
  const [imageUrl, setImageUrl] = useState(null);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [lightboxImage, setLightboxImage] = useState([]);
  const loadDocumentInfo = useCatalogDocumentInfoLoader();
  const [isExampleCollapsed, setIsExampleCollapsed] = useState(isCollapsed);
  const { confirm } = useConfirm();
  const { currentTag, setCurrentTag } = useContext(DataContext);
  const { saveTag } = useContext(TagContext);

  useEffect(() => {
    setIsExampleCollapsed(isCollapsed);
  }, [isCollapsed]);

  useEffect(() => {
    if (isImageEvidence(example.evidence)) {
      const parsedEvidence = JSON.parse(example.evidence);
      const [filename] = parsedEvidence.text.split("~|~");
      const fetchImageUrl = async () => {
        try {
          const documentInfo = await loadDocumentInfo(filename);
          const url = documentInfo.file_url.toString();
          setImageUrl(url);
        } catch (err) {
          console.error(`Error loading image info for ${filename}:`, err);
          setImageUrl(null);
        }
      };
      fetchImageUrl();
    } else {
      setImageUrl(null);
    }
  }, [example.evidence, isImageEvidence, loadDocumentInfo]);

  const handleImageSelect = useCallback(
    async (imageData) => {
      const formattedEvidence = `${imageData.filename}~|~${imageData.path}~|~${imageData.storage}`;
      const evidenceObject = {
        type: "image",
        text: formattedEvidence,
        name: JSON.parse(example.evidence)?.name || "Unnamed",
        creationDate:
          JSON.parse(example.evidence)?.creationDate ||
          new Date().toISOString(),
      };
      updateExample(index, "evidence", JSON.stringify(evidenceObject), type);
      setShowImageSelector(false);
      setInputType("image");

      try {
        const documentInfo = await loadDocumentInfo(imageData.filename);
        const url = documentInfo.file_url.toString();
        setImageUrl(url);
      } catch (err) {
        console.error(
          `Error loading image info for ${imageData.filename}:`,
          err,
        );
        setImageUrl(null);
      }
    },
    [index, type, updateExample, loadDocumentInfo, example.evidence],
  );

  const handleInputTypeChange = useCallback(
    (newType) => {
      setInputType(newType);
      if (newType === "text" && isImageEvidence(example.evidence)) {
        const evidenceObject = {
          type: "text",
          text: "",
          name: JSON.parse(example.evidence)?.name || "Unnamed",
          creationDate:
            JSON.parse(example.evidence)?.creationDate ||
            new Date().toISOString(),
        };
        updateExample(index, "evidence", JSON.stringify(evidenceObject), type);
        setImageUrl(null);
      }
    },
    [example.evidence, index, isImageEvidence, type, updateExample],
  );

  const handleEvidenceUpdate = (field, value) => {
    let currentEvidence;
    try {
      currentEvidence = JSON.parse(example.evidence);
    } catch {
      // If parsing fails, initialize with default values
      currentEvidence = {
        type: "text",
        text: "",
        name: "Unnamed",
        creationDate: new Date().toISOString(),
      };
    }
    const updatedEvidence = {
      ...currentEvidence,
      [field]: value,
    };
    updateExample(index, "evidence", JSON.stringify(updatedEvidence), type);
  };

  const handleDeleteExample = async () => {
    const isConfirmed = await confirm(
      "Are you sure you want to delete this example?",
      "This action cannot be undone.",
    );
    if (isConfirmed) {
      toast.info({
        title: "Info",
        description: "Deleting example...",
      });
      deleteExample(index);

      // Update the current tag
      const updatedExamples =
        type === "examples"
          ? currentTag.examples.filter((_, i) => i !== index)
          : currentTag.neg_examples.filter((_, i) => i !== index);

      const updatedTag = {
        ...currentTag,
        [type]: updatedExamples,
      };

      setCurrentTag(updatedTag);

      // Auto-save the tag after deleting the example
      await saveTag(updatedTag, null, false);
    }
  };

  let parsedEvidence;
  try {
    parsedEvidence = JSON.parse(example.evidence);
  } catch {
    parsedEvidence = {
      type: "text",
      text: "",
      name: "Unnamed",
      creationDate: new Date().toISOString(),
    };
  }

  return (
    <div
      className={`mb-4 p-4 ${bgColor} border-l-4 ${borderColor} rounded-r-md relative`}
    >
      <div className="flex justify-between items-center mb-2">
        <div>
          <input
            type="text"
            value={parsedEvidence.name || ""}
            onChange={(e) => handleEvidenceUpdate("name", e.target.value)}
            placeholder="Example name"
            className="text-sm font-medium mb-1 p-1 border rounded"
          />
          <p className="text-xs text-gray-500">
            Created:{" "}
            {new Date(
              parsedEvidence.creationDate || Date.now(),
            ).toLocaleString()}
          </p>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={() => setIsExampleCollapsed(!isExampleCollapsed)}
            className="text-grey text-bold border-3 rounded-md hover:text-[#1a735e] p-2"
          >
            {isExampleCollapsed ? "Expand" : "Collapse"}
          </button>
          <button
            onClick={handleDeleteExample}
            className="text-gray-500 hover:text-gray-700 transition duration-300 p-2"
            aria-label="Delete example"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      </div>

      {!isExampleCollapsed && (
        <div className="flex flex-col gap-4">
          <div className="w-full">
            <label className="block text-sm font-medium text-gray-600 mb-2">
              Input Type
            </label>
            <div className="flex space-x-4 mb-4">
              <button
                onClick={() => handleInputTypeChange("text")}
                className={`px-4 py-2 rounded-md transition duration-300 ${
                  inputType === "text"
                  ? "bg-[rgba(40,165,144,1)] text-white"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  }`}
              >
                Text
              </button>
              <button
                onClick={() => handleInputTypeChange("image")}
                                className={`px-4 py-2 rounded-md transition duration-300 ${
                  inputType === "image"
                  ? "bg-[rgba(40,165,144,1)] text-white"
                  : "bg-gray-200 text-gray-700 hover:bg-gray-300"
                  }`}
              >
                Image
              </button>
            </div>

            {inputType === "text" ? (
              <>
                <label className="block text-sm font-medium text-gray-600 mb-1">
                  Input Text ({type === "examples" ? "Positive" : "Negative"}{" "}
                  Example)
                </label>
                <textarea
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary transition duration-300 resize-none"
                  value={parsedEvidence.text || ""}
                  onChange={(e) => handleEvidenceUpdate("text", e.target.value)}
                  placeholder={
                    type === "examples"
                      ? "E.g., The product arrived on time and in perfect condition."
                      : "E.g., The delivery was late and the package was damaged."
                  }
                  rows={3}
                />
              </>
            ) : (
              <>
                <label className="block text-sm font-medium text-gray-600 mb-1">
                  Input Image ({type === "examples" ? "Positive" : "Negative"}{" "}
                  Example)
                </label>
                {isImageEvidence(example.evidence) ? (
                  <div className="relative">
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="Selected input"
                        className="mt-2 max-w-full h-auto rounded-md object-cover cursor-pointer"
                        style={{ maxHeight: "200px", width: "auto" }}
                        onClick={() => {
                          setLightboxImage([{ src: imageUrl }]);
                          setIsLightboxOpen(true);
                        }}
                      />
                    ) : (
                      <p>Loading image...</p>
                    )}
                    <button
                      onClick={() => setShowImageSelector(true)}
                      className="absolute top-2 right-2 bg-white text-primary hover:text-[#1a735e] p-2 rounded-full shadow-md transition duration-300"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                      </svg>
                    </button>
                    {isLightboxOpen && (
                      <Lightbox
                        open={isLightboxOpen}
                        close={() => setIsLightboxOpen(false)}
                        slides={lightboxImage}
                      />
                    )}

                    <div className="mt-2">
                      <p className="text-sm text-gray-600">
                        <strong>Filename:</strong>{" "}
                        {parsedEvidence.text.split("~|~")[0]}
                      </p>
                      <p className="text-sm text-gray-600">
                        <strong>Path:</strong>{" "}
                        {parsedEvidence.text.split("~|~")[1]}
                      </p>
                      <p className="text-sm text-gray-600">
                        <strong>Storage:</strong>{" "}
                        {parsedEvidence.text.split("~|~")[2]}
                      </p>
                    </div>
                  </div>
                ) : (
                  <button
                    onClick={() => setShowImageSelector(true)}
                    className="mt-2 bg-gray-100 text-primary hover:bg-gray-200 hover:text-[#1a735e] transition duration-300 p-4 rounded-md w-full flex items-center justify-center"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 mr-2"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                      />
                    </svg>
                    Select Image
                  </button>
                )}
              </>
            )}
          </div>
          <div className="w-full">
            <label className="block text-sm font-medium text-gray-600 mb-1">
              Expected Output
            </label>
            <textarea
              className="w-full p-2 border border-gray-300 rounded-md focus:ring-primary focus:border-primary transition duration-300 resize-none"
              value={example.value}
              onChange={(e) =>
                updateExample(index, "value", e.target.value, type)
              }
              placeholder={
                type === "examples"
                  ? "E.g., Positive review"
                  : "E.g., Positive review"
              }
              rows={3}
            />
          </div>
        </div>
      )}
      {showImageSelector && (
        <ImageSelector
          onSelect={handleImageSelect}
          onClose={() => setShowImageSelector(false)}
        />
      )}
    </div>
  );
};

export default ExamplesSection;
