import Papa from "papaparse";
import { useState } from "react";
import { Disclosure, Transition } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";

export default function QuestionUploadSection({ questions, setQuestions }) {
  const [typedQuestions, setTypedQuestions] = useState("");
  const [csvData, setCsvData] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState("");
  const [isDataUploaded, setIsDataUploaded] = useState(false);

  const handleCsvUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      Papa.parse(file, {
        complete: (results) => {
          if (results.data && results.data.length > 0) {
            setCsvData(results.data);
            setIsDataUploaded(true);
          } else {
            console.error("CSV file is empty or invalid");
          }
        },
        header: true,
        error: (error) => {
          console.error("Error parsing CSV:", error);
        },
      });
    }
  };

  const handleColumnSelect = (e) => {
    setSelectedColumn(e.target.value);
  };

  const handleTypedQuestionsChange = (e) => {
    setTypedQuestions(e.target.value);
  };

  const addQuestion = () => {
    if (typedQuestions.trim()) {
      setQuestions((prevQuestions) => [
        ...prevQuestions,
        typedQuestions.trim(),
      ]);
      setTypedQuestions("");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      addQuestion();
    }
  };

  const handleConfirm = () => {
    if (csvData && csvData.length > 0 && selectedColumn) {
      const newQuestions = csvData
        .map((row) => row[selectedColumn])
        .filter((q) => q && typeof q === "string" && q.trim() !== "");

      const confirmQs = window.confirm(
        `Are you sure you want to add ${newQuestions.length} questions?`,
      );

      if (confirmQs) {
        setQuestions((prevQuestions) => [...prevQuestions, ...newQuestions]);
        setIsDataUploaded(false);
        setCsvData(null);
        setSelectedColumn("");
      }
    } else {
      addQuestion();
    }
  };

  const removeQuestion = (index) => {
    setQuestions((prevQuestions) =>
      prevQuestions.filter((_, i) => i !== index),
    );
  };

  const clearAllQuestions = () => {
    const confirmClear = window.confirm(
      "Are you sure you want to clear all questions?",
    );
    if (confirmClear) {
      setQuestions([]);
    }
  };

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <Disclosure.Button className=" flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-700 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500 focus-visible:ring-opacity-75">
            <span>2. Provide example questions (optional)</span>

            <p className="flex flex-row items-center justify-between gap-3">
              {questions.length > 0 && (
                <span className="text-primary font-bold">
                  {questions.length} Question{questions.length > 1 ? "s" : ""}{" "}
                  added
                </span>
              )}
              <ChevronUpIcon
                className={`${open ? "transform rotate-180" : ""} w-5 h-5 text-gray-500`}
              />
            </p>
          </Disclosure.Button>
          <Transition
            show={open}
            enter="transition duration-100 ease-out"
            enterFrom="transform scale-95 opacity-0"
            enterTo="transform scale-100 opacity-100"
            leave="transition duration-75 ease-out"
            leaveFrom="transform scale-100 opacity-100"
            leaveTo="transform scale-95 opacity-0"
          >
            <Disclosure.Panel className=" text-sm text-gray-500">
              <div className="flex space-x-4 border-2 border-gray-100 rounded-md p-2 w-full">
                <div className="w-1/2 space-y-4">
                  <div>
                    <label
                      htmlFor="csv-upload"
                      className="inline-block px-4 py-2 bg-primary text-white rounded cursor-pointer hover:bg-primary-dark"
                    >
                      Upload CSV
                    </label>
                    <input
                      id="csv-upload"
                      type="file"
                      accept=".csv"
                      onChange={handleCsvUpload}
                      className="hidden"
                    />
                  </div>
                  {csvData && csvData.length > 0 && (
                    <div>
                      <select
                        value={selectedColumn}
                        onChange={handleColumnSelect}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
                      >
                        <option value="">Select a column</option>
                        {Object.keys(csvData[0]).map((column) => (
                          <option key={column} value={column}>
                            {column}
                          </option>
                        ))}
                      </select>
                    </div>
                  )}
                  <div>
                    <p className="mb-2">Or</p>
                    <textarea
                      placeholder="Type questions"
                      value={typedQuestions}
                      onChange={handleTypedQuestionsChange}
                      onKeyPress={handleKeyPress}
                      className="w-full h-40 p-2 border rounded resize-none"
                    />
                  </div>
                  <button
                    onClick={handleConfirm}
                    className={`px-4 py-2 text-white rounded ${
                      isDataUploaded && selectedColumn
                        ? "bg-primary hover:bg-primary-dark"
                        : typedQuestions.trim()
                          ? "bg-primary hover:bg-primary-dark"
                          : "bg-gray-300 cursor-not-allowed"
                    }`}
                    disabled={
                      !(isDataUploaded && selectedColumn) &&
                      !typedQuestions.trim()
                    }
                  >
                    Add questions
                  </button>
                </div>
                <div className="w-1/2">
                  <div className="flex justify-between items-center mb-2">
                    <h3>Question preview ({questions.length})</h3>
                    <button
                      onClick={clearAllQuestions}
                      className="text-red-500 hover:text-red-700"
                    >
                      Clear All
                    </button>
                  </div>
                  <div className="h-64 overflow-y-auto border rounded p-2">
                    {questions.map((question, index) => (
                      <div
                        key={index}
                        className="flex justify-between items-center mb-2 bg-gray-100 p-2 rounded"
                      >
                        <span className="truncate mr-2">{question}</span>
                        <button
                          onClick={() => removeQuestion(index)}
                          className="text-red-500 hover:text-red-700"
                        >
                          x
                        </button>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  );
}
