import { useContext, useEffect, useMemo, useCallback } from "react";
import { EyeIcon, EyeOffIcon } from "@heroicons/react/solid";
import "./TagFilter.css";
import { DataContext } from "../../../context/DataContext";
import { TagContext } from "../../../context/TagContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faEdit } from "@fortawesome/free-solid-svg-icons";
import { CgSpinnerTwo } from "react-icons/cg";
import { selectedCatalogItemsAtom } from "../../../atoms";
import { useAtom } from "jotai";
import { PermissionGuard } from "../PermissionGuard";
import ProgressPie from "../TagFilter/PieChart";
import { FaGlobeAmericas, FaUser } from "react-icons/fa";

export default function TagFilter({
  label,
  categoryKey,
  isBeingDeleted,
  runSelectedTags,
  tagRules,
  completionPercentage,
}) {
  const {
    usedCatalog,
    deleteAllLabels,
    availableTags,
    currentDataGroup,
    hiddenCategories,
    toggleCategoryVisibility,
    ruleDict,
    clearLabels,
    getCatalogAndUpdateCatalog,
    userProfile,
  } = useContext(DataContext);
  const {
    relatedInfo,
    setRelatedInfo,
    processingTags,
    runSingleRule,
    editTag,
  } = useContext(TagContext);

  const [selectedCatalogItems] = useAtom(selectedCatalogItemsAtom);

  const isRuleTag = useCallback(
    (tagName) => tagRules?.some((rule) => rule.tagName === tagName),
    [tagRules],
  );

  const tagDict = useMemo(() => {
    return {
      ...availableTags.llm.tagger_params.tag_dict,
      ...ruleDict,
    };
  }, [availableTags.llm.tagger_params.tag_dict, ruleDict]);

  useEffect(() => {
    const calculateRelatedInfo = () => {
      let info = {};

      Object.entries(tagDict).forEach(([key, value]) => {
        let counter = 0;
        let name = value.name;
        let matchingNames = [];

        Object.entries(currentDataGroup).forEach(([groupKey, groupValue]) => {
          if (!groupValue.hasOwnProperty(name)) {
            counter++;
            matchingNames.push(groupKey);
          }
        });

        info[key] = { name, counter, matchingNames };
      });

      setRelatedInfo(info);
    };

    calculateRelatedInfo();
  }, [currentDataGroup, setRelatedInfo, tagDict]);


  const isGlobalTag =
    (tagDict[label]?.username !== userProfile.username &&
      tagDict[label]?.catalog_name === "Global") ||
    tagDict[label]?.is_global === true;
  const isUserLevelTag =
    (tagDict[label]?.username === userProfile.username &&
      tagDict[label]?.catalog_name === "Global") ||
    tagDict[label]?.is_user_level === true;

  return (
    <div
      className={` flex bg-white dark:border-b-zinc-600 dark:bg-zinc-800 dark:text-white transition-all duration-300 ease-in-out ${
        isBeingDeleted ? "opacity-50 pointer-events-none" : ""
      }`}
    >
      <div className="flex-1 w-full px-3">
        <div className="flex items-center">
          <div className="break-all text-xs font-bold text-gray-800">
            {label}
            {completionPercentage !== 100 &&
              processingTags.map(({ label }) => label).includes(label) && (
                <div className="inline-block ml-1 mr-2 animate-spin">
                  <CgSpinnerTwo />
                </div>
              )}
          </div>
          {isRuleTag(label) && (
            <div className="text-xs px-2 py-1 font-medium rounded-md text-white bg-primary ml-2">
              Tag rule attached
            </div>
          )}
        </div>
        <hr className="border-t border-gray-300 mt-1" />

        <p className="py-2 text-xs break-words">
          {tagDict[label]?.description}
        </p>

        <div className="flex flex-col gap-2">
          {tagDict[label]?.tagType === "rule" ? (
            <button
              onClick={() =>
                runSingleRule(currentDataGroup, tagDict[label].name)
              }
              className={`text-xs px-2 py-1 font-medium rounded-md text-white bg-primary transition-opacity duration-300 ${
                !relatedInfo?.[categoryKey]?.counter
                  ? "opacity-50"
                  : "opacity-100"
              }`}
            >
              Run rule on{" "}
              {selectedCatalogItems.size ||
                relatedInfo[categoryKey]?.counter ||
                0}{" "}
              documents
            </button>
          ) : (
            <div className="flex flex-row items-center w-full justify-between mt-2">
              <div className="flex-shrink-0">
                {isGlobalTag ? (
                  <FaGlobeAmericas
                    size={15}
                    className={`text-buttonGrey hover:text-primary duration-200`}
                    title="This is a global tag"
                  />
                ) : isUserLevelTag ? (
                  <FaUser
                    size={15}
                    className={`text-buttonGrey hover:text-primary duration-200`}
                    title="This is a user level tag"
                  />
                ) : null}
              </div>
              <div className="flex items-center gap-1">
                <PermissionGuard scope="tags" level="canEdit">
                  <>
                    {(!tagDict[label].tagged_at ||
                      new Date(tagDict[label].tagged_at) <
                        new Date(tagDict[label].updated_at)) && (
                      <div
                        className="px-2 py-1 border-2 text-xs text-grey p-1 rounded-md hover:text-white hover:bg-grey duration-200"
                        title="This tag has been changed since last usage and hence can be rerun to see updated results."
                        onClick={async (e) => {
                          e.stopPropagation();
                          await runSelectedTags(true, label);
                        }}
                      >
                        Rerun
                      </div>
                    )}
                    <button
                      onClick={(e) => {
                        clearLabels(e, [label]).then(() => {
                          getCatalogAndUpdateCatalog(usedCatalog);
                        });
                      }}
                      className="px-2 py-1 border-2 text-xs font-medium text-gray-700 bg-gray-200 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition duration-150 ease-in-out"
                      title="Clearing the tag will remove the values from all/selected documents without deleting the tag itself."
                      data-testid="tagClearLabel"
                    >
                      Clear
                    </button>
                    <button
                      className="p-2 text-xs bg-white text-buttonGrey hover:bg-primary hover:text-white rounded-md"
                      onClick={(e) => toggleCategoryVisibility(e, categoryKey)}
                      title={
                        hiddenCategories.includes(categoryKey)
                          ? "Unhide Category"
                          : "Hide Category"
                      }
                    >
                      {hiddenCategories.includes(categoryKey) ? (
                        <EyeOffIcon className="h-4 w-4" />
                      ) : (
                        <EyeIcon className="h-4 w-4" />
                      )}
                    </button>
                    {!isGlobalTag && (
                      <>
                        <button
                          className="p-2 text-sm bg-white text-buttonGrey hover:bg-primary hover:text-white rounded-md"
                          onClick={(e) => editTag(e, categoryKey)}
                          title="Edit Tag"
                        >
                          <FontAwesomeIcon icon={faEdit} />
                        </button>
                        <button
                          className="p-2 text-sm bg-white text-buttonGrey hover:bg-red-400 hover:text-white rounded-md"
                          title="Delete tag"
                          onClick={(e) => deleteAllLabels(e, [categoryKey])}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </>
                    )}
                  </>
                </PermissionGuard>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="absolute right-2 top-2 w-9 h-9">
        <ProgressPie percentage={completionPercentage} />
      </div>
    </div>
  );
}
