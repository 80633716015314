import { useContext, useEffect } from "react";
import "./Export.css";
import { sendRequest } from "../../../functions/api";
import { ENDPOINTS } from "../../../../../api/endpoints";
import { API_USERNAME_KEYWORD } from "../../../../../constants/fixedValues";
import Auth from "../../../../../auth/AuthProvider";
import { useCatalogNames } from "../../../../../api/queryHooks";
import { usePersistedState } from "../../../../../pages/hooks";
import { DataContext } from "../../../../../context/DataContext";

export default function Export() {
  const { data: catalogNames = [] } = useCatalogNames();
  const { usedCatalog, preferences } = useContext(DataContext);
  const qdrantConnectors = Object.fromEntries(
    Object.entries(preferences.webapp_profile.DATA_STORES || {}).filter(
      ([_, connector]) => connector.storage?.type === "qdrant"
    )
  );
  const [outputFormat, setOutputFormat] = usePersistedState("outputFormat", "");
  const [selectedConnector, setSelectedConnector] = usePersistedState("selectedConnector", "");
  const [outputCatalog, setOutputCatalog] = usePersistedState(
    "outputCatalog",
    "",
  );
  const [isExporting, setIsExporting] = usePersistedState(
    "isExporting",
    false,
  );

  useEffect(() => {
    if (!outputCatalog) {
      setOutputCatalog(usedCatalog);
    }
  }, [usedCatalog, outputCatalog, setOutputCatalog]);

  const handleOptionChange = (option) => {
    setOutputCatalog(option);
  };

  const handleFormatChange = (event) => {
    setOutputFormat(event.target.value);
  };

  const handleDownload = async () => {
    const creds = (await Auth.currentAuthenticatedUser()).username;
    const sendDetails = {
      qdrant_connection: selectedConnector ? qdrantConnectors[selectedConnector] : {},
      output_format: outputFormat,
      catalog_name: outputCatalog,
      [API_USERNAME_KEYWORD]: creds,
    };

    const response = await sendRequest(sendDetails, ENDPOINTS["export"]);
    if (outputFormat === "qdrant") return
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${outputCatalog}.${outputFormat}`;
    document.body.appendChild(a);
    a.click();
    a.remove();
    window.URL.revokeObjectURL(url);
  };

  const handleConfirm = async () => {
    if (!outputFormat || !catalogNames.includes(outputCatalog)) {
      window.confirm(
        "Please select an output format and a valid export catalog.",
      );
    } else {
      setIsExporting(true);
      await handleDownload();
      setIsExporting(false);
    }
  };

  return (
    <div className="flex w-[30vw] h-full flex-col bg-white p-6 rounded-lg shadow-md max-w-md mx-auto gap-4">
      <h3 className="text-xl font-semibold text-gray-800 mb-4">
        Select Export Options
      </h3>

      <div className="mb-6">
        <label
          htmlFor="catalog-select"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          Select Catalog
        </label>
        <div className="relative">
          <select
            id="catalog-select"
            value={outputCatalog}
            onChange={(event) => handleOptionChange(event.target.value)}
            className="block w-full pl-3 pr-10 py-2 text-base border focus:outline-none focus:ring-primary focus:border-primary rounded-md"
          >
            <option value="">Select Catalog</option>
            {catalogNames.map((catalog, index) => (
              <option key={index} value={catalog}>
                {catalog}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="mb-6">
        <label
          htmlFor="format-select"
          className="block text-sm font-medium text-gray-700 mb-2"
        >
          Select Output Format
        </label>
        <div className="relative">
          <select
            id="format-select"
            value={outputFormat}
            onChange={handleFormatChange}
            className="block w-full pl-3 pr-10 py-2 text-base border focus:outline-none focus:ring-primary focus:border-primary rounded-md"
          >
            <option value="">Select Format</option>
            <option value="csv">CSV</option>
            <option value="json">JSON</option>
            <option value="qdrant">Qdrant</option>
          </select>
        </div>
      </div>

      {outputFormat === "qdrant" && (
        <div className="mb-6">
          <label
            htmlFor="connector-select"
            className="block text-sm font-medium text-gray-700 mb-2"
          >
            Select Qdrant Connector
          </label>
          <div className="relative">
            <select
              id="connector-select"
              value={selectedConnector}
              onChange={(event) => setSelectedConnector(event.target.value)}
              className="block w-full pl-3 pr-10 py-2 text-base border focus:outline-none focus:ring-primary focus:border-primary rounded-md"
            >
              <option value="">Select Connector</option>
              {Object.entries(qdrantConnectors).map(([key, connector]) => (
                <option key={key} value={key}>
                  {connector.storage.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}

      <div
        onClick={handleConfirm}
        disabled={isExporting}
        className={`w-full ${
          isExporting
            ? 'bg-gray-400'
            : 'bg-primary hover:bg-primary-dark cursor-pointer hover:opacity-75'
        } text-white font-bold py-2 px-4 rounded-md transition duration-300 ease-in-out transform flex justify-center items-center`}
      >
        {isExporting ? (
          <span className="flex items-center">
            <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
            </svg>
            Exporting
          </span>
        ) : (
          'Export'
        )}
      </div>
    </div>
  );
}
