import React, { useRef, useEffect } from "react";
import "./Modal.css";
import { COLOURS } from "../../../assets/colours";
import { isImage } from "../functions/utils";

function Modal({
  isOpen,
  onClose,
  title,
  children,
  searchText = null,
  ...props
}) {
  const modalRef = useRef(null);

  useEffect(() => {
    function handleOutsideClick(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    }

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [onClose, searchText]);

  const handleRedact = (chunkIndex) => {
    const fileRedactions = props.redactions[props.fileName] || {};
    const newRedactions = {
      ...props.redactions,
      [props.fileName]: {
        ...fileRedactions,
        [chunkIndex]: !fileRedactions[chunkIndex],
      },
    };
    props.setRedactions(newRedactions);
  };

  const createHighlightedText = (corpus, searchList) => {
    if (!searchList || searchList.length === 0) return <>{corpus}</>;
    let highlightedCorpus = corpus;
    searchList.forEach(({ evidence, name, color }, index) => {
      const isRedacted = props.redactions[props.fileName]?.[index];
      if (!evidence || typeof evidence !== "string") return;

      const escapeRegex = (word) => word.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      const createFlexiblePattern = (text) => {
        const words = text.split(/\s+/).map(escapeRegex);
        return new RegExp(words.join("[^\\w]*"), "i");
      };

      const pattern = createFlexiblePattern(evidence);

      if (isRedacted) {
        highlightedCorpus = highlightedCorpus.replace(
          pattern,
          `<span class="redacted">[Sensitive Information]</span>`
        );
      } else {
        highlightedCorpus = highlightedCorpus.replace(
          pattern,
          (match) =>
            `<span id="highlight-${index}" class="highlight" style="background-color: ${color};">${match}</span>`
        );
      }
    });

    return <div dangerouslySetInnerHTML={{ __html: highlightedCorpus }} />;
  };

  const createInfoBoxes = (searchList) => {
    return (
      searchList &&
      searchList.map(({ name, color, reason }, chunkIndex) => {
        const isRedacted = props.redactions[props.fileName]?.[chunkIndex];
        return (
          <div
            key={chunkIndex}
            className="evidence-info"
            style={{ backgroundColor: color }}
          >
            <strong>{name}</strong> - {reason}
            <button
              onClick={() => handleRedact(chunkIndex)}
              style={{ backgroundColor: !isRedacted ? "#007bff" : "#8f2a34" }}
            >
              {isRedacted ? "Undo Redact" : "Redact"}
            </button>
          </div>
        );
      })
    );
  };

  const renderContent = () => {
    if (isImage(title)) {
      const imageUrl = `data:image/png;base64,${props.imageString}`;
      return (
        <img
          src={imageUrl}
          alt="Modal Content"
          className="max-w-full max-h-full object-contain block m-auto"
        />
      );
    } else {
      return createHighlightedText(children, searchText);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modalOverlay">
      <div className="modal" ref={modalRef}>
        <div className="modalHeader">
          <h4
            style={{
              color: COLOURS["SubText"],
              fontSize: "20px",
              maxWidth: "60vw",
              overflow: "auto",
              fontWeight: 500,
            }}
          >
            {title}
          </h4>
          <button className="closeButton" onClick={onClose}>
            X
          </button>
        </div>
        <div className="modalContent" style={{ height: 'calc(100% - 60px)' }}>
          <div
            className="modalInnerBody"
            style={{
              color: COLOURS["MainText"],
              height: '100%',
              overflowY: 'auto'
            }}
          >
            {renderContent()}
          </div>
          {searchText && (
            <div className="modalInfo">{createInfoBoxes(searchText)}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Modal;
