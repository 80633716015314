import { useState, useMemo, useEffect } from "react";
import DynamicTable from "./DynamicTableComponent";

const ALLOWED_KEYS = [
  "OPENAI_ENDPOINTS",
  "DEFAULT_CHUNKER",
  "CHUNK_SIZE",
  "CHUNK_OVERLAP",
  "SYSTEM_LEVEL_PROMPT",
];

const DEFAULT_CHUNKER_OPTIONS = ["page", "llm", "sentence"];

export function TagSettings({ activeTab, profileData, onDataChange }) {
  const [chunkingMethod, setChunkingMethod] = useState(
    profileData?.DEFAULT_CHUNKER,
  );
  const [chunkSize, setChunkSize] = useState(profileData?.CHUNK_SIZE);
  const [chunkOverlap, setChunkOverlap] = useState(profileData?.CHUNK_OVERLAP);
  const [systemLevelPrompt, setSystemLevelPrompt] = useState(
    profileData?.SYSTEM_LEVEL_PROMPT,
  );
  const [openaiEndpoints, setOpenaiEndpoints] = useState(
    profileData?.OPENAI_ENDPOINTS,
  );

  useEffect(() => {
    onDataChange({
      DEFAULT_CHUNKER: chunkingMethod,
      CHUNK_SIZE: parseInt(chunkSize, 10),
      CHUNK_OVERLAP: parseInt(chunkOverlap, 10),
      OPENAI_ENDPOINTS: openaiEndpoints,
      SYSTEM_LEVEL_PROMPT: systemLevelPrompt,
    });
  }, [
    chunkingMethod,
    chunkSize,
    chunkOverlap,
    openaiEndpoints,
    systemLevelPrompt,
  ]);

  const renderInputs = useMemo(() => {
    return ALLOWED_KEYS.map((key) => {
      switch (key) {
        case "OPENAI_ENDPOINTS":
          return (
            <div key={key} className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                LLM Endpoints
              </label>
              <DynamicTable
                initialData={openaiEndpoints}
                onDataChange={setOpenaiEndpoints}
              />
            </div>
          );
        case "DEFAULT_CHUNKER":
          return (
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                Chunking Method
              </label>
              <select
                value={chunkingMethod}
                onChange={(e) => setChunkingMethod(e.target.value)}
                className="w-full px-4 py-2 border-2 border-gray-300 rounded-lg transition duration-200 ease-in-out"
              >
                {DEFAULT_CHUNKER_OPTIONS.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          );
        case "CHUNK_SIZE":
          return (
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                {chunkingMethod === "page"
                  ? "Chunk Size (by pages)"
                  : "Chunk Size (by tokens)"}
              </label>
              <input
                value={chunkSize}
                onChange={(e) => setChunkSize(Number(e.target.value))}
                className="w-full px-4 py-2 border-2 border-gray-300 rounded-lg transition duration-200 ease-in-out"
              />
            </div>
          );
        case "CHUNK_OVERLAP":
          return (
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                Chunk Overlap (by tokens)
              </label>
              <input
                value={chunkOverlap}
                onChange={(e) => setChunkOverlap(Number(e.target.value))}
                className="w-full px-4 py-2 border-2 border-gray-300 rounded-lg transition duration-200 ease-in-out"
              />
            </div>
          );
        case "SYSTEM_LEVEL_PROMPT":
          return (
            <div className="mb-4">
              <label className="block text-gray-700 font-medium mb-2">
                System Level Prompt
              </label>
              <textarea
                value={systemLevelPrompt}
                onChange={(e) => setSystemLevelPrompt(e.target.value)}
                className="w-full px-4 py-2 border-2 border-gray-300 rounded-lg transition duration-200 ease-in-out"
              />
            </div>
          );
        default:
          return null;
      }
    });
  }, [
    chunkingMethod,
    chunkSize,
    chunkOverlap,
    openaiEndpoints,
    systemLevelPrompt,
  ]);

  return (
    <div className="mb-8 p-6 bg-white rounded-xl shadow-lg border-2 border-gray-200 transition-colors duration-300">
      {renderInputs}
    </div>
  );
}
