import { useState, useContext, useEffect, useMemo } from "react";
import { TagContext } from "../../../../../context/TagContext";
import { DataContext } from "../../../../../context/DataContext";
import Auth from "../../../../../auth/AuthProvider";
import { API_USERNAME_KEYWORD } from "../../../../../constants/fixedValues";
import { ENDPOINTS } from "../../../../../api/endpoints";
import { sendRequest } from "../../../../utilities/functions/api";
import GeneralSection from "./TagEditorComponents/GeneralSection";
import AdvancedSection from "./TagEditorComponents/AdvancedSection";
import ExamplesSection from "./TagEditorComponents/ExamplesSection";
import SimpleModal from "./TagEditorComponents/SimpleModal";

const TagEditor = ({
  addExamplePair,
  addNegExamplePair,
  updateExample,
  deleteExamplePair,
  deleteNegExamplePair,
  handleChangeOption,
  handleAddValue,
  handleDeleteValue,
  currentValue,
  setCurrentValue,
  handleRiskLevelChange,
  options,
}) => {
  const { defaultTagTypes, defaultOutputTypes, activeTab } = useContext(TagContext);
  const {
    validatingTag,
    validatedScores,
    setValidatingTag,
    setValidatedScores,
    usedCatalog,
    preferences,
    currentTag,
    setCurrentTag,
    availableTags,
  } = useContext(DataContext);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [activeSection, setActiveSection] = useState("general");
  const [showDescriptionModal, setShowDescriptionModal] = useState(false);
  const [generatedDescription, setGeneratedDescription] = useState("");
  const [examplesKey, setExamplesKey] = useState(0);

  // Force re-render when activeTab changes
  useEffect(() => {
    if (activeTab) {
      setActiveSection("general");
      if (activeTab === "examples") {
        setExamplesKey((prevKey) => prevKey + 1);
      }
    } else {
      setActiveSection("general");
    }
  }, [activeTab]);

  const allGroups = useMemo(() => {
    const allTags = {
      ...availableTags.llm.tagger_params.tag_dict,
      ...availableTags.sensitivity.tagger_params.tag_dict,
    };
    const groups = [
      ...new Set(
        Object.values(allTags)
          .map((tag) => tag.group)
          .filter(Boolean),
      ),
    ];

    return groups.sort((a, b) => a.localeCompare(b));
  }, [availableTags]);

  const fetchValidationRecords = async () => {
    const creds = (await Auth.currentAuthenticatedUser()).username;
    const sendDetails = {
      [API_USERNAME_KEYWORD]: creds,
      catalog_name: usedCatalog,
      tag_uuid: currentTag.tag_uuid,
      version_uuid: currentTag.version_uuid,
    };
    try {
      const response = await sendRequest(
        sendDetails,
        ENDPOINTS["get_validation_records"],
      );
      if (!response.ok) {
        console.error("Failed to fetch validation records:", response);
        return { folderKeys: [], validation_records: {} };
      }
      const data = await response.json();
      const validation_records = data.validation_records || {};
      return validation_records;
    } catch (error) {
      console.error("Error fetching validation records:", error);
    }
  };

  const showModal = (message) => {
    setModalMessage(message);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  const calculateValidationScore = async () => {
    setValidatingTag(currentTag.version_uuid);
    try {
      const records = await fetchValidationRecords();
      let totalCorrect = 0;
      let totalRecords = Object.keys(records).length;

      for (const key in records) {
        const record = records[key];
        if (record.label === "correct") {
          totalCorrect++;
        }
      }
      let validationScore;
      if (totalRecords === 0) {
        validationScore = null;
      } else {
        validationScore =
          totalRecords > 0 ? (totalCorrect / totalRecords) * 100 : 0;
      }
      setValidatedScores((prevScores) => ({
        ...prevScores,
        [currentTag.version_uuid]: validationScore,
      }));
    } catch (error) {
      console.error("Error during tag evaluation:", error);
    } finally {
      setValidatingTag(null);
    }
  };
  useEffect(() => {
    if (currentTag && !validatedScores[currentTag.version_uuid]) {
      calculateValidationScore();
    }

    if (!currentTag.tagType) {
      setCurrentTag((prevTag) => ({
        ...prevTag,
        tagType: defaultTagTypes["classification"],
      }));
    }

    if (!currentTag.type) {
      setCurrentTag((prevTag) => ({
        ...prevTag,
        type: defaultOutputTypes["word"],
      }));
    }
    return () => {};
  }, [currentTag.name, currentTag.version_uuid]);

  const generateTagDescription = async () => {
    try {
      const creds = (await Auth.currentAuthenticatedUser()).username;

      const sendDetails = {
        tag_name: currentTag.name,
        tag_description: currentTag.description,
        tag_type: currentTag.tagType,
        output_type: currentTag.type,
        examples: currentTag.examples,
        available_values: currentTag.availableValues,
        [API_USERNAME_KEYWORD]: creds,
      };

      const response = await sendRequest(
        sendDetails,
        ENDPOINTS["suggest_tag_description"],
      );
      if (!response.ok) {
        throw new Error("Failed to generate tag description.");
      }
      const responseData = await response.json();

      setGeneratedDescription(responseData.tag_description);
      setShowDescriptionModal(true);
    } catch (error) {
      console.error("Error generating tag description:", error);
    }
  };

  return (
    <>
      <SimpleModal
        isOpen={isModalVisible}
        onClose={closeModal}
        message={modalMessage}
      />
      {showDescriptionModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-4 rounded-lg shadow-lg w-1/3">
            <p
              className="text-lg"
              dangerouslySetInnerHTML={{ __html: generatedDescription }}
            ></p>
            <button
              className="mt-4 bg-primary hover:bg-deasieTurquoise text-white font-bold py-2 px-4 rounded"
              onClick={() => {
                setCurrentTag((prevTag) => ({
                  ...prevTag,
                  description: generatedDescription,
                }));
                setShowDescriptionModal(false);
              }}
            >
              Accept
            </button>
            <button
              className="mt-4 ml-2 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
              onClick={() => setShowDescriptionModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
      <div className="flex bg-white rounded-t-lg border-b border-gray-200 w-[100%] pt-2">
        <button
          className={`flex-1 py-2 px-4 text-sm font-medium text-center text-gray-500 hover:text-gray-700 focus:outline-none  ${
            activeSection === "general"
              ? "border-b-2 border-primary text-primary"
              : "border-transparent"
          }`}
          onClick={() => setActiveSection("general")}
        >
          General
        </button>
        <button
          className={`flex-1 py-2 px-4 text-sm font-medium text-center text-gray-500 hover:text-gray-700 focus:outline-none ${
            activeSection === "advanced"
              ? "border-b-2 border-primary text-primary"
              : "border-transparent"
          }`}
          onClick={() => setActiveSection("advanced")}
        >
          Model Selection
        </button>
        <button
          className={`flex-1 py-2 px-4 text-sm font-medium text-center text-gray-500 hover:text-gray-700 focus:outline-none ${
            activeSection === "examples"
              ? "border-b-2 border-primary text-primary"
              : "border-transparent"
          }`}
          onClick={() => setActiveSection("examples")}
        >
          Training Examples
        </button>
      </div>

      {activeSection === "advanced" ? (
        <AdvancedSection
          preferences={preferences}
          currentTag={currentTag}
          setCurrentTag={setCurrentTag}
        />
      ) : activeSection === "examples" ? (
        <ExamplesSection
          key={examplesKey}
          currentTag={currentTag}
          activeTab={activeTab}
          addExamplePair={addExamplePair}
          addNegExamplePair={addNegExamplePair}
          updateExample={updateExample}
          deleteExamplePair={deleteExamplePair}
          deleteNegExamplePair={deleteNegExamplePair}
        />
      ) : (
        <GeneralSection
          currentTag={currentTag}
          setCurrentTag={setCurrentTag}
          handleChangeOption={handleChangeOption}
          handleAddValue={handleAddValue}
          handleDeleteValue={handleDeleteValue}
          currentValue={currentValue}
          setCurrentValue={setCurrentValue}
          handleRiskLevelChange={handleRiskLevelChange}
          activeTab={activeTab}
          addExamplePair={addExamplePair}
          addNegExamplePair={addNegExamplePair}
          updateExample={updateExample}
          deleteExamplePair={deleteExamplePair}
          deleteNegExamplePair={deleteNegExamplePair}
          validatingTag={validatingTag}
          validatedScores={validatedScores}
          allGroups={allGroups}
          generateTagDescription={generateTagDescription}
          showModal={showModal}
          options={options}
        />
      )}
    </>
  );
};

export default TagEditor;
