import React from "react";
import { CustomTooltipProps } from "./types";
export const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
}) => {
  if (active && payload && payload.length) {
    const data = payload[0].payload;

    const formatNumber = (value: any) => {
      let numberValue = Number(value);

      if (Number.isFinite(numberValue)) {
        return numberValue.toLocaleString();
      } else {
        console.error(
          "Expected a number or a numeric string, but received:",
          value,
        );
        return `0`;
      }
    };

    type AccumulatorType = {
      promptTokens: number;
      completionTokens: number;
      totalTokens: number;
    };

    const totals = Object.values(data.models).reduce(
      (acc: AccumulatorType, model: any) => {
        acc.promptTokens += Number(model.prompt_tokens);
        acc.completionTokens += Number(model.completion_tokens);
        acc.totalTokens += Number(model.total_tokens);
        return acc;
      },
      {
        promptTokens: 0,
        completionTokens: 0,
        totalTokens: 0,
      } as AccumulatorType,
    );
    if (label !== "Total Tokens") {
      return (
        <div className="bg-white p-2 border border-gray-300 shadow-lg rounded">
          <p className="font-medium text-base">{`Endpoint: ${label}`}</p>
          <table className="min-w-full divide-y divide-gray-200 mt-2">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Model
                </th>
                <th className="px-2 py-1 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Prompt Tokens
                </th>
                <th className="px-2 py-1 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Completion Tokens
                </th>
                <th className="px-2 py-1 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total Tokens
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {Object.entries(data.models).map(
                ([modelName, model]: [string, any]) => (
                  <tr key={modelName}>
                    <td className="px-2 py-1 whitespace-nowrap text-sm font-medium text-gray-900">
                      {model.name}
                    </td>
                    <td className="px-2 py-1 whitespace-nowrap text-sm text-right text-gray-500">
                      {formatNumber(model.prompt_tokens)}
                    </td>
                    <td className="px-2 py-1 whitespace-nowrap text-sm text-right text-gray-500">
                      {formatNumber(model.completion_tokens)}
                    </td>
                    <td className="px-2 py-1 whitespace-nowrap text-sm text-right text-gray-500">
                      {formatNumber(model.total_tokens)}
                    </td>
                  </tr>
                ),
              )}
              <tr className="bg-gray-100">
                <td className="px-2 py-1 font-medium text-gray-800">Totals</td>
                <td className="px-2 py-1 text-right text-gray-800">
                  {formatNumber(totals.promptTokens)}
                </td>
                <td className="px-2 py-1 text-right text-gray-800">
                  {formatNumber(totals.completionTokens)}
                </td>
                <td className="px-2 py-1 text-right text-gray-800">
                  {formatNumber(totals.totalTokens)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    } else {
      return (
        <div className="bg-white p-2 border border-gray-300 shadow-lg rounded">
          <p className="font-medium text-base">Total Token Usage</p>
          <table className="min-w-full divide-y divide-gray-200 mt-2">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-2 py-1 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Used Tokens
                </th>
                <th className="px-2 py-1 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Available Tokens
                </th>
                <th className="px-2 py-1 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Total Tokens
                </th>
                <th className="px-2 py-1 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Percent Remaining
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              <tr className="bg-gray-100 font-medium">
                <td className="px-2 py-1 text-right text-gray-800">
                  {formatNumber(data.tokensUsed)}
                </td>
                <td className="px-2 py-1 text-right text-gray-800">
                  {formatNumber(data.tokensLeft)}
                </td>
                <td className="px-2 py-1 text-right text-gray-800">
                  {formatNumber(data.totalTokens)}
                </td>
                <td className="px-2 py-1 text-right text-gray-800">
                  {((data.tokensLeft / data.totalTokens) * 100).toFixed(2)}%
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    }
  }
  return null;
};
