import { ENDPOINTS } from "../api/endpoints";
import { sendRequest } from "../components/utilities/functions/api";
import { toast } from "../components/utilities/Toast";

export const waitMultiTaskDone = (
  task_ids: string[],
  username: string,
  intervalInSeconds = 2,
  onInterval?: ({
    status,
    completed,
  }: {
    status: "COMPLETED" | "PENDING" | "FAILED" | "quota_exceeded" | "STARTED";
    completed: number;
  }) => {},
) => {
  return new Promise((resolve, reject) => {
    let timeoutId = 0;
    const taskStatuses = new Map<
      string,
      { status: string; completed: number }
    >();

    // Initialize taskStatuses with default values
    task_ids.forEach((task_id) => {
      taskStatuses.set(task_id, { status: "PENDING", completed: 0 });
    });

    const timeoutHandler = async () => {
      try {
        const statusPromises = task_ids.map((task_id) =>
          sendRequest(
            { username },
            `${ENDPOINTS["get_task_status"]}${task_id}`,
            "GET",
          )
            .then((res) => {
              if (res.status !== 200) {
                return { status: "COMPLETED", completed: 1 };
              }
              return res.json();
            })
            .then(({ status, completed }) => {
              taskStatuses.set(task_id, { status, completed });
            })
            .catch((error) => {
              console.error(`Error fetching status for TASK ${task_id}:`, error);
              // Assume task is completed if there's an error
              taskStatuses.set(task_id, { status: "COMPLETED", completed: 1 });
            }),
        );

        await Promise.all(statusPromises);

        // Aggregate status and completion
        let totalCompletionPercentage = 0;
        let allCompleted = true;
        let anyFailed = false;
        let quotaExceeded = false;

        taskStatuses.forEach(({ status, completed }) => {
          totalCompletionPercentage += completed;

          if (status === "FAILED") {
            anyFailed = true;
          }

          if (status === "quota_exceeded") {
            quotaExceeded = true;
          }

          if (status === "PENDING" || status === "STARTED") {
            allCompleted = false;
          }
        });

        const overallCompletion = totalCompletionPercentage / task_ids.length;

        if (quotaExceeded) {
          toast.error({
            title: "Token Limit Exceeded",
            description: "Please contact your admin.",
          });
          resolve(null);
          return;
        }

        // Determine overall status
        let overallStatus: "COMPLETED" | "PENDING" | "FAILED" | "STARTED";
        if (anyFailed) {
          overallStatus = "FAILED";
        } else if (allCompleted) {
          overallStatus = "COMPLETED";
        } else {
          overallStatus = "PENDING";
        }

        onInterval?.({ status: overallStatus, completed: overallCompletion });

        if (allCompleted || anyFailed || quotaExceeded) {
          resolve(null);
        } else {
          timeoutId = window.setTimeout(
            timeoutHandler,
            intervalInSeconds * 1000,
          );
        }
      } catch (error) {
        console.error('Error in timeoutHandler:', error);
      }
    };

    timeoutId = window.setTimeout(timeoutHandler, intervalInSeconds * 1000);
  });
};

export const waitTaskDone = (
  task_id: string,
  username: string,
  intervalInSeconds = 2,
  onInterval?: ({
    status,
    completed,
  }: {
    status: "COMPLETED" | "PENDING" | "FAILED" | "quota_exceeded" | "STARTED";
    completed: number;
  }) => {},
) => {
  return new Promise((resolve, reject) => {
    let timeoutId = 0;
    const timeoutHandler = async () => {
      sendRequest(
        { username },
        `${ENDPOINTS["get_task_status"]}${task_id}`,
        "GET",
      )
        .then((res) => {
          if (res.status !== 200) {
            throw new Error();
          }
          return res.json();
        })
        .then(({ status, completed }) => {
          onInterval?.({ status, completed });
          if (status === "quota_exceeded") {
            toast.error({
              title: "Token Limit Exceeded",
              description: "Please contact your admin.",
            });
            resolve(null);
            return;
          }
          if (status !== "PENDING" && status !== "STARTED") {
            resolve(null);
          }
          timeoutId = window.setTimeout(
            timeoutHandler,
            intervalInSeconds * 1000,
          );
        })
        .catch(() => {
          window.clearTimeout(timeoutId);
          resolve(null);
        });
    };
    timeoutId = window.setTimeout(timeoutHandler, intervalInSeconds * 1000);
  });
};

export const abortTask = async (
  task_id: string,
  username: string,
  error_toast_message: string | undefined = undefined,
  abort_toast_message: string | undefined = undefined,
) => {
  try {
    console.log("aborting task");
    console.log(task_id);
    console.log(username);
    const response = await sendRequest(
      { username, task_id },
      ENDPOINTS["revoke_task"],
      "POST",
    );
    if (abort_toast_message !== undefined && abort_toast_message !== "") {
      toast.info({
        title: "Aborted",
        description: abort_toast_message,
      });
    }
    return response;
  } catch (error) {
    console.error("Error aborting the task: ", task_id, "error: ", error);
    if (error_toast_message !== undefined && error_toast_message !== "") {
      toast.error({
        title: "Error",
        description: error_toast_message,
      });
    }
    return;
  }
};
