// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EvidenceReportsModal {
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    border-radius: 4px;
    padding: 10px;
    height: 80vh;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.LoadingIndicator {
    align-items: center;
    justify-content: center;
}

.EvidenceReports table {
  width: 100%;
  border-collapse: collapse;
}

.EvidenceReports tbody tr:nth-child(odd) {
  background-color: #f8f9fa;
}
.EvidenceReports tbody > tr > td {
  padding: 5px;
  border: 1px solid #dee2e6;
  text-align: left;
  vertical-align: top;
}

.EvidenceReports thead > tr > td {
  padding: 5px;
  border: 1px solid #dee2e6;
  font-weight: bold;
}

.EvidenceReports tbody .centered {
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/components/utilities/EvidenceReport/styles.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,SAAS;AACb;AACA;IACI,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;EACE,WAAW;EACX,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,YAAY;EACZ,yBAAyB;EACzB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,yBAAyB;EACzB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".EvidenceReportsModal {\n    background-color: white;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    width: 80vw;\n    border-radius: 4px;\n    padding: 10px;\n    height: 80vh;\n    display: flex;\n    flex-direction: column;\n    gap: 10px;\n}\n.LoadingIndicator {\n    align-items: center;\n    justify-content: center;\n}\n\n.EvidenceReports table {\n  width: 100%;\n  border-collapse: collapse;\n}\n\n.EvidenceReports tbody tr:nth-child(odd) {\n  background-color: #f8f9fa;\n}\n.EvidenceReports tbody > tr > td {\n  padding: 5px;\n  border: 1px solid #dee2e6;\n  text-align: left;\n  vertical-align: top;\n}\n\n.EvidenceReports thead > tr > td {\n  padding: 5px;\n  border: 1px solid #dee2e6;\n  font-weight: bold;\n}\n\n.EvidenceReports tbody .centered {\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
