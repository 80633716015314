import { useMemo, useContext, useEffect, useState, useRef } from "react";
import { DataContext } from "../../../../../context/DataContext";
import {
  deleteTagRules,
  getTagRules,
  uploadTagRules,
} from "../../../../utilities/functions/apiCalls";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckIcon } from "@heroicons/react/solid";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { toast } from "../../../../utilities/Toast";
import { PermissionGuard } from "../../../../utilities/PermissionGuard";
import { faTrashAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import { components } from "react-select";
import { PlusIcon, MinusIcon } from "@heroicons/react/solid";

const tagOptionsMap = {
  aiGenerated: "Open ended",
  yesNo: "Yes or No",
  custom: "Defined values",
};

const CustomSingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      {props.data.label}
    </components.SingleValue>
  );
};

const CustomOption = (props) => {
  const { data, innerRef, innerProps } = props;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={`cursor-pointer select-none relative py-2 pl-10 hover:text-white hover:bg-primary pr-4 ${
        props.isSelected ? "bg-primary text-white" : "text-gray-900"
      }`}
    >
      <span
        className={`block truncate ${props.isSelected ? "font-medium" : "font-normal"}`}
      >
        {data.label}
        {data.hasRules && (
          <CheckIcon className="inline-block w-4 h-4 ml-2 text-green-500" />
        )}
      </span>
    </div>
  );
};

const TagSelect = ({
  availableTags,
  allRules,
  selectedTag,
  setSelectedTag,
}) => {
  const [filter, setFilter] = useState("");

  const tagOptions = Object.values(availableTags.llm.tagger_params.tag_dict)
    .filter((tag) => tag.availableValues.length > 0)
    .map((tag) => ({
      value: tag.name,
      label: tag.name,
      hasRules: allRules[tag.name] && allRules[tag.name].length > 0,
    }));

  const filteredOptions = tagOptions.filter((tag) =>
    tag.label.toLowerCase().includes(filter.toLowerCase()),
  );

  return (
    <div className="flex flex-col w-full justify-between mb-6 bg-gray-100 p-4 rounded-lg">
      <h2 className="text-xl font-semibold text-gray-800 mb-2">
        {!selectedTag ? "Select a Tag" : `Configuring: ${selectedTag}`}
      </h2>
      <p className="text-sm text-gray-600 mb-3">
        {!selectedTag
          ? "Select a tag to see or define its rules."
          : "Configure rules for the selected tag."}
      </p>
      <div className="relative w-full md:w-1/2 lg:w-1/3">
        <Select
          className="basic-single"
          classNamePrefix="select"
          options={filteredOptions}
          value={filteredOptions.find((option) => option.value === selectedTag)}
          onChange={(selectedOption) =>
            setSelectedTag(selectedOption ? selectedOption.value : "")
          }
          placeholder="Select a tag"
          components={{
            SingleValue: CustomSingleValue,
            Option: CustomOption,
          }}
          onInputChange={(inputValue) => setFilter(inputValue)}
          styles={{
            control: (provided) => ({
              ...provided,
              height: 40,
              minHeight: 40,
              borderColor: "#d1d5db",
              boxShadow: "none",
              "&:hover": {
                borderColor: "#9ca3af",
              },
            }),
            placeholder: (provided) => ({
              ...provided,
              margin: "0 8px",
            }),
          }}
        />
      </div>
    </div>
  );
};

const TreeNode = ({ node, onAddChild, onRemove, depth = 0 }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  return (
    <div className="flex flex-col items-center">
      <div className="flex flex-col items-center mb-4">
        <div className="flex items-center bg-white border-2 border-gray-200 rounded-lg shadow-md p-4 mb-2 transition-all duration-200 hover:shadow-lg hover:border-primary">
          <span className="font-semibold mr-3 text-gray-700">
            {node.tagName}:
          </span>
          <span className="text-gray-600 bg-gray-100 px-2 py-1 rounded">
            {node.conditionValue}
          </span>
          {!node.treeEnd && (
            <button
              onClick={() => onAddChild(node)}
              className="ml-3 bg-green-500 hover:bg-green-600 text-white rounded-full p-1.5 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-green-400"
            >
              <PlusIcon className="h-4 w-4" />
            </button>
          )}
          {depth > 0 && (
            <button
              onClick={() => onRemove(node)}
              className="ml-2 bg-red-500 hover:bg-red-600 text-white rounded-full p-1.5 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-red-400"
            >
              <MinusIcon className="h-4 w-4" />
            </button>
          )}
        </div>
        {node.children && node.children.length > 0 && (
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-gray-500 hover:text-gray-700"
          >
            {isExpanded ? "▼" : "►"}
          </button>
        )}
      </div>
      {isExpanded && node.children && node.children.length > 0 && (
        <div className="flex items-center">
          <div className="flex flex-col items-center">
            <div className="h-8 border-l-2 border-gray-300"></div>
            <div className="flex gap-4">
              {node.children.map((child, index) => (
                <div key={index} className="flex flex-col items-center">
                  <div className="w-32 border-t-2 border-gray-300"></div>
                  <TreeNode
                    node={child}
                    onAddChild={onAddChild}
                    onRemove={onRemove}
                    depth={depth + 1}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default function AddTaggingRules() {
  const { usedCatalog, availableTags, setView, showConnectData } =
    useContext(DataContext);

  const [selectedTag, setSelectedTag] = useState("");
  const [tableData, setTableData] = useState([]);
  const [allRules, setAllRules] = useState({});
  const [conditionFilter, setConditionFilter] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [editingRowIndex, setEditingRowIndex] = useState(null);
  const dropdownRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [existingRules, setExistingRules] = useState([]);
  const [showNewRuleForm, setShowNewRuleForm] = useState(false);

  const disabledClass = selectedTag ? "" : "opacity-20 pointer-events-none";

  const tagAvailableValues = useMemo(() => {
    const tags = Object.values(availableTags).reduce(
      (acc, value) => ({
        ...acc,
        ...Object.entries(value.tagger_params?.tag_dict || {}).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value.availableValues || [],
          }),
          {},
        ),
      }),
      {},
    );
    return tags;
  }, [availableTags]);

  useEffect(() => {
    const constructRuleTree = (rule, depth, ancestorTagsName) => {
      const parentRuleTree = {
        conditionValue: rule.conditionValue,
        tagName: rule.tagName,
        ancestorTagsName: ancestorTagsName.concat(rule.tagName),
        children: [],
        depth: depth,
        treeEnd: rule.conditionValue === "" ? true : false,
      };
      rule.children.forEach((childRule) => {
        const childRuleTree = constructRuleTree(
          childRule,
          depth + 1,
          ancestorTagsName.concat(rule.tagName),
        );
        childRuleTree.parentTag = parentRuleTree;
        parentRuleTree.children.push(childRuleTree);
      });
      return parentRuleTree;
    };

    const addDataToTableInit = (tableInit, rootRule) => {
      tableInit.push(rootRule);
      rootRule.children.forEach((childRule) => {
        addDataToTableInit(tableInit, childRule);
      });
    };

    const fetchTagRules = async () => {
      if (selectedTag === "") {
        return;
      }
      let tableInit = [];
      const tagRules = await getTagRules(usedCatalog);
      let rootRules = [];

      if (tagRules) {
        tagRules.forEach((rule) => {
          if (rule.tagName === selectedTag) {
            const ruleTree = constructRuleTree(rule, 0, []);
            rootRules.push(ruleTree);
          }
        });
        rootRules.forEach((rootRule) => {
          addDataToTableInit(tableInit, rootRule);
        });

        if (tableInit.length === 0) {
          const specificRules = allRules[selectedTag] || [];
          const initialValues = tagAvailableValues[selectedTag] || [];
          tableInit =
            specificRules.length > 0
              ? specificRules.map((rule) => ({
                  conditionValue: rule.conditions[0].value,
                  tagName: rule.conditions[0].tag,
                  ancestorTagsName: [rule.conditions[0].tag],
                  children: [],
                  depth: 0,
                }))
              : initialValues.map((value) => ({
                  conditionValue: value,
                  tagName: selectedTag,
                  ancestorTagsName: [selectedTag],
                  children: [],
                  depth: 0,
                }));
        }

        const filteredTableInit = tableInit.filter((row) => {
          return (
            typeof row.conditionValue === "string" &&
            row.conditionValue
              .toLowerCase()
              .includes(conditionFilter.toLowerCase())
          );
        });

        setTableData(filteredTableInit);
      }
    };

    fetchTagRules();
  }, [
    selectedTag,
    allRules,
    conditionFilter,
    tagAvailableValues,
    usedCatalog,
    availableTags,
  ]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleSkip = () => {
    if (selectedTag) {
      saveRules();
    }
    setView("tagSelection");
  };

  const addTagToRow = (parentTag, childTag, parentRowIndex) => {
    const updatedData = [...tableData];
    const parentRow = updatedData[parentRowIndex];
    if (!parentRow.ancestorTagsName.includes(childTag.name)) {
      const newChildren = [];
      if (childTag.availableValues.length > 0) {
        childTag.availableValues.forEach((value) => {
          newChildren.push({
            conditionValue: value,
            ancestorTagsName: parentTag.ancestorTagsName.concat(childTag.name),
            tagName: childTag.name,
            children: [],
            parentTag: parentTag,
            depth: parentTag.depth + 1,
            treeEnd: false,
          });
        });
      } else {
        newChildren.push({
          conditionValue: "",
          ancestorTagsName: parentTag.ancestorTagsName.concat(childTag.name),
          tagName: childTag.name,
          children: [],
          parentTag: parentTag,
          depth: parentTag.depth + 1,
          treeEnd: true,
        });
      }
      parentTag.children.push(...newChildren);
      updatedData.splice(parentRowIndex + 1, 0, ...newChildren);
      setTableData(updatedData);
    }
  };

  const removeTagFromRow = (tagToDelete, rowIndex) => {
    const updatedData = [...tableData];
    let deleteCount = 1;
    // delete from table data
    for (let i = rowIndex + 1; i < updatedData.length; i++) {
      if (updatedData[i].depth > tagToDelete.depth) {
        deleteCount++;
      } else {
        break;
      }
    }
    updatedData.splice(rowIndex, deleteCount);
    // delete from parent.children
    if (tagToDelete.parentTag !== undefined) {
      for (let i = 0; i < tagToDelete.parentTag.children.length; i++) {
        if (
          tagToDelete.parentTag.children[i].conditionValue ===
          tagToDelete.conditionValue
        ) {
          tagToDelete.parentTag.children.splice(i, 1);
          break;
        }
      }
    }
    setTableData(updatedData);
  };

  const getHierarchicalRulesForSelectedTag = () => {
    const rulesForSelectedTag = [];
    tableData.forEach((tagRule, index) => {
      if (tagRule.depth === 0) {
        rulesForSelectedTag.push(tagRule);
      }
    });
    return rulesForSelectedTag;
  };

  const saveRules = async () => {
    if (selectedTag === "") {
      return;
    }

    const hierarchicalRules = getHierarchicalRulesForSelectedTag();
    await uploadTagRules(hierarchicalRules, usedCatalog)
      .then(() => {
        toast.success({
          title: "Success",
          description: "Rules successfully saved!",
        });
        setShowNewRuleForm(false);
        fetchExistingRules();
      })
      .catch((error) => {
        console.error("Failed to upload rules:", error);
        toast.error({
          title: "Error",
          description: "Rules saving failed. Please try again.",
        });
      });
  };

  const toggleDropdown = (rowIndex) => {
    setEditingRowIndex(rowIndex);
    setDropdownOpen((prev) => !prev);
  };

  const fetchExistingRules = async () => {
    const rules = await getTagRules(usedCatalog);

    const uniqueRules = rules.reduce((acc, rule) => {
      if (!acc[rule.tagName]) {
        acc[rule.tagName] = new Set();
      }
      rule.children.forEach((child) => {
        acc[rule.tagName].add(child.tagName);
      });
      return acc;
    }, {});

    const formattedRules = Object.entries(uniqueRules).map(
      ([tagName, children]) => ({
        tagName,
        ruleCount: children.size,
      }),
    );

    setExistingRules(formattedRules);
  };

  useEffect(() => {
    fetchExistingRules();
  }, [usedCatalog, showNewRuleForm]);

  const handleDeleteRule = async (deleteRule) => {
    const confirmation = window.confirm(
      `Are you sure you want to delete the tag rule: ${deleteRule}?`,
    );

    if (confirmation) {
      await deleteTagRules(deleteRule, usedCatalog)
        .then(async () => {
          toast.success({
            title: "Success",
            description: "Rule successfully deleted!",
          });
          fetchExistingRules();
        })
        .catch((error) => {
          console.error("Failed to delete rule:", error);
          toast.error({
            title: "Error",
            description: "Failed to delete rule. Please try again.",
          });
        });
    }
  };

  const handleAddChild = (parentNode) => {
    setDropdownOpen(true);
    setEditingRowIndex(tableData.findIndex((row) => row === parentNode));
  };

  const handleRemoveNode = (nodeToRemove) => {
    const updatedData = removeNodeFromTree(tableData, nodeToRemove);
    setTableData(updatedData);
  };

  const removeNodeFromTree = (nodes, nodeToRemove) => {
    return nodes.reduce((acc, node) => {
      if (node === nodeToRemove) {
        return acc;
      }
      const updatedNode = { ...node };
      if (node.children) {
        updatedNode.children = removeNodeFromTree(node.children, nodeToRemove);
      }
      return [...acc, updatedNode];
    }, []);
  };

  const renderExistingRulesTable = () => (
    <div className="w-full mt-4">
      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        Existing Tagging Rules
      </h2>
      <div className="overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3">
                Tag
              </th>
              <th scope="col" className="px-6 py-3">
                Number of Tags triggered
              </th>
              <th scope="col" className="px-6 py-3">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {existingRules.map((rule, index) => (
              <tr key={index} className="bg-white border-b hover:bg-gray-50">
                <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">
                  {rule.tagName}
                </td>
                <td className="px-6 py-4">{rule.ruleCount}</td>
                <td className="px-6 py-4">
                  <button
                    className="text-red-600 hover:text-red-900 mr-2"
                    onClick={() => handleDeleteRule(rule.tagName)}
                  >
                    <FontAwesomeIcon icon={faTrashAlt} />
                  </button>
                  <button
                    className="text-primary"
                    onClick={() => setSelectedTag(rule.tagName)}
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-6">
        <button
          className="bg-primary hover:bg-deasieTurquoise text-white font-bold py-2 px-4 rounded transition-colors duration-200"
          onClick={() => setShowNewRuleForm(true)}
        >
          New Rule
        </button>
      </div>
    </div>
  );

  return (
    <div className="flex flex-col w-full h-full relative p-4 pb-0 z-40 bg-white rounded-lg shadow-md overflow-y-auto">
      {!showNewRuleForm && !selectedTag ? (
        renderExistingRulesTable()
      ) : (
        <div className="flex flex-col mt-2 gap-4">
          <div className="flex flex-row justify-between items-center">
            <button
              className="bg-white border-2 p-2 border-primary text-primary hover:text-white hover:bg-primary font-bold rounded z-50 w-20 whitespace-nowrap"
              onClick={() => {
                setShowNewRuleForm(false);
                setSelectedTag("");
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Back
            </button>
            <PermissionGuard scope="rules" level="canEdit">
              {!showConnectData && selectedTag && (
                <div className="flex flex-row justify-start">
                  <button
                    className="text-lg p-2 bg-primary hover:bg-white hover:text-primary border-2 border-primary text-white font-bold rounded max-w-full"
                    onClick={() => saveRules(selectedTag)}
                    style={{ opacity: 1 }}
                    title={"Please select a tag to save rules."}
                  >
                    Save Rules for {selectedTag}
                  </button>
                </div>
              )}
            </PermissionGuard>
          </div>

          <TagSelect
            availableTags={availableTags}
            allRules={allRules}
            selectedTag={selectedTag}
            setSelectedTag={setSelectedTag}
          />
        </div>
      )}
      {selectedTag && (
        <>
          <PermissionGuard scope="rules" level="canEdit">
            <div className="w-full h-full mb-2 bg-gray-100 overflow-y-auto p-4 rounded-lg flex flex-col">
              <h3 className="text-lg font-semibold mb-4 ">Tagging Rules Tree</h3>
              <div className="overflow-x-auto pb-4 flex-grow">
                <div className="inline-flex flex-nowrap gap-8 min-w-max">
                  {tableData
                    .filter((node) => node.depth === 0)
                    .map((rootNode, index) => (
                      <div key={index} className="mb-8">
                        <TreeNode
                          node={rootNode}
                          onAddChild={handleAddChild}
                          onRemove={handleRemoveNode}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </PermissionGuard>

          {showConnectData && (
            <div className="w-full flex items-start justify-start absolute left-5 bottom-10">
              <button
                className="cursor-pointer mt-4 bg-grey hover:bg-grey text-white font-bold py-2 px-4 rounded z-50"
                onClick={() => setView("s3List")}
              >
                <FontAwesomeIcon icon={faArrowLeft} /> Back
              </button>
            </div>
          )}

          <div className="w-full flex items-end justify-end gap-3 mt-6">
            {showConnectData && (
              <>
                <button
                  className={`bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition-colors duration-200 ${disabledClass}`}
                  onClick={() => saveRules(selectedTag)}
                >
                  Save Rules
                </button>
                <button
                  onClick={handleSkip}
                  className="px-4 py-2 bg-primary hover:bg-grey text-white rounded transition-colors duration-200"
                >
                  {selectedTag ? "Continue" : "Skip"}
                </button>
              </>
            )}
          </div>
        </>
      )}
      {dropdownOpen && (
        <div
          ref={dropdownRef}
          className="absolute right-0 mt-2 bg-white shadow-lg border rounded-md z-10 overflow-auto max-h-60 w-64"
        >
          <div className="p-2">
            <input
              type="text"
              placeholder="Search tags..."
              className="w-full px-2 py-1 border rounded"
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          <div className="px-4 py-2 hover:bg-gray-100 cursor-pointer justify-between flex flex-row gap-4 font-bold">
            <p>Tag Name</p>
            <p>Type of Outputs</p>
          </div>
          {Object.values({
            ...availableTags.llm.tagger_params.tag_dict,
          })
            .filter((childTag) => {
              const searchLower = searchTerm.toLowerCase();
              return (
                !tableData[editingRowIndex].ancestorTagsName.includes(
                  childTag.name,
                ) &&
                (childTag.name.toLowerCase().includes(searchLower) ||
                  tagOptionsMap[childTag.option]
                    .toLowerCase()
                    .includes(searchLower))
              );
            })
            .map((childTag) => (
              <div
                key={childTag.name}
                className="px-4 py-2 hover:bg-gray-100 cursor-pointer justify-between flex flex-row gap-4"
                onClick={() =>
                  addTagToRow(
                    tableData[editingRowIndex],
                    childTag,
                    editingRowIndex,
                  )
                }
              >
                <p className="break-words whitespace-normal">{childTag.name}</p>
                <p className="text-grey hover:font-bold whitespace-nowrap">
                  {tagOptionsMap[childTag.option]}
                </p>
              </div>
            ))}
        </div>
      )}
    </div>
  );
}
