import { IAuthProvider, IGCPUser } from "./IAuthProvider";
import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAyJm3cP8FBJ6HeOqSeKCWFBD8zfuclS6s",
  authDomain: "deasie-8c0f1.firebaseapp.com",
  databaseURL: "https://deasie-8c0f1-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "deasie-8c0f1",
  storageBucket: "deasie-8c0f1.appspot.com",
  messagingSenderId: "405710845897",
  appId: "1:405710845897:web:8a07f3299273c29a095a18",
  measurementId: "G-KQWH1275CY"
};
const firebaseApp = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth();


export class GCPAuthProvider implements IAuthProvider<IGCPUser> {
    async currentAuthenticatedUser(): Promise<IGCPUser> {
        const uid = firebaseAuth.currentUser?.uid;
        if (uid) {
            return {
                username: uid,
            };
        } else {
            throw new Error("No GCP user found");
        }
    }
  
    async getIdToken(): Promise<string> {
        const token = await firebaseAuth.currentUser?.getIdToken();
        if (token) {
            return token;
        } else {
            throw new Error("No GCP user found");
        }
    }
  
    // TODO: fix this? though it doesn't seem like firebase uses access tokens really
    async getAccessToken(): Promise<string> {
        const token = await firebaseAuth.currentUser?.getIdToken();
        if (token) {
            return token;
        } else {
            throw new Error("No GCP user found");
        }
    }
};
