import { useMsal } from '@azure/msal-react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import { signOut, getAuth } from "firebase/auth";

const useLogout = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();

  const logout = async () => {
    const authProvider = process.env.REACT_APP_AUTH_PROVIDER ?? "AWS";

    if (authProvider.toLowerCase() === "azure") {
      await instance.logoutPopup().catch(console.error);
    } else if (authProvider.toLowerCase() === "gcp") {
      signOut(getAuth()).catch(console.error);
    } else {
      await Auth.signOut().catch(console.error);
      navigate("/");
    }
  };

  return logout;
};

export default useLogout;