export default function ImportTags({
  fileInputRef,
  exampleFileInputRef,
  handleFileChange,
  handleExampleFileChange,
  handleDownload,
}) {
  return (
    <div className="flex flex-col w-full h-full relative p-5 z-40 bg-white rounded">
      <div className="mb-4">
        <h1 className="text-lg font-semibold text-grey">Import Tags</h1>

        <p className="text-md">
          Please use the template csv to import your tags
        </p>
      </div>
      <div className="flex gap-2 max-w-[50%]">
        <button
          className="text-md p-2 rounded-md text-primary border-2 whitespace-nowrap border-primary font-bold w-[40%]"
          onClick={() => fileInputRef.current?.click()}
        >
          Upload Tags
        </button>
        <button
          className="text-md p-2 rounded-md text-primary border-2 whitespace-nowrap border-primary font-bold w-[40%]"
          onClick={() => exampleFileInputRef.current?.click()}
        >
          Upload Tag Examples
        </button>
        <input
          type="file"
          ref={fileInputRef}
          onChange={handleFileChange}
          className="hidden"
        />
        <input
          type="file"
          ref={exampleFileInputRef}
          onChange={handleExampleFileChange}
          className="hidden"
        />
        <button
          onClick={handleDownload}
          className="text-md p-2 rounded-md bg-primary border-2 border-primary whitespace-nowrap text-white font-bold w-[40%]"
        >
          Download Templates
        </button>
      </div>
    </div>
  );
}
