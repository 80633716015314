import Auth from "../auth/AuthProvider";
import { sendRequest } from "../components/utilities/functions/api";
import { ENDPOINTS } from "./endpoints";
import * as types from "./types";

export const getDocumentInfo = async (
  filename: string,
  fileDirectory: string | null,
  storageInfo: types.StorageInfo,
): Promise<types.DocumentInfo> => {
  const username = (await Auth.currentAuthenticatedUser()).username;
  return sendRequest(
    {
      username,
      filename,
      storage_info: storageInfo,
      file_directory: fileDirectory,
    },
    ENDPOINTS["document_info"],
  )
    .then((res) => res.json() as Promise<types.DocumentInfoResponse>)
    .then((info) => ({
      ...info,
      file_url: new URL(info.file_url),
    }));
};

export const getFolderList = async (): Promise<types.FolderEntry> => {
  const username = (await Auth.currentAuthenticatedUser()).username;

  return sendRequest({ username }, ENDPOINTS["fetch_folders"])
    .then((res) => res.json() as Promise<types.DirectoriesResponse>)
    .then((info) => info.folder_list);
};
