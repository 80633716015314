import { DataContext } from "../context/DataContext";
import { useCallback, useContext } from "react";
import { useAtom } from "jotai";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaExpandArrowsAlt } from "react-icons/fa";
import { FaMinimize } from "react-icons/fa6";
import { pdfAtom, pdfSearchAtom, selectedCatalogItemsAtom } from "../atoms";
import { toast } from "../components/utilities/Toast";
import { useCatalogDocumentInfoLoader } from "../api/queryHooks";

const stringToColor = (stringValue, opacity = "40") => {
  let hash = 0;
  for (let i = 0; i < stringValue.length; i++) {
    hash = stringValue.charCodeAt(i) + ((hash << 5) - hash);
    hash = hash & hash;
  }

  let color = "#";
  for (let i = 0; i < 3; i++) {
    // Use parts of the hash for different color components
    let value = (hash >> (i * 8)) & 0xff;
    color += ("00" + value.toString(16)).slice(-2);
  }
  return color + opacity;
};

const CatalogTableRow = ({
  docKey,
  tags,
  actionsFile,
  expandedFiles,
  setExpandedFiles,
  availableTags,
  setActionsFile,
  onEvidenceClick,
}) => {
  const {
    hiddenCategories,
    ruleDict,
    currentDataGroup,
    setShowFileOnPage,
    isGloballyExpanded,
    catalogFiles,
    deleteMultipleDatasets,
    setDocumentPreviewDatastoreName,
  } = useContext(DataContext);

  const loadDocumentInfo = useCatalogDocumentInfoLoader();

  const [pdf, setPdf] = useAtom(pdfAtom);
  const [pdfSearch, setPdfSearch] = useAtom(pdfSearchAtom);
  const isExpanded = isGloballyExpanded || expandedFiles.has(docKey);
  const isActionsOpened = docKey === actionsFile;
  const [selectedCatalogItems, setSelectedCatalogItems] = useAtom(
    selectedCatalogItemsAtom,
  );

  const downloadFile = useCallback(
    async (docKey) => {
      if (!currentDataGroup) return;
      toast.info({
        title: "Downloading file...",
        description: "This may take a few seconds.",
      });

      const documentInfo = await loadDocumentInfo(docKey);

      window.open(documentInfo.file_url, "_blank");
      setActionsFile("");
    },
    [currentDataGroup, loadDocumentInfo],
  );

  const openFile = useCallback(
    async (docKey) => {
      if (!currentDataGroup) return;

      toast.info({
        title: "Opening file...",
        description: "This may take a few seconds.",
      });

      const file_name = docKey.split("/").pop();
      if (
        file_name?.toLowerCase().endsWith(".pdf") ||
        file_name?.toLowerCase().endsWith(".docx")
      ) {
        setShowFileOnPage("standard");
        setPdf(`${catalogFiles[file_name].file_directory}/${file_name}`);
        setDocumentPreviewDatastoreName(catalogFiles[file_name].data_store_name);
      } else {
        const documentInfo = await loadDocumentInfo(docKey);
        window.open(documentInfo.file_url, "_blank");
        setActionsFile("");
      }
    },
    [currentDataGroup, setPdf, setShowFileOnPage, loadDocumentInfo],
  );

  const disbaled_dropdown_style = "cursor-not-allowed opacity-50 bg-gray-100";

  return (
    <tr
      className="border-b hover:bg-gray-50 transition-colors duration-200"
      data-testid="catalogDocumentEntry"
    >
      <td className="w-1">
        <div className="justify-center items-center flex">
          <input
            id={`checkbox-${docKey}`}
            type="checkbox"
            data-testid="documentEntrySelectCheckbox"
            className="appearance-none h-3 w-3 border border-gray-300 rounded-sm bg-white checked:bg-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 transition duration-200 ease-in-out cursor-pointer"
            checked={selectedCatalogItems.has(docKey)}
            onChange={(e) => {
              if (e.target.checked) {
                selectedCatalogItems.add(docKey);
              } else {
                selectedCatalogItems.delete(docKey);
              }
              setSelectedCatalogItems(new Set(selectedCatalogItems));
            }}
          />
        </div>
      </td>
      <td className="w-60 px-5 py-4 text-xs break-all text-gray-700">
        <label htmlFor={`checkbox-${docKey}`} className="w-50 cursor-pointer">
          <div className="flex items-center gap-1">{docKey}</div>
        </label>
      </td>

      <td className="px-5 py-4">
        <div
          className={`transition-all duration-300 ease-in-out hide-scrollbar ${
            isExpanded
              ? "max-h-none overflow-visible"
              : "max-h-20 overflow-y-auto"
          }`}
          data-testid={`tags-${docKey}`}
        >
          <div className="flex flex-wrap gap-1.5">
            {tags &&
              Object.entries(tags)
                .filter(([key, value]) => {
                  return (
                    key !== "chunks" &&
                    key !== "file_directory" &&
                    !hiddenCategories?.includes(key) &&
                    value &&
                    Array.isArray(value) &&
                    value[0] &&
                    key in availableTags.sensitivity.tagger_params.tag_dict ===
                      false &&
                    Object.keys(
                      {
                        ...availableTags.llm.tagger_params.tag_dict,
                        ...ruleDict,
                      } || {},
                    ).includes(key)
                  );
                })
                .map(([tagKey, value], index) => {
                  let processedValue;

                  if (Array.isArray(value)) {
                    if (typeof value[0] === "string") {
                      const cleanedString = value[0]
                        .replace(/^[\["']|[\]"']$/g, "")
                        .replace(/\\"/g, '"');
                      try {
                        processedValue = JSON.parse(`[${cleanedString}]`);
                      } catch (error) {
                        processedValue = [cleanedString];
                      }
                    } else {
                      processedValue = [value[0]];
                    }
                  } else if (typeof value === "string") {
                    processedValue = [value.replace(/^['"]|['"]$/g, "")];
                  } else {
                    processedValue = [value];
                  }

                  return processedValue.map((val, idx) => (
                    <div
                      key={`${docKey}-${tagKey}-${val}-${idx}`}
                      className={`text-[10px] py-1 px-2 rounded-md shadow-md transition-all duration-200 ${
                        docKey.toLowerCase().includes(".pdf") ||
                        docKey.toLowerCase().includes(".docx")
                          ? "cursor-pointer hover:opacity-50"
                          : ""
                      }`}
                      style={{
                        backgroundColor:
                          stringToColor(tagKey, "20") || "#80808028",
                      }}
                      onClick={async () => {
                        if (
                          !docKey?.toLowerCase().includes(".pdf") &&
                          !docKey.includes(".docx")
                        )
                          return;

                        if (!currentDataGroup) return;

                        if (
                          docKey?.toLowerCase().endsWith(".pdf") ||
                          docKey?.toLowerCase().endsWith(".docx")
                        ) {
                          setPdf(`${catalogFiles[docKey].file_directory}/${docKey}`);
                          setDocumentPreviewDatastoreName(catalogFiles[docKey].data_store_name);
                          setShowFileOnPage("standard");

                          const filteredChunks = Object.entries(
                            tags["chunks"],
                          ).filter(
                            ([_, chunk]) =>
                              chunk[tagKey] &&
                              chunk[tagKey].evidence != "Not found",
                          );

                          const searchEvidence = filteredChunks.map(
                            ([_, chunk]) => chunk[tagKey].evidence,
                          );
                          const chunkIndexArray = filteredChunks.map(
                            ([chunkIndex]) => chunkIndex,
                          );
                          const pageRangeArray = chunkIndexArray.map(
                            (index) =>
                              catalogFiles[docKey]?.chunks?.[index]
                                ?.page_range[0],
                          );
                          setPdfSearch({
                            evidence: searchEvidence,
                            chunk_index: chunkIndexArray,
                          });
                        } else {
                          const response = await loadDocumentInfo(docKey);
                          window.open(response.file_url, "_blank");
                          setActionsFile("");
                        }
                      }}
                    >
                      <span
                        className="font-semibold text-gray-700"
                        data-testid="documentTag"
                      >
                        {tagKey}:
                      </span>{" "}
                      <span className="text-gray-600">
                        {typeof val === "string"
                          ? val.replace(/^["']|["']$/g, "")
                          : val}
                      </span>
                    </div>
                  ));
                })
                .flat()}
          </div>
        </div>
      </td>
      <td scope="col" className="text-left px-5 py-3 top-0  z-40">
        <div className="flex items-center gap-1">
          <div
            className={`items-center text-base h-5 flex justify-center transition-all cursor-pointer py-1 px-2 rounded-md ${isGloballyExpanded ? "text-grey" : "text-grey"}`}
            onClick={() => {
              if (isExpanded) {
                expandedFiles.delete(docKey);
              } else {
                expandedFiles.add(docKey);
              }
              setExpandedFiles(new Set(expandedFiles));
            }}
          >
            {isExpanded ? <FaMinimize /> : <FaExpandArrowsAlt />}
          </div>
        </div>
      </td>
      <td className="px-5 py-3">
        <div className="flex justify-center relative">
          <BsThreeDotsVertical
            className="cursor-pointer"
            onClick={() => {
              setActionsFile(isActionsOpened ? "" : docKey);
            }}
          />
          {isActionsOpened && (
            <div className="absolute z-30 select-none top-[calc(100%_+_8px)] w-40 bg-white border rounded-md shadow-md flex flex-col">
              <div
                className="fixed inset-0 z-20"
                onClick={() => setActionsFile("")}
              ></div>
              <div
                className={`z-30 border-b border-b-gray-100 px-3 py-3 text-gray-600 cursor-pointer hover:bg-gray-100`}
                onClick={() => openFile(docKey)}
              >
                Open
              </div>
              <div
                className={`z-30 border-b border-b-gray-100 px-3 py-3 text-gray-600 cursor-pointer hover:bg-gray-100`}
                onClick={() => downloadFile(docKey)}
              >
                Download
              </div>
              <div
                className="cursor-pointer z-30 border-b border-b-gray-100 px-3 py-3 text-gray-600 hover:bg-gray-100"
                onClick={() => {
                  deleteMultipleDatasets([docKey]);
                  setSelectedCatalogItems((prev) => {
                    const newSet = new Set(prev);
                    newSet.delete(docKey);
                    return newSet;
                  });
                }}
              >
                Delete
              </div>
            </div>
          )}
        </div>
      </td>

      <td className="px-4 py-2">
        <button
          className="px-3 py-[5px] text-primary border-[1px] border-primary rounded-md whitespace-nowrap"
          onClick={() => onEvidenceClick(docKey)}
        >
          See evidence
        </button>
      </td>
    </tr>
  );
};

export default CatalogTableRow;
